import React, { useEffect, useState } from "react";
import AdminMenuBar from "../../AdminMenuBar";
import { getAllEvents } from "../../../../apis/api";
import { DataView } from "primereact/dataview";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import { useNavigate } from "react-router-dom";
import moment from "moment";
function ListAllEvents() {
  const [allEvents, setAllEvents] = useState([]);

  useEffect(() => {
    getAllEvents().then((res) => setAllEvents(res?.data?.results));
  }, []);
  const navigate = useNavigate();
  console.log(allEvents);
  const header = (
    <div className="flex justify-content-between align-items-center ">
      <h2 className="m-0">All Events</h2>
      <div>
        <Button
          icon="pi pi-globe"
          className="ml-3"
          label="Add New Event"
          onClick={() => navigate("/addNewEvent")}
        ></Button>
      </div>
    </div>
  );

  const itemTemplate = (data) => {
    return (
      <div className="p-col-12 md:col-2 ">
        <div className="product-grid-item card p-2">
          <div
            className="justify-content-between flex flex-column justify-content-between"
            style={{ height: "400px" }}
          >
            <div>
              <img
                style={{ width: "150px", height: "150px", alignSelf: "center" }}
                alt=""
                src={
                  data.media[0]
                    ? data.media[0].link
                    : "https://png.pngtree.com/png-vector/20190804/ourlarge/pngtree-map-location-pin-world-abstract-flat-color-icon-template-png-image_1650126.jpg"
                }
              />
              <div className="product-name">
                {data.event_name?.length > 50
                  ? `${data.event_name?.substring(0, 60)}...`
                  : data.event_name}
              </div>
              <div>
                <i className="pi pi-clock" style={{ marginRight: "5px" }}></i>
                {moment(data?.event_start).format("DD MMM YYYY")}
                {", "}
                {moment(data?.event_start).format("h:mm a")} to{" "}
                {moment(data?.event_end).format("DD MMM YYYY")}
                {", "}
                {moment(data?.event_end).format("h:mm a")}
              </div>
            </div>
            <div className="product-text">
              <div>{data.date}</div>

              <Button
                onClick={() => navigate("/eventDetails", { state: data })}
              >
                View Event
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <div className="dataview-demo">
        <div className="m-2 p-2">
          <DataView
            paginator
            layout={"grid"}
            rows={12}
            itemTemplate={itemTemplate}
            value={allEvents}
          />
        </div>
      </div>
    </div>
  );
}

export default ListAllEvents;
