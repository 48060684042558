import axios from "axios";
const login = async (email, password) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/login`,
    { email, password },
    { headers: { "Content-Type": "application/json" } }
  );
  return response.data;
};
const loginMember = async (email, password) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/memberships/login`,
    { email, password },
    { headers: { "Content-Type": "application/json" } }
  );
  return response.data;
};
const logout = async () => {
  const response = await axios.delete(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/logout`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    }
  );
  if (response.status === 401) {
    window.location.replace("/");
    return null;
  } else {
    return response;
  }
};

const getAllMembers = async (pageNumber, pageSize, order, url) => {
  let url2 = "";
  if (order !== null) {
    url = `&sortType=MEM_id_no&orderBy=${order}`;
  }
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/memberships?pageNumber=${pageNumber}&pageSize=${pageSize}${url2}${url}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const getPendingMembers = async (pageNumber, pageSize, order, url) => {
  let url2 = "";
  if (order !== null) {
    url = `&sortType=MEM_id_no&orderBy=${order}`;
  }
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/memberships/pending?pageNumber=${pageNumber}&pageSize=${pageSize}${url2}${url}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const getApprovedMembers = async (pageNumber, pageSize, order, url, isPaid) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/memberships?pageNumber=${pageNumber}&pageSize=${pageSize}&sortType=MEM_id_no&orderBy=${order}&isPaid=${isPaid}${url}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const getBookPrint = async (url, member) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/memberships/download?isPaid=All&sortType=name_of_org&orderBy=ASC&media=true&member=${member}${url}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const getApprovedMembersforPrint = async (type, order, url) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/memberships/download?sortType=${type}&orderBy=${order}${url}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const getMemberCount = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/memberships/count`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const getSearchMember = async (key) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/search/cert-of-origin?key=${key}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/cert-of-origin");
      return null;
    }
  }
};
const getReceipts = async (pageNumber, pageSize, url) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/memberships/receipts?pageNumber=${pageNumber}&pageSize=${pageSize}&${url}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/cert-of-origin");
      return null;
    }
  }
};
const getApprovedMembersbyCategory = async (data) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/memberships?&industry=${data}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const getAllUsers = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/users`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const deleteUser = async (id) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/users/${id}`,
      {
        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const toggleStatus = async (id, data) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/users/${id}/toggle?status=true`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};

const register = async (data) => {
  // try {
  // const response =
  return await axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/memberships`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    }
  );
  // return response;
  // } catch (error) {
  //   if (error.response.status === 401) {
  //     window.location.replace("/");
  //     return null;
  //   }
  //   return error;
  // }
};
const updateMemberCategory = async (data, id) => {
  return await axios.put(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/memberships/${id}/memberships`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    }
  );
};
const changePassword = async (data) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/users/password`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.log("error", error);
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    } else {
      return error;
    }
  }
};
const updateMember = async (data, id) => {
  // try {
  //   const response =
  return await axios.put(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/memberships/${id}`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    }
  );
  //   return response;
  // } catch (error) {
  //   console.log("error", error);
  //   if (error.response.status === 401) {
  //     window.location.replace("/");
  //     return null;
  //   } else {
  //     return error;
  //   }
  // }
};
const createNewEvent = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/events`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const createNewJournal = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/journals/`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const createNewUser = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/users`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const getAllEvents = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/public/events`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "fkcci-testing",
        },
      }
    );
    if (response.status === 401) {
      window.location.replace("/");
      return null;
    } else {
      return response.data;
    }
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const getAllJournals = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/journals/allJournals`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    if (response.status === 401) {
      window.location.replace("/");
      return null;
    } else {
      return response.data;
    }
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const approve = async (id, data) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/memberships/${id}/changeStatus`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const uploadMembersImages = async (id, data) => {
  try {
    console.log("data", data);
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/memberships/${id}/photo`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const deleteMembersImages = async (id) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/memberships/photo/${id}`,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );

    return response;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const generateAccessCode = async (id) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/voting/generatecode/${id}`,

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const searchMemberApi = async (data) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/search/members?key=${data}`,

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const searchReceipt = async (data) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/search/receiptSearch?key=${data}`,

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const getMemberDetail = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/memberships/${id}`,

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const getListOfAllCandidates = async (votingCode) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/voting/${votingCode}`,

      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "fkcci-testing",
        },
      }
    );
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const getResults = async (code) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/voting/vote/all?key=${code}`,

      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "fkcci-testing",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const searchExactRfid = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/search/rfid/${id}`,

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const registerEvent = async (id) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/events/subscribe/${id}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );

    return response;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const varifyEventRegisteration = async (id, data) => {
  try {
    console.log("res", id, data);
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/events/verify/${id}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );

    return response;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const registerCOO = async (data) => {
  try {
    console.log("res", data);
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/memberships/cert-of-origin`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );

    return response;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/cert-of-origin");
      return error;
    }
  }
};
const renewMember = async () => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/payment/member/order`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const updateUserProfile = async (data) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/users/profile`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const contactUs = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/users/contact`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const confirmPayment = async (id, data) => {
  try {
    console.log("res", id, data);
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/payment/member/verify/${id}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const getEventDetails = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/public/events/${id}`,
      { headers: { "x-api-key": "fkcci-testing" } }
    );
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const getCOOReceipts = async (url) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/memberships/cert-of-origin/receipts?${url}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};

const voteForCandidates = async (id, data, headers) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/voting/vote/${id}`,
      data,
      headers
    );

    return response;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const getCategory = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/categories/count`,

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const getUserProfile = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/users/profile`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const getFilterMembers = async (start, size, url) => {
  try {
    console.log("start", start);
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/memberships?pageNumber=${start}&pageSize=${size}${url}`,

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const getActivityFeed = async (start, size) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/activity_log/?pageNumber=${start}&pageSize=${size}`,

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const getActivitySearch = async (key) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/activity_log/search/?key=${key}`,

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const generateInvoice = async (id, type, data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/memberships/${id}/invoice?type=${type}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const sendInvoiceToEmail = async (id, data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/memberships/${id}/invoice/email`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const getInvoiceIsPaid = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/memberships/${id}/invoiceDetails`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const cancelInvoice = async (id) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/memberships/${id}/invoice/cancel`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const getCityWiseCount = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/memberships/download/city-wise`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
export {
  login,
  loginMember,
  register,
  getAllMembers,
  getAllEvents,
  createNewEvent,
  getAllUsers,
  toggleStatus,
  deleteUser,
  createNewUser,
  approve,
  searchMemberApi,
  logout,
  uploadMembersImages,
  generateAccessCode,
  getMemberDetail,
  searchExactRfid,
  getListOfAllCandidates,
  voteForCandidates,
  getResults,
  getApprovedMembers,
  updateMember,
  getEventDetails,
  registerEvent,
  varifyEventRegisteration,
  getCategory,
  getFilterMembers,
  renewMember,
  confirmPayment,
  getUserProfile,
  updateUserProfile,
  contactUs,
  getApprovedMembersbyCategory,
  generateInvoice,
  getReceipts,
  getActivityFeed,
  getActivitySearch,
  deleteMembersImages,
  getApprovedMembersforPrint,
  getMemberCount,
  registerCOO,
  getSearchMember,
  getCOOReceipts,
  sendInvoiceToEmail,
  getInvoiceIsPaid,
  searchReceipt,
  updateMemberCategory,
  cancelInvoice,
  getCityWiseCount,
  getBookPrint,
  changePassword,
  getAllJournals,
  createNewJournal,
  getPendingMembers
};
