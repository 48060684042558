import React, { useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";

function AdminLogin() {
  const [checked, setChecked] = useState(null);
  return (
    <div style={{background: "white",height:"100vh"}}>
      <div style={{paddingTop:"150px"}}>
        <div className="flex align-items-center justify-content-center">
          <div className="surface-card p-4 shadow-2 border-round w-full lg:w-4">
            <div
              style={{
                display: "flex",
                margin: "20px 0px",
                justifyContent: "center",
              }}
            >
              <div>
                <i
                  style={{ fontSize: "30px", margin: "10px",color:"white" }}
                  className="pi pi-user"
                ></i> 
              </div>

              <div style={{ textAlign: "center", margin: "10px" }}>
                <div style={{ fontSize: "25px", fontWeight: "700" }}>
                  Sign in to your Account
                </div>
              </div>
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-900 font-medium mb-2"
              >
                Email
              </label>
              <InputText style={{color: "#17212F"}} id="email" type="text" className="w-full mb-3" />

              <label
                htmlFor="password"
                className="block text-900 font-medium mb-2"
              >
                Password
              </label>
              <InputText
                id="password"
                type="password"
                className="w-full mb-3"
              />

              <div className="flex align-items-center justify-content-between mb-6">
                <div className="flex align-items-center">
                  <Checkbox
                    id="rememberme"
                    onChange={(e) => setChecked(e.checked)}
                    checked={checked}
                    className="mr-2"
                  />
                  <label style={{color:"white"}} htmlFor="rememberme">Remember me</label>
                </div>
                <a className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">
                  Forgot your password?
                </a>
              </div>

              <Button label="Sign In" icon="pi pi-user" className="w-full" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminLogin;
