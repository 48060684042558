import moment from "moment";
import React from "react";
import Logo from "../../../../../../assets/logo1.png";
import Signature from "../../../../../../assets/signature.png";

import "./Invoice.css";
var converter = require("number-to-words");
function Invoice({ data, isDuplicate }) {
  const getYear = (val) => {
    // const date = new Date(val);
    // const year = date.getFullYear();
    return val;
  };
  const getNextYear = (val) => {
    // const date = new Date(val);
    const year = Number(val) + 1;
    return year;
  };
  const getCurrency = (val) => {
    const curr = new Intl.NumberFormat().format(val);
    const total = curr.toString() + ".00";
    return total;
  };
  // console.log("invoiceData", data);
  return (
    <div
      style={{
        width: "100%",
        padding: isDuplicate ? "30px 10px" : "20px 10px",
        marginTop: isDuplicate ? "700px" : "0",
      }}
    >
      <div
        style={{
          border: "1px solid #000000",
          display: "flex",
          justifyContent: "space-between",
          borderRadius: "5px",
          padding: "10px 20px 5px 20px",
        }}
      >
        <diV>
          <img src={Logo} alt="Logo" style={{ height: "70px" }} />
          <p style={{
            fontSize:"10px",
            textAlign: "left",
            lineHeight: "11px"
          }}>PAN No.: AAACF2404N</p>
          <p style={{
            fontSize:"10px",
            textAlign: "left",
            lineHeight: "11px"
          }}>GSTIN: 29AAACF2404N1ZM</p>
        </diV>
        <div>
          <p
            style={{
              fontSize: "13px",
              textAlign: "center",
              lineHeight: "16px",
              fontWeight: "600",
            }}
          >
            FEDERATION OF KARNATAKA CHAMBERS OF COMMERCE & INDUSTRY
          </p>
          <p 
           style={{
            fontSize: "14px",
            textAlign: "center",
            lineHeight: "16px",
          }}>Federation House</p>
          <p
            style={{
              fontSize: "12px",
              textAlign: "center",
              lineHeight: "14px",
            }}
          >
            Kempegowda Road, Bengaluru - 560009 | Ph: +91 080 2226 2355/56
          </p>
          <p style={{
            fontSize: "14px",
            textAlign: "center",
            lineHeight: "16px",
          }}>e-mail:membership@fkcci.in</p>
          <p
            style={{
              fontSize: "18px",
              textAlign: "center",
              lineHeight: "24px",
              fontWeight: "500",
            }}
          >
            {isDuplicate
              ? "TAX INVOICE (Duplicate for Supplier)"
              : "TAX INVOICE"}
          </p>
        </div>
        <div>
          <p style={{
            fontSize:"10px",
            textAlign: "left",
            lineHeight: "11px",
            marginTop: "60px"
          }}>
            CIN No: U91110KA1938NPL00024
          </p>
        </div>
      </div>
      <div
        style={{
          border: "1px solid #000000",
          borderRadius: "5px",
          padding: "10px 20px",
        }}
      >
        <p style={{
            fontSize: "14px",
            textAlign: "center",
            lineHeight: "16px",
          }}>Place of Supply:29-Karnataka</p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p style={{ fontSize: "14px" }}>
            Inv. No. : {data?.payment_details[0]?.receipt_no}
          </p>
          <p style={{ fontSize: "14px" }}>
            Date : {moment(data?.created_at).format("DD-MM-YYYY")}
          </p>
        </div>
        <p style={{
            fontSize: "14px",
            textAlign: "left",
            lineHeight: "16px",
          }}>
          Received with thanks from Messers {data?.member?.name_of_org},{" "}
          {data?.member?.address} {data?.member?.address2}{" "}
          {data?.member?.address3}
          {data?.member?.city}-{data?.member?.pincode}
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <p style={{ fontSize: "14px" }}>{data?.member?.MEM_id_no}</p>
          <p style={{ fontSize: "14px" }}>
            MEMBER GST NO.{data?.member?.GSTIN}
          </p>
        </div>
        <p style={{
            fontSize: "14px",
            textAlign: "left",
            lineHeight: "16px",
          }}>
          towards Membership Subscription for the Year{" "}
          {getYear(data?.payment_details[0]?.financial_year)}-
          {getNextYear(data?.payment_details[0]?.financial_year)}
        </p>
        <p style={{
            fontSize: "14px",
            textAlign: "left",
            lineHeight: "16px",
            marginLeft: "100px" 
          }} >
          Description of Service
        </p>
        <div
          style={{
            borderTop: "1px dotted #000000",
            borderBottom: "1px dotted #000000",
            display: "flex",
          }}
        >
          <div
            style={{
              width: "50%",
              borderRight: "1px dotted #000000",
              padding: "10px 5px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "flex-end",
                marginRight: "25px",
              }}
            >
              <p style={{
            fontSize: "14px",
            textAlign: "center",
            lineHeight: "16px",
          }}>Membership Subscription Fee :</p>
              <p style={{
            fontSize: "14px",
            textAlign: "center",
            lineHeight: "16px",
          }}>Admission Fee :</p>
              <div style={{ display: "flex" }}>
                {/* <p style={{
            fontSize: "14px",
            textAlign: "center",
            lineHeight: "16px",
            marginRight: "10px"
          }}>
                  HSN CODE:999599
                </p> */}
                <p style={{
            fontSize: "14px",
            textAlign: "center",
            lineHeight: "16px",
          }}>HSN CODE:999599  Interest Amount :</p>
              </div>
              <p style={{
            fontSize: "14px",
            textAlign: "center",
            lineHeight: "16px",
          }}>Total Taxable Value :</p>
              <p style={{
            fontSize: "14px",
            textAlign: "center",
            lineHeight: "16px",
          }}>IGST @18% :</p>
              <p style={{
            fontSize: "14px",
            textAlign: "center",
            lineHeight: "16px",
          }}>SGST @9% :</p>
              <p style={{
            fontSize: "14px",
            textAlign: "center",
            lineHeight: "16px",
          }}>CGST @9% :</p>
              <p style={{
            fontSize: "14px",
            textAlign: "center",
            lineHeight: "16px",
          }}>MISC. Amount :</p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <p style={{
            fontSize: "14px",
            textAlign: "center",
            lineHeight: "16px",
          }}>
                {getCurrency(data?.payment_details[0]?.subscription_amount)}
              </p>
              <p style={{
            fontSize: "14px",
            textAlign: "center",
            lineHeight: "16px",
          }}>
                {getCurrency(data?.payment_details[0]?.admission_amount)}
              </p>
              <p style={{
            fontSize: "14px",
            textAlign: "center",
            lineHeight: "16px",
          }}>
                {getCurrency(data?.payment_details[0]?.interest_amount)}
              </p>
              <p style={{
            fontSize: "14px",
            textAlign: "center",
            lineHeight: "16px",
          }}>
                {getCurrency(data?.payment_details[0]?.taxable_amount)}
              </p>

              <p style={{
            fontSize: "14px",
            textAlign: "center",
            lineHeight: "16px",
          }}>
                {getCurrency(data?.payment_details[0]?.igst)}
              </p>
              <p style={{
            fontSize: "14px",
            textAlign: "center",
            lineHeight: "16px",
          }}>
                {getCurrency(data?.payment_details[0]?.sgst)}
              </p>
              <p style={{
            fontSize: "14px",
            textAlign: "center",
            lineHeight: "16px",
          }}>
                {getCurrency(data?.payment_details[0]?.cgst)}
              </p>
              <p style={{
            fontSize: "14px",
            textAlign: "center",
            lineHeight: "16px",
          }}>
                {getCurrency(data?.payment_details[0]?.misc_amount)}
              </p>
            </div>
          </div>
          <div
            style={{
              width: "50%",
              padding: "10px 10px",
            }}
          >
            {data?.payment_details[0]?.payment_type === "NEFT" ? (
              <p style={{
            fontSize: "14px",
            textAlign: "left",
            lineHeight: "16px",
          }}>
                by NEFT Transaction ID.{" "}
                {data?.payment_details[0]?.transactional_id} Date:
                {moment(data?.payment_details[0]?.date_of_payment).format(
                  "DD-MM-YYYY"
                )}
                {"\n"}
                {data?.payment_details[0]?.bank_name}
              </p>
            ) : (
              <p style={{
            fontSize: "14px",
            textAlign: "left",
            lineHeight: "16px",
          }}>
                by Cheque bearing No. {data?.payment_details[0]?.cheque_number}{" "}
                Date:
                {moment(data?.payment_details[0]?.date_of_payment).format(
                  "DD-MM-YYYY"
                )}
                {"\n"}
                {data?.payment_details[0]?.bank_name}
              </p>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "70%",
          }}
        >
          <p style={{
            fontSize: "14px",
            textAlign: "center",
            lineHeight: "16px",
            fontWeight: "700"
          }}>
            Rs.{getCurrency(data?.payment_details[0]?.paid_amount)}
          </p>
          <p style={{
            fontSize: "14px",
            textAlign: "center",
            lineHeight: "16px",
          }}>Tax Payable on Reverse Charge: No</p>
        </div>
        <p  style={{
            fontSize: "14px",
            textAlign: "left",
            lineHeight: "16px",
          }}>
          a Sum of Ruppes:{" "}
          {converter.toWords(data?.payment_details[0]?.paid_amount ?? 0)} Only
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            marginTop: "-20px",
          }}
        >
          <p style={{
            fontSize: "14px",
            textAlign: "left",
            lineHeight: "16px",
          }}>
            Remarks: {data?.payment_details[0]?.remark}
          </p>
          <img
            src={Signature}
            alt="Logo"
            style={{
              height: "80px",
              marginRight: "40px",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "5px",
          }}
        >
          <p style={{
            fontSize: "14px",
            textAlign: "center",
            lineHeight: "16px",
          }}>*Cheque Subject to Realisation</p>
          <p style={{
            fontSize: "14px",
            textAlign: "center",
            lineHeight: "16px",
          }}>Authorised Signatory</p>
        </div>
      </div>
    </div>
  );
}

export default Invoice;
