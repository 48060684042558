import React, { useEffect, useState } from "react";
import MenuBar from "../../../components/MenuBar";
import { Button } from "primereact/button";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { useNavigate } from "react-router-dom";
import "./HomePage.css";
import {
  getAllMembers,
  getCategory,
  getFilterMembers,
} from "../../../apis/api";
import { Paginator } from "primereact/paginator";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
const cat = {
  "Small Scale Manufacturing Activity": "ms",
  "Large Scale Manufacturing Activity": "ml",
  "Small Scale Trading Activity": "ts",
  "Large Scale Trading Activity": "tl",
  "Small Scale Service Activity": "ss",
  "Large Scale Service Activity": "sl",
  Profession: "pr",
  "District Chambers Of Commerce": "di",
  Association: "as",
};
const items = [
  { label: "Small Scale Manufacturing Activit", value: "ms" },
  { label: "Small Scale Trading Activity", value: "ts" },
  { label: "Large Scale Manufacturing Activity", value: "ml" },
  { label: "Large Scale Service Activity", value: "sl" },
  { label: "Association", value: "as" },
  { label: "Small Scale Service Activity", value: "ss" },
  { label: "Large Scale Trading Activity", value: "tl" },
  { label: "District Chambers Of Commerce", value: "di" },
  { label: "Profession", value: "pr" },
];

function HomePage() {
  const [allMembers, setAllMembers] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [categoryOption, setCategoryOption] = useState([]);
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(12);
  const [currentPage, setCurrentPage] = useState(0);
  const [count, setCount] = useState(0);
  const [title, setTitle] = useState("");
  const [location, setLocation] = useState("");
  const [category, setCategory] = useState("");
  // let url = "";
  const [isSearch, setIsSearch] = useState(false);
  const [generate, setGenerate] = useState(false);

  const navigate = useNavigate();
  const onBasicPageChange = (event) => {
    setBasicFirst(event.first);
    setBasicRows(event.rows);
    setCurrentPage(event.page);
  };
  useEffect(() => {
    let url = "";
    if (title !== "") {
      url += `&key=${title}`;
    }
    if (location !== "") {
      url += `&location=${location}`;
    }
    if (category !== "") {
      url += `&industry=${category}`;
    }
    getFilterMembers(currentPage, 12, url)
      .then((data) => {
        console.log("data", data);
        setAllMembers(data?.results);
        setCount(data?.total);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, [isSearch, currentPage, generate]);
  useEffect(() => {
    if (!isSearch) {
      getAllMembers(currentPage, 12)
        .then((data) => {
          console.log("data", data);
          setAllMembers(data?.results);
          setCount(data?.total);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  }, [currentPage]);
  useEffect(() => {
    getCategory()
      .then((res) => {
        console.log("resData", res);
        setCategoryData(res?.data);
        setCategoryOption(
          res?.data.slice(0, res?.data.length - 1)?.map((item) => ({
            lable: item?.membership_category,
            value: cat[item?.membership_category],
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  console.log("catData", typeof categoryOption);
  const itemTemplate = (data) => {
    console.log("data", data);
    return (
      <div
        className="p-col-12 p-md-2"
        style={{ margin: "20px" }}
        onClick={(e) => {
          navigate("/membership", { state: data });
        }}
      >
        <div className="product-grid-item card" style={{ cursor: "pointer" }}>
          <div className="product-grid-item-top">
            <div>
              <span className="product-category">{data.category}</span>
            </div>
          </div>
          <div className="product-grid-item-content">
            <div>
              <img
                style={{ padding: "20px", height: "300px" }}
                src={
                  data?.media.length !== 0
                    ? data?.media[0]?.link
                    : "https://avataaars.io/?avatarStyle=Circle&topType=Hat&accessoriesType=Prescription02&facialHairType=BeardMedium&facialHairColor=Blonde&clotheType=CollarSweater&clotheColor=Heather&eyeType=Squint&eyebrowType=UpDownNatural&mouthType=Sad&skinColor=DarkBrown"
                }
              />
            </div>
            <div className="product-text">
              <div className="product-name">
                {data.user.first_name}&nbsp;{data.user.last_name}
              </div>
              <div className="product-name">{data.name_of_org}</div>

              <div
                style={{
                  display: "flex",
                  alignItem: "center",
                  justifyContent: "center",
                }}
              >
                <i
                  className="pi pi-map-marker"
                  style={{ color: "#27C200" }}
                ></i>
                <div style={{ marginTop: "-3px", marginLeft: "10px" }}>
                  {data.city}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "10px",
                }}
              >
                {/* <Button
                  onClick={() => navigate("/membership", { state: data })}
                  label="View Profile"
                ></Button> */}
                {/* <i
                  style={{ fontSize: "22px", margin: "0px 10px" }}
                  className="pi pi-map-marker"
                ></i>
                <div>{data.address}</div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <MenuBar />
      <div className="topTemplate">
        <div style={{ textAlign: "center", fontSize: "35px", display: "flex" }}>
          <div>Browse Over&nbsp;</div>
          <strong style={{ color: "yellow" }}>4,000&nbsp;</strong>
          <div>FKCCI Members</div>
        </div>
      </div>
      <div className="searchBar">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Tittle, keyword..."
          />
        </span>

        <Dropdown
          style={{ width: "14rem" }}
          value={category}
          options={items}
          onChange={(e) => {
            console.log("cat", e.value);
            setCategory(e.value);
          }}
          placeholder="Select a Category"
        />
        <span className="p-input-icon-left">
          <i className="pi pi-map-marker" />
          <InputText
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            placeholder="Location"
          />
        </span>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            label="Search"
            icon="pi pi-search"
            className="p-button font-light"
            onClick={(e) => {
              setIsSearch(true);
              setCurrentPage(0);
              setGenerate(!generate);
            }}
          />

          <Button
            style={{ marginLeft: "10px" }}
            label="Clear"
            className="p-button-outlined font-light"
            onClick={(e) => {
              setCategory("");
              setTitle("");
              setLocation("");
              setIsSearch(false);
              setCurrentPage(0);
            }}
          />
        </div>
      </div>
      <div>
        {/* {categoryOption?.map((item, index) => (
          <div>val</div>
        ))} */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          {categoryData
            .slice(0, categoryData.length - 1)
            ?.map((item, index) => (
              <div className="smallContainer" key={index}>
                <div style={{ textAlign: "center" }}>
                  <div>
                    <img
                      src={item.link}
                      alt="icon"
                      style={{ height: "40px" }}
                    />
                  </div>
                  <strong>{item.membership_category}</strong>
                  <div style={{ color: "grey", fontSize: "12PX" }}>
                    {item.count} Members
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div
        className="dataview-demo"
        style={{ justifyContent: "center", display: "flex" }}
      >
        <div className="card" style={{ width: "80%" }}>
          <DataView
            rows={10}
            layout="grid"
            itemTemplate={itemTemplate}
            value={allMembers}
          />
          <Paginator
            first={basicFirst}
            rows={basicRows}
            totalRecords={count}
            onPageChange={onBasicPageChange}
          ></Paginator>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
