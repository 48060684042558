import React, { useState, useRef } from "react";
import { useFormik } from "formik";
import {
  register,
  updateMember,
  uploadMembersImages,
} from "../../../../../apis/api";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";
import AdminMenuBar from "../../../AdminMenuBar";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
export const MemberShipForm = () => {
  const [displayBasic, setDisplayBasic] = useState(false);
  const [position, setPosition] = useState("center");
  const [resId, setResId] = useState("");

  const [representative1Image, setRepresentative1Image] = useState([]);
  const [representative2Image, setRepresentative2Image] = useState([]);
  const [representative3Image, setRepresentative3Image] = useState([]);
  const [signature1, setSignature1] = useState([]);
  const [signature2, setSignature2] = useState([]);
  const [signature3, setSignature3] = useState([]);
  const [profileImage, setProfileImage] = useState([]);
  const [scannedCopy, setScannedCopy] = useState([]);
  const [userEmail, setUserEmail] = useState("");
  const location = useLocation();
  const [date, setDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [img1, setImg1] = useState(null);
  const [img2, setImg2] = useState(null);
  const [img3, setImg3] = useState(null);
  const navigate = useNavigate();

  const onClick = (name, position) => {
    setDisplayBasic(true);

    if (position) {
      setPosition(position);
    }
  };

  const onHide = (name) => {
    setDisplayBasic(false);
  };

  const renderFooter = (name) => {
    return (
      <div>
        <Button
          label="No"
          icon="pi pi-times"
          onClick={() => {
            onHide(name);
          }}
          className="p-button-text"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          onClick={() => {
            const fd = new FormData();
            fd.append("representative1", representative1Image);
            fd.append("representative2", representative2Image);
            fd.append("representative3", representative3Image);
            fd.append("signature1", signature1);
            fd.append("signature2", signature2);
            fd.append("signature3", signature3);
            fd.append("profile", profileImage);
            fd.append("scannedCopy", scannedCopy);
            uploadMembersImages(resId, fd).then((res) => {
              if (Array.isArray(res)) {
                showUploadSuccess();
                onHide(name);
              }
            });
          }}
          autoFocus
        />
      </div>
    );
  };
  const uploadImages = async (id) => {
    const fd = new FormData();
    if (img1 !== undefined && img1 !== null) {
      fd.append("representative1", img1);
    }
    if (img2 !== undefined && img2 !== null) {
      fd.append("representative2", img2);
    }
    if (img3 !== undefined && img3 !== null) {
      fd.append("representative3", img3);
    }
    uploadMembersImages(id, fd).then((res) => {
      console.log("res", res);
      if (Array.isArray(res)) {
        showUploadSuccess();
        setTimeout(() => {
          navigate("/userManagement/allMembers");
        }, 3000);
      } else {
        console.log("error", res);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "There is an Error while upload images. Please Try Again",
          life: 3000,
        });
      }
    });
  };
  const formik = useFormik({
    initialValues: {
      // encrypted_password: "",
      email: "",
      name_of_org: "",
      address: "",
      phone_number: "",
      fax: "",
      mobile: "",
      website: "",
      year_of_estd: "",
      nature_of_business: "",
      member_category: "",
      membership_category: "",
      annual_turnover: "",
      authorized_capital: "",
      paid_up_capital: "",
      domestic_sales: "",
      exports_sales: "",
      total_sales: "",
      product_desc: "",
      GSTIN: "",
      PAN: "",
      MSME_registration_no: "",
      name_of_representative1: "",
      name_of_representative2: "",
      name_of_representative3: "",
      designation_of_representative1: "",
      designation_of_representative2: "",
      designation_of_representative3: "",
      MEM_id_no: "",
      payment_type: "",
      payment_type_no: "",
      // payment_on_date: "",
      // payment_drawn_date: "",
      // subscription_period: "",
      type_of_member: "",
      // new added fileds
      proposed_by: "",
      proposed_by_id_no: "",
      seconded_by: "",
      seconded_by_id_no: "",
      address2: "",
      address3: "",
      city: "",
      pincode: "",
      state: "",
      landline1: "",
      landline2: "",
      landline3: "",
      description: "",
      cin_no: "",
      date_of_admission: "",
      date_of_estd: "",
      mobile_of_repr2: "",
      mobile_of_repr3: "",
      membership_terminated: "",
      introducer_name: "",
      introducer_mobile_no: "",
      referred_by: "",
      remarks: "",
      is_active: "",
      amount_collected: "",
    },
    validate: (data) => {
      let errors = {};
      if (!data.name_of_org) {
        errors.name_of_org = "This Field is Required.";
      }
      if (!data.address) {
        errors.address = "This Field is Required.";
      }
      if (!data.MEM_id_no) {
        errors.MEM_id_no = "This Field is Required.";
      }
      if (!data.membership_category) {
        errors.membership_category = "This Field is Required.";
      }
      if (!data.member_category) {
        errors.member_category = "This Field is Required.";
      }
      if (!data.name_of_representative1) {
        errors.name_of_representative1 = "This Field is Required";
      }
      if (!data.designation_of_representative1) {
        errors.designation_of_representative1 = "This Field is Required";
      }
      if (!data.state) {
        errors.state = "This Field is Required";
      }
      return errors;
    },
    onSubmit: (data) => {
      // console.log("data", data.subscription_period, date);

      const registerData = {};
      console.log(
        Object.keys(data)
          .filter((item) => data[item].length !== 0)
          .map((key) => (registerData[key] = data[key]))
      );
      const { email, ...newdata } = registerData;

      const rData = {
        ...newdata,
        email:
          formik.values.email !== ""
            ? formik.values.email
            : formik.values.MEM_id_no,
      };
      console.log("data", rData);
      register(rData)
        .then((res) => {
          return res.data;
        })
        .then((res) => {
          console.log("res", res);
          if (res.status === "success") {
            console.log("res", res?.data);
            if (img1 !== null || img2 !== null || img3 !== null) {
              uploadImages(res?.data?.member_id);
              setResId(res?.data?.member_id);
            } else {
              showSuccess();
              setTimeout(() => {
                navigate("/userManagement/allMembers");
              }, 3000);
            }
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Something went wrong try agian later!",
              life: 3000,
            });
          }
        })
        .catch((err) => {
          console.log("error", err);
          if (err?.response?.status === 401) {
            window.location.replace("/");
          } else {
            console.log("err", err?.response?.status);
            if (err?.response?.data?.err?.data) {
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: err?.response?.data?.err?.data,
                life: 3000,
              });
            } else {
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: "Something went wrong try agian later!",
                life: 3000,
              });
            }
          }
        });
      formik.handleReset();
    },
  });

  // onFileUpload = () => {

  //   // Create an object of formData
  //   const formData = new FormData();

  //   // Update the formData object
  //   formData.append(
  //     "myFile",
  //     this.state.selectedFile,
  //     this.state.selectedFile.name
  //   );

  //   // Details of the uploaded file
  //   console.log(this.state.selectedFile);

  //   // Request made to the backend api
  //   // Send formData object
  //   axios.post("api/uploadfile", formData);
  // };

  // console.log("formik val", formik.values);
  // const genderOptions = ["Male", "Female", "Prefer Not to Respond"];
  const memberOptions = ["ordinary", "patron"];
  const toast = useRef(null);

  const showSuccess = (e) => {
    toast.current.show({
      severity: "success",
      summary: "Membership Form Submitted",
      detail:
        "The Membership form has been submitted and the respective authorities will review and approve",
      life: 5000,
    });
  };
  const showUploadSuccess = (e) => {
    toast.current.show({
      severity: "success",
      summary: "All Documents Submitted",
      detail: "All the respective Documents were Uploaded",
      life: 5000,
    });
  };

  const showError = () => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: "There is an Error, Deleting the User. Please Try Again",
      life: 3000,
    });
  };
  const formatDate = (val) => {
    const date = new Date(val);
    return date;
  };
  const setNatureOfBussiness = (val) => {
    if (
      val === "Small Scale Manufacturing Activity" ||
      val === "Large Scale Manufacturing Activity"
    ) {
      formik.values.nature_of_business = "Manufacture";
    }
    if (
      val === "Small Scale Trading Activity" ||
      val === "Large Scale Trading Activity"
    ) {
      formik.values.nature_of_business = "Trade";
    }
    if (
      val === "Small Scale Service Activity" ||
      val === "Large Scale Service Activity"
    ) {
      formik.values.nature_of_business = "Service";
    }
    if (val === "District Chambers Of Commerce") {
      formik.values.nature_of_business = "District Chambers Of Commerce";
    }
    if (val === "Association") {
      formik.values.nature_of_business = "Association";
    }
    if (val === "Profession") {
      formik.values.nature_of_business = "Profession";
    }
  };
  useEffect(() => {}, []);

  const membershipCategory = [
    "Small Scale Manufacturing Activity",
    "Large Scale Manufacturing Activity",
    "Small Scale Trading Activity",
    "Large Scale Trading Activity",
    "Small Scale Service Activity",
    "Large Scale Service Activity",
    "District Chambers Of Commerce",
    "Association",
    "Profession",
  ];
  const nature_of_business = [
    "Manufacture",
    "Trade",
    "Service",
    "Profession",
    "District Chambers Of Commerce",
    "Association",
  ];
  console.log(representative3Image);
  return (
    <div style={{}}>
      <div>
        <div
          className="title"
          style={{ textAlign: "center", margin: "40px 0px" }}
        >
          <Toast ref={toast}></Toast>
        </div>

        <div className="form  ">
          <form
            className="card-container text-left"
            onSubmit={formik.handleSubmit}
          >
            <div className="p-fluid align-items-center justify-content-evenly">
              <div className="p-fluid flex flex-wrap justify-content-evenly">
                <div className="p-field m-2 w-3 m-2">
                  {formik.errors.MEM_id_no ? (
                    <label className="text-red-400 m-1">Membership ID * </label>
                  ) : (
                    <label className="m-1">Membership ID * </label>
                  )}

                  <InputText
                    id="MEM_id_no"
                    value={formik.values.MEM_id_no}
                    onChange={formik.handleChange}
                  ></InputText>
                </div>
                <div className="p-field m-2 w-3 m-2">
                  {formik.errors.member_category ? (
                    <label className="text-red-400 m-1">
                      Member Category *{" "}
                    </label>
                  ) : (
                    <label className="m-1">Member Category * </label>
                  )}
                  <Dropdown
                    id="member_category"
                    value={formik.values.member_category}
                    onChange={formik.handleChange}
                    options={memberOptions}
                  ></Dropdown>
                </div>
                <div className="p-field m-2 w-3 m-2">
                  {formik.errors.membership_category ? (
                    <label className="text-red-400 m-1">
                      Membership Category *{" "}
                    </label>
                  ) : (
                    <label className="m-1">Membership Category * </label>
                  )}
                  <Dropdown
                    id="membership_category"
                    value={formik.values.membership_category}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setNatureOfBussiness(e.target.value);
                    }}
                    options={membershipCategory}
                  ></Dropdown>
                </div>
                {/* <div className="p-field w-3 m-2">
                  <label>Admission Fee</label>
                  <InputText
                    id="admission_fee"
                    value={formik.values.admission_fee}
                    onChange={formik.handleChange}
                  ></InputText>
                </div> */}
                <div className="p-field w-3 m-2">
                  {formik.errors.name_of_org ? (
                    <label className="text-red-400 m-1">
                      Name of Organisation *{" "}
                    </label>
                  ) : (
                    <label className="m-1">Name of Organisation * </label>
                  )}
                  <InputText
                    id="name_of_org"
                    value={formik.values.name_of_org}
                    onChange={formik.handleChange}
                  ></InputText>
                </div>
                <div className="p-field w-3 m-2">
                  <label>Admission Date</label>
                  <Calendar
                    id="date_of_admission"
                    value={formik.values.date_of_admission}
                    onChange={formik.handleChange}
                  ></Calendar>
                </div>
                <div className="p-field w-3 m-2">
                  <label>Establishment Date</label>
                  <Calendar
                    id="date_of_estd"
                    value={formik.values.date_of_estd}
                    onChange={formik.handleChange}
                  ></Calendar>
                </div>
              </div>
              {/* <div className="text-sm flex justify-content-evenly  mt-3">
                <div className="p-field w-25rem">
                  <div style={{ textAlign: "center" }}>
                    <h3>Representative No.1 Details</h3>
                  </div>
                </div>

                <div className="p-field w-25rem">
                  <div style={{ textAlign: "center" }}>
                    <h3>Representative No.2 Details</h3>
                  </div>

                  <div className="m-2">
                    <label className="m-1">
                      Address of the Representative 2
                    </label>
                    <InputText
                      id="address2"
                      value={formik.values.address2}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="m-2">
                    <label className="m-1">
                      Mobile Number of the Representative 2
                    </label>
                    <InputText
                      id="mobile_of_repr2"
                      value={formik.values.mobile_of_repr2}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <div className="p-field w-25rem ">
                  <div style={{ textAlign: "center" }}>
                    <h3>Representative No.3 Details</h3>
                  </div>

                  <div className="m-2">
                    <label className="m-1">
                      Address of the Representative 3
                    </label>
                    <InputText
                      id="address3"
                      value={formik.values.address3}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="m-2">
                    <label className="m-1">
                      Mobile Number of the Representative 3
                    </label>
                    <InputText
                      id="mobile_of_repr3"
                      value={formik.values.mobile_of_repr3}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </div> */}
            </div>
            <div className="flex bg-gray-200 align-items-start justify-content-evenly m-2 p-2">
              <div className="w-25rem text-sm flex align-items-center justify-content-center">
                <div className=" p-fluid m-2 p-2">
                  <div className="p-field m-2">
                    {formik.errors.address ? (
                      <label className="text-red-400 m-1">Address * </label>
                    ) : (
                      <label className="m-1">Address * </label>
                    )}
                    <InputText
                      id="address"
                      value={formik.values.address}
                      onChange={formik.handleChange}
                    ></InputText>
                  </div>
                  <div className="m-2">
                    {formik.errors.name_of_representative1 ? (
                      <label className="text-red-400 m-1">
                        Name of the Representative 1 *{" "}
                      </label>
                    ) : (
                      <label className="m-1">
                        Name of the Representative 1 *{" "}
                      </label>
                    )}
                    <InputText
                      id="name_of_representative1"
                      value={formik.values.name_of_representative1}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="m-2">
                    {formik.errors.designation_of_representative1 ? (
                      <label className="text-red-400 m-1">
                        Designation of the Representative 1*{" "}
                      </label>
                    ) : (
                      <label className="m-1">
                        Designation of the Representative 1*{" "}
                      </label>
                    )}
                    <InputText
                      id="designation_of_representative1"
                      value={formik.values.designation_of_representative1}
                      onChange={formik.handleChange}
                    />
                  </div>

                  <div className="m-2">
                    <label className="m-1">
                      Mobile Number of the Representative 1{" "}
                    </label>
                    <InputText
                      id="mobile"
                      value={formik.values.mobile}
                      onChange={formik.handleChange}
                    ></InputText>
                  </div>
                  <div className="m-2">
                    <label className="m-1">Image of Representative 1 </label>
                    <input
                      id="mobile"
                      type={"file"}
                      style={{
                        marginTop: "5px",
                        borderRadius: "7px",
                        border: "1px solid #E0E0E0",
                        height: "50px",
                        padding: "12px",
                        width: "100%",
                        backgroundColor: "#ffffff",
                      }}
                      onChange={(e) => {
                        setImg1(e.target.files[0]);
                      }}
                    />
                  </div>
                  {/* <div className="p-field m-2">
                    {formik.errors.type_of_member ? (
                      <label className="text-red-400 m-1">
                        Type of Member *{" "}
                      </label>
                    ) : (
                      <label className="m-1">Type of Member * </label>
                    )}
                    <Dropdown
                      id="type_of_member"
                      options={["old", "new"]}
                      value={formik.values.type_of_member}
                      onChange={formik.handleChange}
                    ></Dropdown>
                  </div> */}
                  <div className="m-2">
                    <label className="m-1">Email </label>

                    <InputText
                      id="email"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      disabled={location.state.isUpdate}
                    />
                  </div>

                  <div className="p-field m-2">
                    <label className="m-1">Website </label>
                    <InputText
                      id="website"
                      value={formik.values.website}
                      onChange={formik.handleChange}
                    ></InputText>
                  </div>
                </div>
              </div>
              <div className="w-25rem text-sm  flex align-items-center justify-content-center">
                <div className=" p-fluid m-2 p-2">
                  <div className="p-field m-2">
                    <label className="m-1">City </label>
                    <InputText
                      id="city"
                      value={formik.values.city}
                      onChange={formik.handleChange}
                    ></InputText>
                  </div>
                  <div className="m-2">
                    <label>Name of the Representative 2 </label>
                    <InputText
                      id="name_of_representative2"
                      value={formik.values.name_of_representative2}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="m-2">
                    <label>Designation of the Representative 2 </label>
                    <InputText
                      id="designation_of_representative2"
                      value={formik.values.designation_of_representative2}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="m-2">
                    <label>Mobile Number of the Representative 2</label>
                    <InputText
                      id="mobile_of_repr2"
                      value={formik.values.mobile_of_repr2}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="m-2">
                    <label className="m-1">Image of Representative 2 </label>
                    <input
                      id="mobile"
                      type={"file"}
                      style={{
                        marginTop: "5px",
                        borderRadius: "7px",
                        border: "1px solid #E0E0E0",
                        height: "50px",
                        padding: "12px",
                        width: "100%",
                        backgroundColor: "#ffffff",
                      }}
                      onChange={(e) => {
                        setImg2(e.target.files[0]);
                      }}
                    />
                  </div>
                  {/* <div className="p-field m-2">
                    {formik.errors.encrypted_password ? (
                      <label className="text-red-400 m-1">Password *</label>
                    ) : (
                      <label className="m-1">Password *</label>
                    )}
                    <InputText
                      onChange={formik.handleChange}
                      value={formik.values.encrypted_password}
                      id="encrypted_password"
                      type="password"
                      disabled={location.state.isUpdate}
                    />
                  </div> */}

                  {/* <div className="p-field m-2">
                    <label className="m-1">Year of Establishment</label>
                    <InputText
                      id="year_of_estd"
                      value={formik.values.year_of_estd}
                      onChange={formik.handleChange}
                    ></InputText>
                  </div> */}

                  <div className="p-field m-2">
                    <label className="m-1">Fax</label>
                    <InputText
                      id="fax"
                      value={formik.values.fax}
                      onChange={formik.handleChange}
                    ></InputText>
                  </div>
                  <div className="p-field m-2">
                    <label className="m-1">Phone Number </label>

                    <InputText
                      onChange={formik.handleChange}
                      value={formik.values.phone_number}
                      id="phone_number"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className=" w-25rem text-sm  flex align-items-center justify-content-center">
                <div className=" p-fluid m-2 p-2">
                  <div className="p-field m-2">
                    <label className="m-1">Pincode</label>
                    <InputText
                      id="pincode"
                      value={formik.values.pincode}
                      onChange={formik.handleChange}
                    ></InputText>
                  </div>
                  <div className="p-field m-2">
                  {formik.errors.state ? (
                      <label className="text-red-400 m-1">
                        State *{" "}
                      </label>
                    ) : (
                      <label className="m-1">
                        State *{" "}
                      </label>
                    )}
                    <InputText
                      id="state"
                      value={formik.values.state}
                      onChange={formik.handleChange}
                    ></InputText>
                  </div>

                  <div className="m-2">
                    <label>Name of the Representative 3 </label>
                    <InputText
                      id="name_of_representative3"
                      value={formik.values.name_of_representative3}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="m-2">
                    <label className="m-1">
                      Designation of the Representative 3
                    </label>
                    <InputText
                      id="designation_of_representative3"
                      value={formik.values.designation_of_representative3}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="m-2">
                    <label className="m-1">
                      Mobile Number of the Representative 3
                    </label>
                    <InputText
                      id="mobile_of_repr3"
                      value={formik.values.mobile_of_repr3}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="m-2">
                    <label className="m-1">Image of Representative 3 </label>
                    <input
                      id="mobile"
                      type={"file"}
                      style={{
                        marginTop: "5px",
                        borderRadius: "7px",
                        border: "1px solid #E0E0E0",
                        height: "50px",
                        padding: "12px",
                        width: "100%",
                        backgroundColor: "#ffffff",
                      }}
                      onChange={(e) => {
                        setImg3(e.target.files[0]);
                      }}
                    />
                  </div>
                  <div className="p-field m-2">
                    <label className="m-1">Nature of Business </label>
                    <Dropdown
                      id="nature_of_business"
                      value={formik.values.nature_of_business}
                      onChange={formik.handleChange}
                      options={nature_of_business}
                      disabled
                    ></Dropdown>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-3 m-2 flex text-sm border-round ">
              <div className="p-fluid flex flex-wrap justify-content-evenly">
                <div className="p-field w-3 m-2">
                  <label>GSTIN</label>
                  <InputText
                    id="GSTIN"
                    value={formik.values.GSTIN}
                    onChange={formik.handleChange}
                  ></InputText>
                </div>
                <div className="p-field w-3 m-2">
                  <label>CIN</label>
                  <InputText
                    id="cin_no"
                    value={formik.values.cin_no}
                    onChange={formik.handleChange}
                  ></InputText>
                </div>

                <div className="p-field w-3 m-2">
                  <label>PAN</label>
                  <InputText
                    id="PAN"
                    value={formik.values.PAN}
                    onChange={formik.handleChange}
                  ></InputText>
                </div>
                <div className="p-field w-3 m-2">
                  <label htmlFor="integeronly">Annual Turnover</label>
                  <InputText
                    id="annual_turnover"
                    value={formik.values.annual_turnover}
                    onChange={formik.handleChange}
                  ></InputText>
                </div>
                <div className="p-field w-3 m-2">
                  <label>MSME Registration No</label>
                  <InputText
                    id="MSME_registration_no"
                    value={formik.values.MSME_registration_no}
                    onChange={formik.handleChange}
                  ></InputText>
                </div>
                <div className="p-field w-3 m-2">
                  <label>Proposed By</label>
                  <InputText
                    id="proposed_by"
                    value={formik.values.proposed_by}
                    onChange={formik.handleChange}
                  ></InputText>
                </div>
                <div className="p-field w-3 m-2">
                  <label>Seconded By</label>
                  <InputText
                    id="seconded_by"
                    value={formik.values.seconded_by}
                    onChange={formik.handleChange}
                  ></InputText>
                </div>
                <div className="p-field w-3 m-2">
                  <label>Introducer Name</label>
                  <InputText
                    id="introducer_name"
                    value={formik.values.introducer_name}
                    onChange={formik.handleChange}
                  ></InputText>
                </div>
                <div className="p-field w-3 m-2">
                  <label>Mobile Number of the Introducer</label>
                  <InputText
                    id="introducer_mobile_no"
                    value={formik.values.introducer_mobile_no}
                    onChange={formik.handleChange}
                  ></InputText>
                </div>
                <div className="p-field w-3 m-2">
                  <label>Referred By</label>
                  <InputText
                    id="referred_by"
                    value={formik.values.referred_by}
                    onChange={formik.handleChange}
                  ></InputText>
                </div>
                {/* <div className="p-field w-3 m-2">
                  <label>Proposed By ID Number</label>
                  <InputText
                    id="proposed_by_id_no"
                    value={formik.values.proposed_by_id_no}
                    onChange={formik.handleChange}
                  ></InputText>
                </div> */}
                <div className="p-field w-3 m-2">
                  <label>Status</label>
                  <Dropdown
                    id="is_active"
                    options={[
                      { name: "Active", code: "true" },
                      { name: "Deactive", code: "false" },
                    ]}
                    optionLabel="name"
                    optionValue="code"
                    value={formik.values.is_active}
                    onChange={formik.handleChange}
                  ></Dropdown>
                </div>
                <div className="p-field w-3 m-2">
                  <label>Remarks</label>
                  <InputText
                    id="remarks"
                    value={formik.values.remarks}
                    onChange={formik.handleChange}
                  ></InputText>
                </div>
                {/* <div className="p-field w-3 m-2">
                  <label>Seconded By ID Number</label>
                  <InputText
                    id="seconded_by_id_no"
                    value={formik.values.seconded_by_id_no}
                    onChange={formik.handleChange}
                  ></InputText>
                </div> */}
              </div>
            </div>
            {/* <div className="flex border-round align-items-start ">
              <div className="w-6 text-sm flex align-items-center justify-content-center">
                <div className="w-6 m-2 ">
                  <div className="m-2 text-center">
                    <h3>Financial Information</h3>
                  </div>
                  <div className="p-fluid flex justify-content-center">
                    <div className="w-10">
                      <div className="p-field m-2"></div>
                      <div className="p-field m-2">
                        <label>Authorized Capital</label>
                        <InputText
                          id="authorized_capital"
                          value={formik.values.authorized_capital}
                          onChange={formik.handleChange}
                        ></InputText>
                      </div>
                      <div className="p-field m-2">
                        <label>Paid-up Capital</label>
                        <InputText
                          id="paid_up_capital"
                          value={formik.values.paid_up_capital}
                          onChange={formik.handleChange}
                        ></InputText>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-6 m-2">
                  <div className="m-2 text-center">
                    <h3>Composition of Sales</h3>
                  </div>
                  <div className="p-fluid flex justify-content-center">
                    <div className="w-10">
                      <div className="p-field m-2">
                        <label htmlFor="integeronly">Domestic Sales</label>
                        <InputText
                          id="domestic_sales"
                          value={formik.values.domestic_sales}
                          onChange={formik.handleChange}
                        ></InputText>
                      </div>
                      <div className="p-field m-2">
                        <label>Exports Sales</label>
                        <InputText
                          id="exports_sales"
                          value={formik.values.exports_sales}
                          onChange={formik.handleChange}
                        ></InputText>
                      </div>
                      <div className="p-field m-2">
                        <label>Total Sales</label>
                        <InputText
                          id="total_sales"
                          value={formik.values.total_sales}
                          onChange={formik.handleChange}
                        ></InputText>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-6 text-sm  flex align-items-center justify-content-center">
                <div className="p-fluid m-2">
                  <div className="m-2 text-center">
                    <h3>Enter the Following Details as Applicable</h3>
                  </div>

                  <div className="flex justify-content-center">
                    <div style={{ width: "70%", margin: "0px 30px" }}></div>
                    <div style={{ width: "70%", margin: "0px 30px" }}>
                      <div className="p-field m-2"></div>
                      <div className="p-field m-2"></div>
                      <div className="p-field m-2"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="p-fluid flex  text-sm justify-content-center mx-5 ">
              <div className="p-field m-2 w-full text-center">
                <h3>Product Description</h3>
                <InputTextarea
                  id="product_desc"
                  value={formik.values.product_desc}
                  onChange={formik.handleChange}
                  rows={3}
                  className="w-full"
                  fullResize
                ></InputTextarea>
              </div>
            </div>
            <h3
              style={{
                textAlign: "center",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              Payment Details
            </h3>
            <div className="bg-gray-200 p-4 px-8 text-sm border-round">
              <div className="p-fluid flex flex-wrap justify-content-between align-center px-6">
                <div className="p-field w-3 m-2">
                  <label>Payment Type</label>
                  <Dropdown
                    id="payment_type"
                    options={["cheque", "NEFT"]}
                    value={formik.values.payment_type}
                    onChange={formik.handleChange}
                  ></Dropdown>
                </div>
                <div className="p-field w-3 m-2">
                  <label>Payment Details</label>
                  <InputText
                    id="payment_type_no"
                    value={formik.values.payment_type_no}
                    onChange={formik.handleChange}
                  ></InputText>
                </div>
                {/* <div className="p-field w-3 m-2">
                  <label>Payment on Date</label>
                  <Calendar
                    id="payment_on_date"
                    value={formik.values.payment_on_date}
                    onChange={formik.handleChange}
                  ></Calendar>
                </div>
                <div className="p-field w-3 m-2">
                  <label>Payment Drawn Date</label>
                  <Calendar
                    id="payment_drawn_date"
                    value={formik.values.payment_drawn_date}
                    onChange={formik.handleChange}
                  ></Calendar>
                </div> */}

                <div className="p-field w-3 m-2">
                  <label>Amount Collected</label>
                  {/* <Calendar
                    id="range"
                    value={date}
                    // onChange={formik.handleChange}
                    onChange={(e) => {
                      setDate(e.value);
                      setStartDate(
                        e.value[0] !== null && e.value !== undefined
                          ? moment(e.value[0]).format("DD-MM-YYYY")
                          : undefined
                      );
                      setEndDate(
                        e.value[1] !== null && e.value !== undefined
                          ? moment(e.value[1]).format("DD-MM-YYYY")
                          : undefined
                      );
                    }}
                    selectionMode="range"
                    readOnlyInput
                  /> */}
                  <InputText
                    id="amount_collected"
                    value={formik.values.amount_collected}
                    onChange={formik.handleChange}
                  ></InputText>
                </div>

                {/* <div className="p-field w-3 m-2">
                  <label>Description</label>
                  <InputText
                    id="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                  ></InputText>
                </div> */}

                <div className="p-field w-3 m-2">
                  <label>Membership Terminated</label>

                  <Dropdown
                    options={[
                      { name: "Yes", code: "true" },
                      { name: "No", code: "false" },
                    ]}
                    id="membership_terminated"
                    value={formik.values.membership_terminated}
                    onChange={formik.handleChange}
                    optionLabel="name"
                    optionValue="code"
                  ></Dropdown>
                </div>
              </div>
            </div>
            <div
              style={{
                textAlign: "center",
                marginBottom: "100px",
                marginTop: "30px",
              }}
            >
              {location.state?.isUpdate ? (
                <Button
                  type="submit"
                  // onClick={(e) => {
                  //   console.log("update");
                  // }}
                  label="Update"
                ></Button>
              ) : (
                <Button type="submit" label="Submit Membership Form"></Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
