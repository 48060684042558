import React, { useState, useEffect } from "react";
import AdminMenuBar from "../AdminMenuBar";
import { TabMenu } from "primereact/tabmenu";
import { useNavigate, useLocation, Outlet } from "react-router-dom";

function Events() {
  const userType = window.localStorage.getItem("userType");

  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  let location = useLocation();
  console.log(location.pathname.split("/")[2]);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (location.pathname.split("/")[2] === "allEvents") {
      setActiveIndex(0);
    }
    if (location.pathname.split("/")[2] === "AddNewEvent") {
      setActiveIndex(1);
    }
    if (location.pathname.split("/")[2] === "allMembers") {
      setActiveIndex(2);
    }
    if (location.pathname.split("/")[2] === "addNewUser") {
      setActiveIndex(3);
    }
    if (location.pathname.split("/")[2] === "addNewMember") {
      setActiveIndex(4);
    }
  }, [location.pathname]);
  useEffect(() => {
    setLoading(false);
  }, [isLoading]);

  const items = [
    { label: "All Events", icon: "pi pi-fw pi-pencil" },
    
  ];
  if (userType === "Office User") {
    items.push({
      label: "Add Events",
      icon: "pi pi-fw pi-home",
    });
  }
  return (
    <div>
      <AdminMenuBar />
      <TabMenu
        activeIndex={activeIndex}
        onTabChange={(e) => {
          if (e.index === 0) {
            setActiveIndex(e.index);
            setLoading(true);
            navigate("allEvents");
          }
          if (e.index === 1) {
            setActiveIndex(e.index);
            setLoading(true);
            navigate("AddNewEvent");
          }
        }}
        className="m-3"
        model={items}
      />
      <Outlet />
    </div>
  );
}

export default Events;
