import React, { useEffect, useState } from "react";
import AdminMenuBar from "../../AdminMenuBar";
import { getAllJournals } from "../../../../apis/api";
import { DataView } from "primereact/dataview";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import { useNavigate } from "react-router-dom";
import moment from "moment";
function ListAllJournals() {
  const [allEvents, setAllEvents] = useState([]);

  useEffect(() => {
    getAllJournals().then((res) => {
      console.log("res", res);
      setAllEvents(res);
    });
  }, []);
  const navigate = useNavigate();
  console.log(allEvents);
  const header = (
    <div className="flex justify-content-between align-items-center ">
      <h2 className="m-0">All Events</h2>
      <div>
        <Button
          icon="pi pi-globe"
          className="ml-3"
          label="Add New Event"
          onClick={() => navigate("/addNewEvent")}
        ></Button>
      </div>
    </div>
  );

  const itemTemplate = (data) => {
    return (
      <div className="p-col-12 md:col-2 ">
        <div className="product-grid-item card p-2">
          <div
            className="justify-content-between flex flex-column h-15rem justify-content-between"
            onClick={() => {
              window.open(data?.pdf);
            }}
          >
            <div>
              <img
                style={{ width: "150px", height: "150px", alignSelf: "center" }}
                alt=""
                src={
                  data?.image
                    ? data?.image
                    : "https://png.pngtree.com/png-vector/20190804/ourlarge/pngtree-map-location-pin-world-abstract-flat-color-icon-template-png-image_1650126.jpg"
                }
              />
              <div className="product-name">{data?.month}</div>
            </div>
            {/* <div className="product-text">
              <div>{data.date}</div>

              <Button
                onClick={() => navigate("/eventDetails", { state: data })}
              >
                View Event
              </Button>
            </div> */}
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <div className="dataview-demo">
        <div className="m-2 p-2">
          <DataView
            paginator
            layout={"grid"}
            rows={12}
            itemTemplate={itemTemplate}
            value={allEvents}
          />
        </div>
      </div>
    </div>
  );
}

export default ListAllJournals;
