import Login from "./pages/Login";
import "primeflex/primeflex.css";
import "./App.css";
import HomePage from "./site/customers/users/HomePage";
import AdminLogin from "./pages/AdminLogin";
import ListAllUsers from "./site/admin/UserManagement/components/AllUsers/ListAllUsers";
import { MemberShipForm } from "./site/admin/UserManagement/components/AddNewMembers/MemberShipForm";
import { Routes, Route } from "react-router-dom";

import MembershipDetails from "./site/customers/users/MembershipDetails";
import EventsDetail from "./site/customers/users/EventsDetail";
import AdminAuthGuard from "./guards/AdminAuthGuard";
import { UpdateMembershipForm } from "./site/admin/UpdateMembershipForm";
import AddNewEvent from "./site/admin/Events/AddEvents/AddNewEvent";
import AddNewUser from "./site/admin/UserManagement/components/AddNewUser/AddNewUser";
import ListAllMembers from "./site/admin/UserManagement/components/ApprovedMembers/ListAllMembers";
import ListAllPendingApprovals from "./site/admin/UserManagement/components/PendingApprovals/ListAllPendingApprovals";
import MemberDetails from "./site/admin/UserManagement/components/MemberDetails/MemberDetails";
import UserManagement from "./site/admin/UserManagement/UserManagement";
import Events from "./site/admin/Events/Events";
import ListAllEvents from "./site/admin/Events/ListAllEvents/ListAllEvents";
import AllEvents from "./site/customers/users/AllEvents";
import UsersProfile from "./site/customers/users/UsersProfile";
import MemberShipDescription from "./site/customers/users/MemberShipDescription";
import Contact from "./site/customers/users/Contact";
import ListOfAllRecipt from "./site/admin/UserManagement/components/Receipt/ListOfAllRecipt";
import { createBrowserHistory } from "history";

import ActiviFeed from "./site/admin/UserManagement/components/ActvityFeed/ActiviFeed";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import PrintMebers from "./site/admin/UserManagement/components/ApprovedMembers/PrintMembers";
import Journals from "./site/admin/Journals/Journals";
import AddNewJournal from "./site/admin/Journals/AddJournals/AddNewJournal";
import ListAllJournals from "./site/admin/Journals/ListAllJournals/ListAllJournals";
const customHistory = createBrowserHistory();
const App = () => {
    const userType = window.localStorage.getItem("userType");

  const { token } = useSelector((state) => state.member);
  const logOut = () => {
    setTimeout(() => {
      window.location.replace("/");
      window.localStorage.clear();
    }, 43200000);
  };
  useEffect(() => {
    console.log("token", token);
    logOut();
  }, [token]);
  return (
    <div className="App">
      <Routes>
        <Route path="" element={<Login />}></Route>

        <Route
          path="userManagement"
          element={<AdminAuthGuard children={<UserManagement />} />}
        >
          <Route
            path="allUsers"
            element={<AdminAuthGuard children={<ListAllUsers />} />}
          ></Route>

          <Route
            path="pendingApprovals"
            element={<AdminAuthGuard children={<ListAllPendingApprovals />} />}
          ></Route>
          <Route
            path="memberDetails"
            element={
              <AdminAuthGuard
                children={<MemberDetails history={customHistory} />}
              />
            }
          ></Route>
          <Route
            path="allMembers"
            element={<AdminAuthGuard children={<ListAllMembers />} />}
          >
            <Route
              path="memberDetails"
              element={<AdminAuthGuard children={<MemberDetails />} />}
            ></Route>
          </Route>
          <Route
            path="addNewUser"
            element={<AdminAuthGuard children={<AddNewUser />} />}
          />
          <Route
            path="receipt"
            element={<AdminAuthGuard children={<ListOfAllRecipt />} />}
          />
          <Route
            path="activity-feed"
            element={<AdminAuthGuard children={<ActiviFeed />} />}
          />

          <Route
            path="addNewMember"
            element={<AdminAuthGuard children={<MemberShipForm />} />}
          ></Route>
        </Route>
        {/* <Route
            path="results"
            element={<AdminAuthGuard children={<Results />} />}
          ></Route> */}
        {/* <Route
            path="election"
            element={<AdminAuthGuard children={<Election />} />}
          >
            <Route
              path="accessCode"
              element={<AdminAuthGuard children={<Popup />} />}
            ></Route>
            <Route
              path="vote"
              element={<AdminAuthGuard children={<CandidatesTable />} />}
            ></Route>
          </Route> */}
        <Route path="updateMemberDetail" element={<UpdateMembershipForm />} />
        <Route path="events" element={<AdminAuthGuard children={<Events />} />}>
          <Route
            path="allEvents"
            element={<AdminAuthGuard children={<ListAllEvents />} />}
          />
          {userType === "Office User" ? (
            <Route
              path="addNewEvent"
              element={<AdminAuthGuard children={<AddNewEvent />} />}
            />
          ) : null}
        </Route>
        <Route
          path="journals"
          element={<AdminAuthGuard children={<Journals />} />}
        >
          <Route
            path="allJournals"
            element={<AdminAuthGuard children={<ListAllJournals />} />}
          />
          {userType === "Office User" ? (
            <Route
              path="addNewJournal"
              element={<AdminAuthGuard children={<AddNewJournal />} />}
            />
          ) : null}
        </Route>
      </Routes>

      <Routes>
        {/* Admin Routes */}

        <Route
          path="/adminLogin"
          element={<AdminAuthGuard children={<AdminLogin />} />}
        ></Route>
        {/* Members Routes */}
        <Route path="home" element={<HomePage />}></Route>
        {/* <Route path="allEvents" element={<AllEvents />}></Route> */}
        <Route path="eventDetails" element={<EventsDetail />}></Route>
        <Route path="allEvents" element={<AllEvents />}></Route>
        <Route path="membership" element={<MembershipDetails />}></Route>
        <Route path="userProfile" element={<UsersProfile />}></Route>
        <Route
          path="membershipDescription"
          element={<MemberShipDescription />}
        ></Route>
        <Route path="contactUs" element={<Contact />}></Route>
      </Routes>
    </div>
  );
};

export default App;
