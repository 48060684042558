import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { useFormik } from "formik";
import { createNewJournal } from "../../../../apis/api";
import { Toast } from "primereact/toast";
import { Divider } from "primereact/divider";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function AddNewJournal() {
  const [uploadImage, setUploadImage] = useState("");
  const [uploadPDF, setUploadPDF] = useState("");
  const toast = useRef(null);
  const navigate = useNavigate();
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  const showSuccess = () => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "New journal Added",
      life: 3000,
    });
  };
  const showError = () => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: "There is an Issue adding a new Journal, Please try Again",
      life: 3000,
    });
  };

  const formik = useFormik({
    initialValues: {
      month: "",
    },
    validate: (data) => {
      let errors = {};

      if (!data.month) {
        errors.event_name = "Event Name is required.";
      }
      return errors;
    },
    onSubmit: (data) => {
      console.log(moment(data?.month).format("MMM-YYYY"));
      const formdata = new FormData();
      formdata.append("month", moment(data?.month).format("MMMM-YYYY"));
      formdata.append("image", uploadImage);
      formdata.append("pdf", uploadPDF);
      createNewJournal(formdata)
        .then((data) => {
          showSuccess();
          setTimeout(() => {
            navigate(-1);
          }, 3000);
        })
        .catch((e) => {
          console.log(e);
          showError();
        });
    },
  });
  return (
    <div>
      <div className="">
        <Divider className="mt-5" align="center">
          <h2>Add Journal</h2>
        </Divider>
        <Toast ref={toast}></Toast>
        <div className="">
          <form className="p-fluid" onSubmit={formik.handleSubmit}>
            <div className="flex justify-content-center">
              <div className="card w-25rem mx-5 ">
                <div className="field my-5">
                  <label
                    htmlFor="month"
                    style={{
                      display: "block",
                      marginBottom: "5px",
                      textAlign: "left",
                    }}
                  >
                    Month and Year*
                  </label>
                  <Calendar
                    id="event_start"
                    name="month"
                    view="month"
                    dateFormat="mm/yy"
                    value={formik.values.event_start}
                    onChange={formik.handleChange}
                    autoFocus
                  />
                </div>

                <div className="field">
                  <label
                    htmlFor="month"
                    style={{
                      display: "block",
                      marginBottom: "5px",
                      textAlign: "left",
                    }}
                  >
                    PDF*
                  </label>
                  <input
                    className="fileUpload w-full h-3rem"
                    type="file"
                    onChange={(e) => setUploadPDF(e.target.files[0])}
                  />
                </div>
              </div>

              <div className="card w-25rem mx-5 ">
                <div className="field my-5">
                  <label
                    htmlFor="month"
                    style={{
                      display: "block",
                      marginBottom: "5px",
                      textAlign: "left",
                    }}
                  >
                    Image*
                  </label>
                  <input
                    className="fileUpload w-full h-3rem"
                    type="file"
                    onChange={(e) => setUploadImage(e.target.files[0])}
                  />
                </div>
              </div>
            </div>

            <Button
              className="w-15rem my-5"
              type="submit"
              label="Add Now"
            ></Button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddNewJournal;
