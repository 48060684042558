import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { getActivityFeed, getActivitySearch } from "../../../../../apis/api";
import { Paginator } from "primereact/paginator";
import { useDownloadExcel } from "react-export-table-to-excel";
const ActiviFeed = () => {
  const [searchMember, setSearchMember] = useState("");
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [url, setUrl] = useState("");
  const [data, setData] = useState([]);
  const tableRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    getActivityFeed(currentPage, 10, url)
      .then((res) => {
        return res;
      })
      .then((res) => {
        setData(res?.data?.results);
        setTotalRecords(res?.data?.total);
      });
  }, [currentPage]);
  const searchFeed = async () => {
    getActivitySearch(searchMember)
      .then((res) => {
        return res;
      })
      .then((res) => {
        console.log("res", res.data);
        setData(res?.data);
        setTotalRecords(res?.data?.length);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const date = (data) => {
    return (
      <div>
        <div>{moment(data?.created_at).format("DD MMM YYYY")}</div>
        <div>{moment(data?.created_at).format("h:mm a")}</div>
      </div>
    );
  };
  const title = (data) => {
    return (
      <a
        style={{ cursor: "pointer", color: "#1c7ed6" }}
        onClick={() => {
          navigate("/userManagement/memberDetails", {
            state: data?.member_id,
          });
        }}
      >
        {data?.member?.first_name}'s {data?.event_id} by{" "}
        {data?.author?.first_name} {data?.author?.last_name}
      </a>
    );
  };
  const handleMemberExcelPrint = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "FKCCI_Activity_Feed",
    sheet: "Activities",
  });
  const renderHeader = () => {
    return (
      <div className="flex justify-content-between align-items-center">
        <h2 className="m-0">Recent Activity</h2>
        {/* <Toast ref={toast}></Toast> */}

        <div>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={searchMember}
              onChange={(e) => setSearchMember(e.target.value)}
              placeholder="Search Member"
            />
            <Button
              onClick={() => {
                if (searchMember.length > 4) {
                  searchFeed();
                }
              }}
              className="ml-2"
              label="Search Member"
            ></Button>
            {/* <Button
              onClick={() => {
                handleMemberExcelPrint.onDownload();
              }}
              className="ml-2"
              label="Download"
            ></Button> */}
          </span>
        </div>
      </div>
    );
  };
  const header = renderHeader();
  const onBasicPageChange = (event) => {
    console.log("event", event);
    setBasicFirst(event.first);
    setBasicRows(event.rows);
    setCurrentPage(event.page);
  };
  return (
    <div>
      <div className="card w-9 m-auto">
        <DataTable header={header} value={data} showGridlines>
          <Column field={title} header="" style={{ width: "80%" }} />
          <Column field={date} header="" style={{ width: "20%" }} />
        </DataTable>
        <Paginator
          first={basicFirst}
          totalRecords={totalRecords}
          rows={basicRows}
          onPageChange={onBasicPageChange}
        ></Paginator>
      </div>
      <div style={{ display: "none" }}>
        <table ref={tableRef} border="1">
          <tbody>
            {data?.map((val) => (
              <tr>
                <td>
                  <p>
                    {val?.member?.first_name}'s {val?.event_id} by{" "}
                    {val?.author?.first_name} {val?.author?.last_name}
                  </p>
                </td>
                <td>
                  <div>{moment(val?.created_at).format("DD MMM YYYY")} {moment(val?.created_at).format("h:mm a")}</div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ActiviFeed;
