import { Button } from "primereact/button";
import React, { useRef, useState, useEffect } from "react";
import ReactToPrint from "react-to-print";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import { useFormik } from "formik";
import "./MemberDescription.css";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { useNavigate } from "react-router-dom";
import { RadioButton } from "primereact/radiobutton";
import {
  deleteMembersImages,
  updateMember,
  uploadMembersImages,
} from "../../../../../../apis/api";

function MemberDescription({
  data,
  rfid,
  setAccessCode,
  update,
  setUpdate,
  scanRfid,
}) {
  const toast = useRef(null);
  const componentRef = useRef();
  const [code, setCode] = useState("");
  const [displayBasic1, setDisplayBasic1] = useState(false);
  const [displayBasic2, setDisplayBasic2] = useState(false);
  const [displayBasic3, setDisplayBasic3] = useState(false);
  const [displayBasic4, setDisplayBasic4] = useState(false);

  const [representative1Image, setRepresentative1Image] = useState(null);
  const [representative2Image, setRepresentative2Image] = useState(null);
  const [representative3Image, setRepresentative3Image] = useState(null);
  const [id1, setId1] = useState(null);
  const [id2, setId2] = useState(null);
  const [id3, setId3] = useState(null);

  const [rfid1, setRfid1] = useState("");
  const [rfid2, setRfid2] = useState("");
  const [rfid3, setRfid3] = useState("");

  const [Email, setEmail] = useState(null);
  const [memberId, setMemberId] = useState(null);

  // const [rfid1, setRfid1] = useState("");
  const [index, setIndex] = useState(null);
  const [position, setPosition] = useState("center");
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const [ingredient, setIngredient] = useState("Yes");
  const navigate = useNavigate();
  const dialogFuncMap = {
    displayBasic1: setDisplayBasic1,
    displayBasic2: setDisplayBasic2,
    displayBasic3: setDisplayBasic3,
    displayBasic4: setDisplayBasic4,
  };

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };
  const styles = {
    width: "300px",
    border: "1px solid #000000",
    borderRadius: "0px",
    marginLeft: "2px",
    textAlign: "left",
    marginTop: "2px",
  };
  const styles2 = {
    width: "250px",
    border: "1px solid #000000",
    borderRadius: "0px",
    marginLeft: "30px",
    textAlign: "left",
    marginTop: "",
  };
  const memberOptions = ["ordinary", "patron"];
  const statusOptions = ["Active", "Deactive"];
  const membershipCategory = [
    "Small Scale Manufacturing Activity",
    "Large Scale Manufacturing Activity",
    "Small Scale Trading Activity",
    "Large Scale Trading Activity",
    "Small Scale Service Activity",
    "Large Scale Service Activity",
    "District Chambers Of Commerce",
    "Association",
    "Profession",
  ];
  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };

  const showUploadSuccess = (e) => {
    toast.current.show({
      severity: "success",
      summary: "Image Uploaded!",
      detail: "Image of representative is updated!",
      life: 5000,
    });
  };

  const showError = () => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: "There is an Error, to update image. Please Try Again",
      life: 3000,
    });
  };

  const renderUploadFooter = (name) => {
    return (
      <div>
        <Button
          label="No"
          icon="pi pi-times"
          onClick={() => {
            onHide(name);
          }}
          className="p-button-text"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          onClick={() => {
            const fd = new FormData();
            fd.append("representative1", representative1Image);
            fd.append("representative2", representative2Image);
            fd.append("representative3", representative3Image);

            uploadMembersImages(data.id, fd).then((res) => {
              console.log(res);
              if (Array.isArray(res)) {
                showUploadSuccess();

                setUpdate(!update);

                onHide(name);
              }
            });
          }}
          autoFocus
        />
      </div>
    );
  };
  const renderUploadFooter2 = (name) => {
    return (
      <div>
        <Button
          label="No"
          icon="pi pi-times"
          onClick={() => {
            onHide(name);
          }}
          className="p-button-text"
        />
        <Button
          label="Update"
          icon="pi pi-check"
          onClick={() => {
            if (rfid1.length === 10) {
              let val = {};
              if (index === 1) {
                val = { rfid: rfid1 };
              }
              if (index === 2) {
                val = { rfid2: rfid1 };
              }
              if (index === 3) {
                val = { rfid3: rfid1 };
              }
              updateMember(val, data?.id)
                .then((res) => {
                  console.log("res", res);
                  toast.current.show({
                    severity: "success",
                    summary: "RFID Updated!",
                    life: 5000,
                  });
                })
                .catch((err) => {
                  toast.current.show({
                    severity: "error",
                    summary: "Error",
                    detail: "There is an Error ",
                    life: 3000,
                  });
                });
            } else {
            }
            onHide(name);
          }}
          autoFocus
        />
      </div>
    );
  };
  const renderUploadFooter3 = (name) => {
    return <div></div>;
  };

  // console.log("data23", data);
  const formik = useFormik({
    initialValues: {
      email: "",
      name_of_org: "",
      address: "",
      phone_number: "",
      fax: "",
      mobile: "",
      website: "",
      year_of_estd: "",
      nature_of_business: "",
      member_category: "",
      membership_category: "",
      annual_turnover: "",
      product_desc: "",
      GSTIN: "",
      PAN: "",
      name_of_representative1: "",
      name_of_representative2: "",
      name_of_representative3: "",
      designation_of_representative1: "",
      designation_of_representative2: "",
      designation_of_representative3: "",
      MEM_id_no: "",
      amount_collected: "",
      // new added fileds
      proposed_by: "",
      seconded_by: "",
      address2: "",
      address3: "",
      city: "",
      pincode: "",
      state: "",
      cin_no: "",
      date_of_admission: "",
      date_of_estd: "",
      mobile_of_repr2: "",
      mobile_of_repr3: "",
      introducer_name: "",
      introducer_mobile_no: "",
      referred_by: "",
      is_active: "",
      remarks: "",
      documents_submitted: "",
      // rfid: "",
      // rfid2: "",
      // rfid3: "",
    },
    onSubmit: (Data) => {
      console.log("update click");
      const id = data?.id;
      const registerData = {};
      console.log(
        "filtereddata",
        Object.keys(Data)
          .filter(
            (item) =>
              Data[item] !== null &&
              Data[item] !== undefined &&
              Data[item].length !== 0 &&
              Data[item] !== data[item]
          )
          .map((key) => (registerData[key] = Data[key]))
      );
      console.log("register Data", registerData);
      const {
        email,
        MEM_id_no,
        date_of_admission,
        date_of_estd,
        is_active,
        documents_submitted,
        phone_number,
        member_category,
        membership_category,
        ...extraData
      } = registerData;
      let newData = { ...extraData };
      if (formik.values.email !== Email) {
        newData = { ...newData, email: formik.values.email };
      }
      if (formik.values.MEM_id_no !== memberId) {
        newData = { ...newData, MEM_id_no: formik.values.MEM_id_no };
      }
      if (
        moment(formik.values.date_of_admission).format("DD-MM-YYYY") !==
        moment(data.date_of_admission).format("DD-MM-YYYY")
      ) {
        newData = {
          ...newData,
          date_of_admission: formik.values.date_of_admission,
        };
      }
      if (
        moment(formik.values.date_of_estd).format("DD-MM-YYYY") !==
        moment(data.date_of_estd).format("DD-MM-YYYY")
      ) {
        newData = { ...newData, date_of_estd: formik.values.date_of_estd };
      }
      if (formik.values.is_active !== data?.user?.is_active) {
        newData = { ...newData, is_active: formik.values.is_active };
      }
      if (formik.values.documents_submitted !== data?.documents_submitted) {
        newData = {
          ...newData,
          documents_submitted: formik.values.documents_submitted,
        };
      }
      if (formik.values.phone_number !== data?.user?.phone_number) {
        newData = { ...newData, phone_number: formik.values.phone_number };
      }
      console.log("extra data", newData);

      if (Object.keys(newData).length !== 0) {
        updateMember(newData, id)
          .then((res) => {
            console.log("res", res);
            if (res.status === 200) {
              toast.current.show({
                severity: "success",
                summary: "Membership Form Submitted",
                detail: "The Membership form has been submitted for update",
                life: 5000,
              });
              setUpdate(!update);
            } else {
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: "There is an Error to update user",
                life: 3000,
              });
            }
          })
          .catch((err) => {
            console.log("error", err);
            if (err.response.status === 401) {
              window.location.replace("/");
            } else {
              console.log("err", err?.response?.status);
              if (err?.response?.data?.err?.data) {
                toast.current.show({
                  severity: "error",
                  summary: "Error",
                  detail: err?.response?.data?.err?.data,
                  life: 3000,
                });
              } else {
                console.log("error", err);
                toast.current.show({
                  severity: "error",
                  summary: "Error",
                  detail: "There is an Error to update user",
                  err,
                  life: 3000,
                });
              }
            }
          })
          .finally((res) => {
            formik.handleReset();
          });
      }
    },
  });
  const setData = () => {
    console.log("called2", data);
    const userData = data;
    // setUserEmail(userData?.user?.email);
    setEmail(userData?.user?.email);
    setMemberId(userData?.MEM_id_no);
    userData?.media?.map((item) => {
      if (item?.media_desc === "REPRESENTATIVE2") {
        setRepresentative2Image(item?.link);
        setId2(item?.id);
      }
      if (item?.media_desc === "REPRESENTATIVE3") {
        setRepresentative3Image(item?.link);
        setId3(item?.id);
      }
      if (item?.media_desc === "REPRESENTATIVE1") {
        setRepresentative1Image(item?.link);
        setId1(item?.id);
      }
    });
    formik.values.phone_number = userData?.user?.phone_number;
    formik.values.email = userData?.user?.email;

    formik.values.name_of_org = userData?.name_of_org;
    formik.values.address = userData?.address;
    formik.values.fax = userData?.fax;
    formik.values.mobile = userData?.mobile;
    formik.values.website = userData?.website;
    formik.values.year_of_estd = userData?.year_of_estd;
    formik.values.nature_of_business = userData?.nature_of_business;
    formik.values.member_category = userData?.member_category;
    formik.values.membership_category = userData?.membership_category;
    formik.values.annual_turnover = userData?.annual_turnover;
    formik.values.product_desc = userData?.product_desc;
    formik.values.GSTIN = userData?.GSTIN;
    formik.values.PAN = userData?.PAN;
    formik.values.name_of_representative1 = userData?.name_of_representative1;
    formik.values.name_of_representative2 = userData?.name_of_representative2;
    formik.values.name_of_representative3 = userData?.name_of_representative3;
    formik.values.designation_of_representative1 =
      userData?.designation_of_representative1;
    formik.values.designation_of_representative2 =
      userData?.designation_of_representative2;
    formik.values.designation_of_representative3 =
      userData?.designation_of_representative3;
    formik.values.MEM_id_no = userData?.MEM_id_no;
    formik.values.amount_collected = userData?.amount_collected;
    formik.values.proposed_by = userData?.proposed_by;
    formik.values.seconded_by = userData?.seconded_by;
    formik.values.address2 = userData?.address2;
    formik.values.address3 = userData?.address3;
    formik.values.city = userData?.city;
    formik.values.pincode = userData?.pincode;
    formik.values.state = userData?.state;
    formik.values.services = userData?.services;
    formik.values.cin_no = userData?.cin_no;
    formik.values.date_of_admission =
      userData?.date_of_admission !== null
        ? new Date(userData?.date_of_admission)
        : "";
    formik.values.date_of_estd =
      userData?.date_of_estd !== null ? new Date(userData?.date_of_estd) : "";
    formik.values.mobile_of_repr2 = userData?.mobile_of_repr2;
    formik.values.mobile_of_repr3 = userData?.mobile_of_repr3;
    formik.values.introducer_name = userData?.introducer_name;
    formik.values.referred_by = userData?.referred_by;
    formik.values.remarks = userData?.remarks;
    formik.values.is_active = userData.user?.is_active;
    formik.values.documents_submitted = userData?.documents_submitted;
    formik.values.introducer_mobile_no = userData?.introducer_mobile_no;
  };
  const uploadImages = async (Data, name) => {
    console.log("call", name, Data);
    const fd = new FormData();
    fd.append(name, Data);
    uploadMembersImages(data.id, fd)
      .then((res) => {
        console.log("res", res);
        showUploadSuccess();
        // navigate('')
        setUpdate(!update);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          // window.location.replace("/");
        } else {
          showError();
        }
      });
  };
  const deleteImages = async (id) => {
    console.log("id", id);
    deleteMembersImages(id)
      .then((res) => {
        console.log("res", res);
        toast.current.show({
          severity: "success",
          summary: "Image deleted!",
          detail: "Image of representative is deleted!",
          life: 5000,
        });
        // navigate('')
        setRepresentative1Image(null);
        setRepresentative2Image(null);
        setRepresentative3Image(null);
        setId1(null);
        setId2(null);
        setId3(null);
        setUpdate((prev) => !prev);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          // window.location.replace("/");
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "There is an Error, to delete image. Please Try Again",
            life: 3000,
          });
        }
      });
  };
  const triggerFileSelectPopup = (e) => {
    e.preventDefault();
    ref1.current.click();
  };
  const triggerFileSelectPopup2 = (e) => {
    e.preventDefault();
    ref2.current.click();
  };
  const triggerFileSelectPopup3 = (e) => {
    e.preventDefault();
    ref3.current.click();
  };
  useEffect(() => {
    console.log("called", data);
    setData();
  }, [data]);
  return (
    <div>
      <Toast ref={toast}></Toast>
      <div className="form">
        <form
          className="card-container text-left"
          onSubmit={formik.handleSubmit}
        >
          {/* {" "}
          <div
            className="text-2xl m-1 font-bold text-center"
            style={{ color: "#1c7ed6" }}
          >
            {data?.name_of_org}
          </div> */}
          <div className="flex flex-row justify-content-evenly align-items-start p-4">
            <div className="w-4 text-right p-1">
              <div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    ID Number :
                  </div>
                  <InputText
                    style={styles}
                    id="MEM_id_no"
                    value={formik.values.MEM_id_no}
                    onChange={formik.handleChange}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Date of Admission:
                  </div>
                  <Calendar
                    id="date_of_admission"
                    style={styles}
                    value={formik.values.date_of_admission}
                    onChange={formik.handleChange}
                    dateFormat="dd-mm-yy"
                  />
                </div>
              </div>
            </div>
            <div className="w-4 text-right p-1">
              <div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Member Category :
                  </div>
                  <Dropdown
                    style={styles}
                    id="member_category"
                    value={formik.values.member_category}
                    onChange={formik.handleChange}
                    options={memberOptions}
                    disabled
                  ></Dropdown>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Date of Establishment:
                  </div>
                  <Calendar
                    id="date_of_estd"
                    style={styles}
                    value={formik.values.date_of_estd}
                    onChange={formik.handleChange}
                    dateFormat="dd-mm-yy"
                  />
                </div>
              </div>
            </div>
            <div className="w-4 text-right p-1">
              <div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Membership Category :
                  </div>
                  <Dropdown
                    style={styles}
                    id="membership_category"
                    value={formik.values.membership_category}
                    onChange={formik.handleChange}
                    options={membershipCategory}
                    disabled
                  ></Dropdown>
                </div>
              </div>
            </div>
          </div>
          <div
            className="flex flex-row justify-content-start align-items-center px-4"
            style={{ marginBottom: "-7px" }}
          >
            <div className=" flex text-right p-1">
              <div
                className="text-l align-items-center textInput"
                style={{ width: "235px" }}
              >
                Company Name :
              </div>
              <InputText
                style={{
                  width: "725px",
                  border: "1px solid #000000",
                  borderRadius: "0px",
                  marginLeft: "2px",
                  textAlign: "left",
                  marginTop: "2px",
                }}
                id="name_of_org"
                value={formik.values.name_of_org}
                onChange={formik.handleChange}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    formik.setFieldValue("name_of_org", " ");
                  }
                }}
              ></InputText>
            </div>
          </div>
          <div className="flex flex-row justify-content-evenly align-items-start px-4">
            <div className="w-4 text-right p-1">
              <div>
                <div className="flex" style={{ marginTop: "0px" }}>
                  <div className="text-l align-items-center textInput">
                    Address :
                  </div>
                  <InputText
                    style={styles}
                    id="address"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        formik.setFieldValue("address", " ");
                      }
                    }}
                  ></InputText>
                </div>
                <div className="flex" style={{ marginTop: "0px" }}>
                  <div className="text-l align-items-center textInput">
                    Place :
                  </div>
                  <InputText
                    style={styles}
                    id="city"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        formik.setFieldValue("city", " ");
                      }
                    }}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Pincode :
                  </div>
                  <InputText
                    style={styles}
                    id="pincode"
                    value={formik.values.pincode}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        formik.setFieldValue("pincode", " ");
                      }
                    }}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    State :
                  </div>
                  <InputText
                    style={styles}
                    id="state"
                    value={formik.values.state}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        formik.setFieldValue("state", " ");
                      }
                    }}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Telephone No. :
                  </div>
                  <InputText
                    style={styles}
                    id="phone_number"
                    value={formik.values.phone_number}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        formik.setFieldValue("phone_number", " ");
                      }
                    }}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Email ID :
                  </div>
                  <InputText
                    style={styles}
                    id="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Website :
                  </div>
                  <InputText
                    style={styles}
                    id="website"
                    value={formik.values.website}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        formik.setFieldValue("website", " ");
                      }
                    }}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Fax :
                  </div>
                  <InputText
                    style={styles}
                    id="fax"
                    value={formik.values.fax}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        formik.setFieldValue("fax", " ");
                      }
                    }}
                  ></InputText>
                </div>
                <div className="flex" style={{ marginTop: "150px" }}>
                  <div className="text-l align-items-center textInput">
                    GST :
                  </div>
                  <InputText
                    style={styles}
                    id="GSTIN"
                    value={formik.values.GSTIN}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        formik.setFieldValue("GSTIN", " ");
                      }
                    }}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    PAN :
                  </div>
                  <InputText
                    style={styles}
                    id="PAN"
                    value={formik.values.PAN}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        formik.setFieldValue("PAN", " ");
                      }
                    }}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    CIN No. :
                  </div>
                  <InputText
                    style={styles}
                    id="cin_no"
                    value={formik.values.cin_no}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        formik.setFieldValue("cin_no", " ");
                      }
                    }}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Product Description :
                  </div>
                  <InputText
                    style={styles}
                    id="product_desc"
                    value={formik.values.product_desc}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        formik.setFieldValue("product_desc", " ");
                      }
                    }}
                  ></InputText>
                </div>
              </div>
            </div>
            <div className="w-4 text-right p-1">
              <div>
                <div className="flex" style={{ marginTop: "0px" }}>
                  <div className="text-l align-items-center textInput">
                    Representative 1 Name:
                  </div>
                  <InputText
                    style={styles}
                    id="name_of_representative1"
                    value={formik.values.name_of_representative1}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        formik.setFieldValue("name_of_representative1", " ");
                      }
                    }}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Designation :
                  </div>
                  <InputText
                    style={styles}
                    id="designation_of_representative1"
                    value={formik.values.designation_of_representative1}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        formik.setFieldValue(
                          "designation_of_representative1",
                          " "
                        );
                      }
                    }}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Mobile No. :
                  </div>
                  <InputText
                    style={styles}
                    id="mobile"
                    value={formik.values.mobile}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        formik.setFieldValue("mobile", " ");
                      }
                    }}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Representative 2 Name:
                  </div>
                  <InputText
                    style={styles}
                    id="name_of_representative2"
                    value={formik.values.name_of_representative2}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        formik.setFieldValue("name_of_representative2", " ");
                      }
                    }}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Designation :
                  </div>
                  <InputText
                    style={styles}
                    id="designation_of_representative2"
                    value={formik.values.designation_of_representative2}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        formik.setFieldValue(
                          "designation_of_representative2",
                          " "
                        );
                      }
                    }}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Mobile No. :
                  </div>
                  <InputText
                    style={styles}
                    id="mobile_of_repr2"
                    value={formik.values.mobile_of_repr2}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        formik.setFieldValue("mobile_of_repr2", " ");
                      }
                    }}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Representative 3 Name:
                  </div>
                  <InputText
                    style={styles}
                    id="name_of_representative3"
                    value={formik.values.name_of_representative3}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        formik.setFieldValue("name_of_representative3", " ");
                      }
                    }}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Designation :
                  </div>
                  <InputText
                    style={styles}
                    id="designation_of_representative3"
                    value={formik.values.designation_of_representative3}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        formik.setFieldValue(
                          "designation_of_representative3",
                          " "
                        );
                      }
                    }}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Mobile No. :
                  </div>
                  <InputText
                    style={styles}
                    id="mobile_of_repr3"
                    value={formik.values.mobile_of_repr3}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        formik.setFieldValue("mobile_of_repr3", " ");
                      }
                    }}
                  ></InputText>
                </div>
                <div className="flex" style={{ marginTop: "50px" }}>
                  <div className="text-l align-items-center textInput">
                    Annual Turnover :
                  </div>
                  <InputText
                    style={styles}
                    id="annual_turnover"
                    value={formik.values.annual_turnover}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        formik.setFieldValue("annual_turnover", " ");
                      }
                    }}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Proposed By :
                  </div>
                  <InputText
                    style={styles}
                    id="proposed_by"
                    value={formik.values.proposed_by}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        formik.setFieldValue("proposed_by", " ");
                      }
                    }}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Seconded By :
                  </div>
                  <InputText
                    style={styles}
                    id="seconded_by"
                    value={formik.values.seconded_by}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        formik.setFieldValue("seconded_by", " ");
                      }
                    }}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Referred By :
                  </div>
                  <InputText
                    style={styles}
                    id="referred_by"
                    value={formik.values.referred_by}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        formik.setFieldValue("referred_by", " ");
                      }
                    }}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Amount Collected :
                  </div>
                  <InputText
                    style={styles}
                    id="amount_collected"
                    value={formik.values.amount_collected}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        formik.setFieldValue("amount_collected", 0);
                      }
                    }}
                  ></InputText>
                </div>
              </div>
            </div>
            <div className="w-4 text-right p-1">
              <div>
                <div
                  className="flex align-items-center"
                  style={{ marginTop: "5px" }}
                >
                  {data.length !== 0 && data !== null ? (
                    <img
                      src={
                        representative1Image ??
                        "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=826&t=st=1661248188~exp=1661248788~hmac=64bb7d23be8dcef15ecfef304e7d5bfe293f29bbf5b48fd6cadb282e205f9ddb"
                      }
                      alt="user"
                      height={"138px"}
                      width={"120px"}
                    />
                  ) : null}
                  <input
                    ref={ref1}
                    style={{
                      width: "200px",
                      border: "1px solid #000000",
                      borderRadius: "0px",
                      textAlign: "left",
                      marginTop: "2px",
                      marginLeft: "20px",
                      display: "none",
                    }}
                    id="img1"
                    type={"file"}
                    onChange={(e) => {
                      // setRepresentative1Image(e.target.files);
                      uploadImages(e.target.files[0], "representative1");
                      e.target.value = null;
                    }}
                  />
                  <div className="flex flex-column gap-6">
                    <i
                      className="pi pi-upload"
                      style={{ fontSize: "1.5rem", marginLeft: "30px" }}
                      onClick={triggerFileSelectPopup}
                    ></i>
                    <i
                      className="pi pi-trash"
                      style={{
                        fontSize: "1.5rem",
                        marginLeft: "30px",
                        color: id1 !== null ? "#000" : "grey",
                      }}
                      onClick={(e) => {
                        if (id1 !== null) {
                          deleteImages(id1);
                          // .then(() => {
                          //   setRepresentative1Image(null);
                          //   setId1(null);
                          // })
                          // .catch((err) => {
                          //   console.log(err);
                          // });
                        }
                      }}
                    ></i>
                  </div>
                  {/* <div className="flex flex-wrap justify-content-center align-items-center gap-4"> */}
                  <div
                    className="flex align-items-center"
                    style={{ width: "200px" }}
                  >
                    <InputText
                      style={styles2}
                      // id="rfid"
                      placeholder="RFID"
                      value={rfid}
                      onChange={(e) => setRfid1(e.target.value)}
                      // onBlur={(e) => {
                      //   if (e.target.value === "") {
                      //     formik.setFieldValue("rfid");
                      //   }
                      // }}
                    />
                    <i
                      className="pi pi-check "
                      style={{
                        fontSize: "1.5rem",
                        marginLeft: "",
                        backgroundColor: "#3B82F6",
                        color: "white",
                        padding: "11px",
                      }}
                      onClick={() => {
                        if (rfid1.length > 0) {
                          let val = { rfid: rfid1 };
                          console.log(val, "nulll");
                          updateMember(val, data?.id)
                            .then((res) => {
                              console.log("res", res);
                              toast.current.show({
                                severity: "success",
                                summary: "RFID Updated!",
                                life: 5000,
                              });
                            })
                            .catch((err) => {
                              console.log(
                                err.response.data.err,
                                "errorsssssss"
                              );
                              toast.current.show({
                                severity: "error",
                                summary: "Error",
                                detail: err.response.data.err,
                                life: 3000,
                              });
                            });
                        } else {
                          toast.current.show({
                            severity: "error",
                            summary: "Error",
                            detail: "RFID-1 could not be empty",
                            life: 3000,
                          });
                        }
                      }}
                    ></i>
                  </div>
                  {/* </div> */}
                </div>
                <div
                  className="flex align-items-center"
                  style={{ marginTop: "10px" }}
                >
                  {data.length !== 0 && data !== null ? (
                    <img
                      src={
                        representative2Image ??
                        "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=826&t=st=1661248188~exp=1661248788~hmac=64bb7d23be8dcef15ecfef304e7d5bfe293f29bbf5b48fd6cadb282e205f9ddb"
                      }
                      alt="user"
                      height={"138px"}
                      width={"120px"}
                    />
                  ) : null}
                  <input
                    ref={ref2}
                    style={{
                      width: "200px",
                      border: "1px solid #000000",
                      borderRadius: "0px",
                      textAlign: "left",
                      marginTop: "2px",
                      marginLeft: "20px",
                      display: "none",
                    }}
                    id="img2"
                    type={"file"}
                    onChange={(e) => {
                      console.log("event", e.target);
                      uploadImages(e.target.files[0], "representative2");
                      e.target.value = null;
                    }}
                  />
                  <div className="flex flex-column gap-6">
                    <i
                      className="pi pi-upload"
                      style={{
                        fontSize: "1.5rem",
                        marginLeft: "30px",
                      }}
                      onClick={triggerFileSelectPopup2}
                    ></i>
                    <i
                      className="pi pi-trash"
                      style={{
                        fontSize: "1.5rem",
                        marginLeft: "30px",
                        color: id2 !== null ? "#000" : "grey",
                      }}
                      onClick={(e) => {
                        if (id2 !== null) {
                          deleteImages(id2);
                          // .then(() => {
                          //   setRepresentative2Image(null);
                          //   setId2(null);
                          // })
                          // .catch((err) => {
                          //   console.log(err);
                          // });
                        }
                      }}
                    ></i>
                  </div>
                  {/* <div className="flex flex-wrap justify-content-center align-items-center gap-4"> */}
                  <div
                    className="flex align-items-center"
                    style={{ width: "200px" }}
                  >
                    <InputText
                      style={styles2}
                      // id="rfid2"
                      placeholder="RFID 2"
                      value={rfid2}
                      onChange={(e) => setRfid2(e.target.value)}
                      onBlur={(e) => {
                        if (e.target.value === "") {
                          formik.setFieldValue("rfid2");
                        }
                      }}
                    ></InputText>
                    <i
                      className="pi pi-check "
                      style={{
                        fontSize: "1.5rem",
                        marginLeft: "",
                        backgroundColor: "#3B82F6",
                        color: "white",
                        padding: "11px",
                      }}
                      onClick={() => {
                        if (rfid2.length > 0) {
                          let val = { rfid2: rfid2 };
                          console.log(val, "nulll");
                          updateMember(val, data?.id)
                            .then((res) => {
                              console.log("res", res);
                              toast.current.show({
                                severity: "success",
                                summary: "RFID 2 Updated!",
                                life: 5000,
                              });
                            })
                            .catch((err) => {
                              console.log(
                                err.response.data.err,
                                "errorsssssss"
                              );
                              toast.current.show({
                                severity: "error",
                                summary: "Error",
                                detail: err.response.data.err,
                                life: 3000,
                              });
                            });
                        } else {
                          toast.current.show({
                            severity: "error",
                            summary: "Error",
                            detail: "RFID-2 could not be empty",
                            life: 3000,
                          });
                        }
                      }}
                    ></i>
                  </div>
                  {/* </div> */}
                </div>
                <div
                  className="flex align-items-center"
                  style={{ marginTop: "10px" }}
                >
                  {data.length !== 0 && data !== null ? (
                    <img
                      src={
                        representative3Image ??
                        "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=826&t=st=1661248188~exp=1661248788~hmac=64bb7d23be8dcef15ecfef304e7d5bfe293f29bbf5b48fd6cadb282e205f9ddb"
                      }
                      alt="user"
                      height={"138px"}
                      width={"120px"}
                    />
                  ) : null}
                  <input
                    ref={ref3}
                    style={{
                      width: "200px",
                      border: "1px solid #000000",
                      borderRadius: "0px",
                      textAlign: "left",
                      marginTop: "2px",
                      marginLeft: "20px",
                      display: "none",
                    }}
                    id="img2"
                    type={"file"}
                    onChange={(e) => {
                      uploadImages(e.target.files[0], "representative3");
                      e.target.value = null;
                    }}
                  />
                  <div className="flex flex-column gap-6">
                    <i
                      className="pi pi-upload"
                      style={{
                        fontSize: "1.5rem",
                        marginLeft: "30px",
                      }}
                      onClick={triggerFileSelectPopup3}
                    ></i>
                    <i
                      className="pi pi-trash"
                      style={{
                        fontSize: "1.5rem",
                        marginLeft: "30px",
                        color: id3 !== null ? "#000" : "grey",
                      }}
                      onClick={(e) => {
                        if (id3 !== null) {
                          deleteImages(id3);
                          // .then(() => {
                          //   setRepresentative3Image(null);
                          //   setId3(null);
                          // })
                          // .catch((err) => {
                          //   console.log(err);
                          // });
                        }
                      }}
                    ></i>
                  </div>
                  {/* <div className="flex flex-wrap justify-content-center align-items-center gap-4"> */}
                  <div
                    className="flex align-items-center"
                    style={{ width: "200px" }}
                  >
                    <InputText
                      style={styles2}
                      // id="rfid3"
                      placeholder="RFID 3"
                      value={rfid3}
                      onChange={(e) => setRfid3(e.target.value)}
                      onBlur={(e) => {
                        if (e.target.value === "") {
                          formik.setFieldValue("rfid3");
                        }
                      }}
                    ></InputText>
                    <i
                      className="pi pi-check "
                      style={{
                        fontSize: "1.5rem",
                        marginLeft: "",
                        backgroundColor: "#3B82F6",
                        color: "white",
                        padding: "11px",
                      }}
                      onClick={() => {
                        if (rfid3.length > 0) {
                          let val = { rfid3: rfid3 };
                          console.log(val, "nulll");
                          updateMember(val, data?.id)
                            .then((res) => {
                              console.log("res", res);
                              toast.current.show({
                                severity: "success",
                                summary: "RFID Updated!",
                                life: 5000,
                              });
                            })
                            .catch((err) => {
                              console.log(
                                err.response.data.err,
                                "errorsssssss"
                              );
                              toast.current.show({
                                severity: "error",
                                summary: "Error",
                                detail: err.response.data.err,
                                life: 3000,
                              });
                            });
                        } else {
                          toast.current.show({
                            severity: "error",
                            summary: "Error",
                            detail: "RFID-3 could not be empty",
                            life: 3000,
                          });
                        }
                      }}
                    ></i>
                  </div>
                  {/* </div> */}
                </div>
                <div className="flex" style={{ marginTop: "54px" }}>
                  <div className="text-l align-items-center textInput">
                    Introducer Name :
                  </div>
                  <InputText
                    style={styles}
                    id="introducer_name"
                    value={formik.values.introducer_name}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        formik.setFieldValue("introducer_name", " ");
                      }
                    }}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Mobile No. of Introducer :
                  </div>
                  <InputText
                    style={styles}
                    id="introducer_mobile_no"
                    value={formik.values.introducer_mobile_no}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        formik.setFieldValue("introducer_mobile_no", " ");
                      }
                    }}
                  ></InputText>
                </div>
                <div className="flex">
                  <div
                    className="text-l align-items-center textInput"
                    style={{ height: "45px", margin: "2px 0px" }}
                  >
                    Status :
                  </div>
                  <div
                    style={{
                      width: "300px",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0px 10px",
                      border: "1px solid #000000",
                      borderRadius: "0px",
                      marginLeft: "2px",
                      textAlign: "left",
                      marginTop: "2px",
                      height: "45px",
                    }}
                  >
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId="1"
                        name="Active"
                        value={true}
                        onChange={(e) => {
                          formik.values.is_active = true;
                          console.log(formik.values.is_active);
                        }}
                        checked={formik.values.is_active === true}
                      />
                      <label htmlFor="1" className="ml-2">
                        Active
                      </label>
                    </div>
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId="2"
                        name="Deactive"
                        value={false}
                        onChange={(e) => {
                          formik.values.is_active = false;
                          console.log(formik.values.is_active);
                        }}
                        checked={formik.values.is_active === false}
                      />
                      <label htmlFor="2" className="ml-2">
                        Deactive
                      </label>
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <div
                    className="text-l align-items-center textInput"
                    style={{ height: "45px", margin: "2px 0px" }}
                  >
                    Documents Submitted :
                  </div>
                  <div
                    style={{
                      width: "300px",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0px 10px",
                      border: "1px solid #000000",
                      borderRadius: "0px",
                      marginLeft: "2px",
                      textAlign: "left",
                      marginTop: "2px",
                      height: "45px",
                    }}
                  >
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId="3"
                        // name="Yes"
                        value={true}
                        onChange={(e) => {
                          formik.values.documents_submitted = true;
                        }}
                        checked={formik.values.documents_submitted}
                      />
                      <label htmlFor="3" className="ml-2">
                        Yes
                      </label>
                    </div>
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId="4"
                        // name="No"
                        value={false}
                        onChange={(e) => {
                          formik.values.documents_submitted = false;
                        }}
                        checked={!formik.values.documents_submitted}
                      />
                      <label htmlFor="4" className="ml-2">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Remark :
                  </div>
                  <InputText
                    style={styles}
                    id="remarks"
                    value={formik.values.remarks}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        formik.setFieldValue("remarks", " ");
                      }
                    }}
                  ></InputText>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              textAlign: "center",
              marginBottom: "100px",
              marginTop: "30px",
            }}
          >
            <Button
              type="submit"
              // onClick={(e) => {
              //   console.log("update");
              // }}
              label="Save Changes"
            ></Button>
          </div>
        </form>
      </div>

      <div>
        <Dialog
          header="Update RFID Number"
          visible={displayBasic3}
          breakpoints={{ "960px": "75vw" }}
          style={{ width: "30vw" }}
          footer={renderUploadFooter2("displayBasic3")}
          onHide={() => onHide("displayBasic3")}
        >
          <InputText
            value={rfid1}
            onChange={(e) => {
              setRfid1(e.target.value);
            }}
            style={{ width: "8rem", height: "2rem" }}
            maxLength
            type="number"
          />
          {rfid1.length > 0 && rfid1.length < 10 && (
            <p
              style={{ color: "#FF0000", fontSize: "13px", marginTop: "10px" }}
            >
              RFID should be in 10 digits
            </p>
          )}
        </Dialog>
        <Dialog
          header="Member Details"
          visible={displayBasic4}
          breakpoints={{ "960px": "75vw" }}
          style={{ width: "30vw" }}
          footer={renderUploadFooter3("displayBasic4")}
          onHide={() => onHide("displayBasic4")}
        >
          <div className="m-1 flex-wrap">
            Year of Establishment : &nbsp;{" "}
            <strong>
              {" "}
              {data.year_of_estd ? data.year_of_estd : "No Data"}
            </strong>
          </div>

          <div className="m-1 flex-wrap">
            Annual TurnOver : &nbsp;
            <strong>
              {" "}
              {data.annual_turnover ? data.annual_turnover : "Not Available"}
            </strong>
          </div>
          <div className="m-1 flex-wrap">
            Authorized Capital : &nbsp;{" "}
            <strong>
              {" "}
              {data.authorized_capital
                ? data.authorized_capital
                : "Not Available"}
            </strong>
          </div>
          <div className="m-1 flex-wrap">
            Paid-up Capital : &nbsp;
            <strong>
              {data.paid_up_capital ? data.paid_up_capital : "Not Available"}
            </strong>
          </div>

          <div className="m-1 flex-wrap ">
            {" "}
            GSTIN : &nbsp;
            <strong>{data.GSTIN ? data.GSTIN : "Not Available"}</strong>
          </div>
          <div className="m-1 flex-wrap ">
            SIN : &nbsp;
            <strong>{data.GSTIN ? data.GSTIN : "Not Available"}</strong>
          </div>
        </Dialog>
        {/* {scanRfid && (
          <Button
            className="w-15rem m-2"
            onClick={() => {
              var myHeaders = new Headers();
              myHeaders.append(
                "Authorization",
                `Bearer ${window.localStorage.getItem("token")}`
              );
              var requestOptions = {
                method: "POST",
                headers: myHeaders,
                redirect: "follow",
              };
              fetch(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/voting/generatecode/${rfid}`,
                requestOptions
              )
                .then((response) => response.json())
                .then((result) => {
                  console.log("res", result);
                  if (result?.err) {
                    toast.current.show({
                      severity: "error",
                      summary: "Error",
                      detail: result?.err?.data,
                      life: 3000,
                    });
                  } else {
                    onClick("displayBasic1");
                    setCode(result.data);
                    setAccessCode(result.data);
                  }
                })
                .catch((error) => console.log("error", error));
            }}
            label="Generate Code"
          ></Button>
        )} */}

        <Dialog
          header="Voting Code"
          visible={displayBasic1}
          style={{ width: "30vw" }}
          onHide={() => onHide("displayBasic1")}
        >
          <div className="flex flex-column align-items-center justify-content-center">
            <div
              style={{
                backgroundColor: "#ECCFFF",
                color: "#694382",
                textTransform: "uppercase",
                padding: ".25em 1rem",
                borderRadius: "3px",
                fontWeight: "700",
                letterSpacing: ".3px",
              }}
              className="text-xl font-bold m-3"
              ref={componentRef}
            >
              {code}
            </div>
            <ReactToPrint
              trigger={() => (
                <Button
                  className="p-button-outlined p-button-secondary w-15rem"
                  onClick={() => window.print()}
                  label="Print Code"
                ></Button>
              )}
              content={() => componentRef.current}
            />
          </div>
        </Dialog>
        <Dialog
          header="Upload Respective Images"
          visible={displayBasic2}
          breakpoints={{ "960px": "75vw" }}
          style={{ width: "30vw" }}
          footer={renderUploadFooter("displayBasic2")}
          onHide={() => onHide("displayBasic2")}
        >
          <div>
            <div className="m-2 ">
              <div className=" ">
                <div className="m-2 ">
                  <h3>Representative Image 1</h3>
                  <input
                    className="fileUpload m-2 w-12"
                    type="file"
                    onChange={(e) => setRepresentative1Image(e.target.files[0])}
                    style={{}}
                  />
                </div>
                <div className="m-2">
                  <h3>Representative Image 2</h3>
                  <input
                    className="fileUpload m-2 w-12"
                    type="file"
                    onChange={(e) => setRepresentative2Image(e.target.files[0])}
                    style={{}}
                  />
                </div>
                <div className="m-2">
                  <h3>Representative Image 3</h3>
                  <input
                    className="fileUpload m-2 w-12"
                    type="file"
                    onChange={(e) => setRepresentative3Image(e.target.files[0])}
                    style={{}}
                  />
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
}

export default MemberDescription;
