import React, { useEffect, useRef } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import MenuBar from "../../../components/MenuBar";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  contactUs,
  getUserProfile,
  updateUserProfile,
} from "../../../apis/api";
import { Toast } from "primereact/toast";

function Contact() {
  const [fname, setFname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [subError, setSubError] = useState(false);
  const [messageError, setMessageError] = useState(false);

  const navigate = useNavigate();
  const toast = useRef(null);

  const contact = async () => {
    if (fname === "") {
      setNameError(true);
    }
    if (email === "") {
      setEmailError(true);
    }
    if (subject === "") {
      setSubError(true);
    }
    if (message === "") {
      setMessageError(true);
    } else {
      const data = {
        name: fname,
        email: email,
        subject: subject,
        message: message,
      };
      contactUs(data)
        .then((res) => {
          // console.log("res", res);
          if (res?.status === 200) {
            toast.current.show({
              severity: "success",
              summary: "Message sent",
              detail: "message sent successfully!",
              life: 3000,
            });
            setTimeout(() => {
              navigate("/home");
            }, 3000);
          }
        })
        .catch((err) => {
          console.log("err", err);
          toast.current.show({
            severity: "error",
            summary: "Error occured",
            detail: "couldn't send message!",
            life: 3000,
          });
        });
    }
  };
  return (
    <div style={{ height: "100vh" }}>
      <Toast ref={toast} />
      <MenuBar />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            padding: "50px 90px",
          }}
        >
          <div style={{ textAlign: "left", alignItems: "flex-start" }}>
            Full Name
          </div>
          <InputText
            value={fname}
            onChange={(e) => setFname(e.target.value)}
            placeholder="Full Name"
            style={{ width: "17rem", marginTop: "5px" }}
          />
          {nameError ? (
            <p
              style={{
                color: "red",
                fontSize: "12px",
                marginTop: "5px",
                textAlign: "left",
              }}
            >
              Enter your full name!
            </p>
          ) : null}
          <div
            style={{
              textAlign: "left",
              alignItems: "flex-start",
              marginTop: "20px",
            }}
          >
            Email Address
          </div>
          <InputText
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email Address"
            style={{ width: "17rem", marginTop: "5px" }}
          />
          {emailError ? (
            <p
              style={{
                color: "red",
                fontSize: "12px",
                marginTop: "5px",
                textAlign: "left",
              }}
            >
              Enter your email address!
            </p>
          ) : null}
          <div
            style={{
              textAlign: "left",
              alignItems: "flex-start",
              marginTop: "20px",
            }}
          >
            Subjects
          </div>
          <InputText
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            placeholder="Subjects"
            style={{ width: "17rem", marginTop: "5px" }}
          />
          {subError ? (
            <p
              style={{
                color: "red",
                fontSize: "12px",
                marginTop: "5px",
                textAlign: "left",
              }}
            >
              Enter subject for message!
            </p>
          ) : null}
          <div
            style={{
              textAlign: "left",
              alignItems: "flex-start",
              marginTop: "20px",
            }}
          >
            Message
          </div>
          <InputText
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Message"
            style={{ width: "17rem", marginTop: "5px" }}
          />
          {messageError ? (
            <p
              style={{
                color: "red",
                fontSize: "12px",
                marginTop: "5px",
                textAlign: "left",
              }}
            >
              Enter your message!
            </p>
          ) : null}
          <Button
            onClick={() => {
              contact();
            }}
            style={{
              background: "#2A2B8F",
              borderRadius: "12px",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "30px",
              width: "17rem",
            }}
          >
            <div
              style={{
                fontSize: "16px",
                fontWeight: "600",
              }}
            >
              Send Message
            </div>
          </Button>
        </div>
        <div
          style={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            padding: "50px 90px",
          }}
        >
          <div
            style={{
              backgroundColor: "#FFF8E0",
              padding: "30px",
              borderRadius: "12px",
              width: "70%",
            }}
          >
            <div
              style={{
                textAlign: "left",
                alignItems: "flex-start",
                marginTop: "20px",
                fontSize: "20px",
                fontWeight: "600",
              }}
            >
              Contact Info
            </div>
            <div
              style={{
                height: "1px",
                backgroundColor: "#EDEFF5",
                margin: "20px 0px",
              }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <i
                style={{
                  marginRight: "10px",
                  fontSize: "14px",
                  color: "#2A2B8F",
                }}
                className="pi pi-phone"
              ></i>
              <div
                style={{
                  color: "#2A2B8F",
                  fontSize: "16px",
                  fontWeight: "400",
                }}
              >
                (406) 555-0120
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "start",
                marginBottom: "15px",
              }}
            >
              <i
                style={{
                  marginRight: "10px",
                  fontSize: "14px",
                  color: "#2A2B8F",
                  marginTop: "5px",
                }}
                className="pi pi-send"
              ></i>
              <div
                style={{
                  color: "#2A2B8F",
                  fontSize: "16px",
                  fontWeight: "400",
                  maxWidth: "50%",
                }}
              >
                Support@Adlisting.com Career@Adlisting.com
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <i
                style={{
                  marginRight: "10px",
                  fontSize: "14px",
                  color: "#2A2B8F",
                }}
                className="pi pi-map-marker"
              ></i>
              <div
                style={{
                  color: "#2A2B8F",
                  fontSize: "16px",
                  fontWeight: "400",
                }}
              >
                8502 Preston Rd. Inglewood, Maine 9838
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
