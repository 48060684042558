import React, { useRef, useState } from "react";
import MenuBar from "../../../components/MenuBar";
import AdminMenuBar from "../../admin/AdminMenuBar";
import events from "../../../assets/events.png";
import { Button } from "primereact/button";
import Footer from "../../../components/Footer";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useNavigate, useLocation } from "react-router-dom";
import { Toast } from "primereact/toast";
import moment from "moment";

import {
  confirmPayment,
  getAllEvents,
  getEventDetails,
  registerEvent,
  renewMember,
  varifyrenewMembershipation,
} from "../../../apis/api";
function MemberShipDescription() {
  const userType = window.localStorage.getItem("userType");
  const location = useLocation();
  const data = location.state;
  console.log(data);
  const toast = useRef(null);
  const [displayResponsive, setDisplayResponsive] = useState(false);
  const [position, setPosition] = useState("center");
  const dialogFuncMap = {
    displayResponsive: setDisplayResponsive,
  };
  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };

  const header = () => {
    return (
      <div>
        <div
          style={{
            textAlign: "center",
            fontSize: "30px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <i
            style={{ fontSize: "40px", marginRight: "20px" }}
            className="pi pi-user"
          ></i>
          <div>Register Now</div>
        </div>
      </div>
    );
  };
  const renewMembership = async () => {
    renewMember(location?.state?.id)
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .then((res) => {
        console.log("res", res?.data);
        if (typeof res?.data === typeof {}) {
          displayRazorpay(res?.data, res?.data?.order_id);
        } else {
          if (res?.data === "Successfully subscribed to the event") {
            toast.current.show({
              severity: "success",
              summary: "Subcribed successfully",
              detail: "Successfully subscribed to the event",
              life: 3000,
            });
          } else if (res?.data === "Already Subscribed") {
            toast.current.show({
              severity: "success",
              summary: "Already subcribed!",
              detail: "Your Subscription is active!",
              life: 3000,
            });
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const loadScript = (src) => {
    return new Promise((resovle) => {
      const script = document.createElement("script");
      script.src = src;

      script.onload = () => {
        resovle(true);
      };

      script.onerror = () => {
        resovle(false);
      };

      document.body.appendChild(script);
    });
  };

  const displayRazorpay = async (data, id) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("You are offline... Failed to load Razorpay SDK");
      return;
    }
    // console.log(finalAmount);
    const options = {
      order_id: data?.id,
      key: "rzp_test_7orho26dPSCJZE",
      currency: data?.currency,
      amount: data?.amount_due,
      name: "FKCCI",
      description: "Thanks for purchasing",

      handler: function (response) {
        const val = {
          razorpay_order_id: response?.razorpay_order_id,
          razorpay_payment_id: response?.razorpay_payment_id,
          razorpay_signature: response?.razorpay_signature,
        };
        confirmPayment(id, val)
          .then((res) => {
            console.log("res", res);
            if (res.status === 200) {
              toast.current.show({
                severity: "success",
                summary: "Payment Succefull!",
                detail: "Your membership is renewed!",
                life: 3000,
              });
            } else {
              toast.current.show({
                severity: "error",
                summary: "Payment Failed!",
                detail: "couldn't renew membership!",
                life: 3000,
              });
            }
          })
          .catch((err) => {
            console.log("res", err);
            toast.current.show({
              severity: "error",
              summary: "Payment Failed!",
              detail: "couldn't renew membership!",
              life: 3000,
            });
          });
        console.log("res", response.razorpay_payment_id, response);
      },
      prefill: {
        name: "FKCCi",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };
  return (
    <div style={{ height: "100%" }}>
      <Toast ref={toast} />
      {userType === "FKCCI Member" ? <MenuBar /> : <AdminMenuBar />}
      <div style={{ height: "220px", backgroundColor: "#767E94" }}></div>
      <div
        className="flex border-round "
        style={{
          display: "flex",
          flexDirection: "row",
          // justifyContent: "space-between",
        }}
      >
        <div className="w-8 p-3" style={{ width: "60%", marginTop: "30px" }}>
          <div className="flex justify-content-between align-items-center">
            <div style={{ textAlign: "left", width: "60%" }}>
              <div
                style={{
                  fontSize: "24px",
                  fontWeight: "600",
                  color: "#191F33",
                }}
              >
                Renew membership right now
              </div>
              <div style={{ lineHeight: "24px" }}>
                Vestibulum ac sollicitudin enim. In hac habitasse platea
                dictumst. Maecenas efficitur turpis maximus magna dignissim.
              </div>
            </div>
            <div
              style={{
                display: "flex",
                height: "56px",
                width: "266px",
                backgroundColor: "#F5F7FA",
                borderRadius: "160px",
                alignItems: "center",
                padding: "15px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  height: "40px",
                  width: "92px",
                  backgroundColor: "#00AAFF",
                  borderRadius: "100px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ color: "#FFFFFF" }}>Yearly</div>
              </div>
            </div>
          </div>
          <div
            className="flex justify-content-between align-items-center"
            style={{ marginTop: "100px" }}
          >
            <div style={{ textAlign: "left", width: "50%" }}>
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  color: "#191F33",
                }}
              >
                Get Customer Services
              </div>
              <div style={{ lineHeight: "24px" }}>
                Vestibulum ac sollicitudin enim. In hac habitasse platea
                dictumst. Maecenas efficitur turpis maximus
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <i
                    style={{ marginRight: "10px", fontSize: "14px" }}
                    className="pi pi-phone"
                  ></i>
                  <div>Call us</div>
                </div>
                <div style={{ fontWeight: "600" }}>080-2262355</div>
                <div style={{ fontWeight: "600" }}>080-408288300</div>
              </div>
              <div
                style={{
                  borderLeft: "1px solid #000000",
                  marginLeft: "20px",
                  paddingLeft: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <i
                    style={{ marginRight: "10px", fontSize: "14px" }}
                    className="pi pi-send"
                  ></i>
                  <div>Email us</div>
                </div>
                <div style={{ fontWeight: "600" }}>Membership@fkcci.in</div>
                <div style={{ fontWeight: "600" }}>Helpdesk@fkcci.in</div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "40%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            paddingTop: "20px",
            paddingLeft: "100px",
          }}
        >
          <div style={{ color: "#EBEEF7", fontWeight: "600" }}>Basic $10</div>
          <div
            style={{
              width: "380px",
              border: "1px solid #EBEEF7",
              borderRadius: "12px",
              padding: "30px",
            }}
          >
            <div style={{ fontSize: "24px", fontWeight: "600" }}>
              FKCCI Membership
            </div>
            {/* <div
              style={{
                fontSize: "14pz",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
              posuere.
            </div> */}
            <div
              style={{ fontSize: "56px", fontWeight: "700", color: "#2A2B8F" }}
            >
              Rs 3000/-
            </div>
            <div>/Year</div>
            <Button
              className="w-12"
              onClick={() => renewMembership()}
              style={{
                background: "#E8F7FF",
                border: "1px solid #E8F7FF",
                borderRadius: "12px",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <div
                style={{
                  color: "#2A2B8F",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                Renew Now
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MemberShipDescription;
