import React, { useRef, useState } from "react";
import logo from "../../assets/FKCCI.png";
import { useNavigate, useLocation } from "react-router-dom";
import { Menubar } from "primereact/menubar";
import { changePassword, logout } from "../../apis/api";
import { Tag } from "primereact/tag";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
function AdminMenuBar() {
  const userType = window.localStorage.getItem("userType");
  const [displayBasic, setDisplayBasic] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [position, setPosition] = useState("center");
  const [value, setValue] = useState("");
  const toast = useRef(null);
  const navigate = useNavigate();
  const items = [
    {
      label: "User Management",
      icon: "pi pi-fw pi-users",
      template: (item, options) => {
        return (
          <div
            onClick={() => navigate("/userManagement/allMembers")}
            className="text-white m-3 text-l"
            style={{ cursor: "pointer", letterSpacing: "1px" }}
          >
            <span>{item.label}</span>
          </div>
        );
      },
    },

    {
      label: "Events",
      icon: "pi pi-fw pi-calendar",
      template: (item, options) => {
        return (
          <div
            onClick={() => navigate("/events/allEvents")}
            className="text-white m-3 text-l"
            style={{ cursor: "pointer", letterSpacing: "1px" }}
          >
            <span>{item.label}</span>
          </div>
        );
      },
    },
    {
      label: "Journals",
      icon: "pi pi-fw pi-calendar",
      template: (item, options) => {
        return (
          <div
            onClick={() => navigate("/journals/allJournals")}
            className="text-white m-3 text-l"
            style={{ cursor: "pointer", letterSpacing: "1px" }}
          >
            <span>{item.label}</span>
          </div>
        );
      },
    },
    {
      label: "Logout",
      icon: "pi pi-fw pi-power-off",
      template: (item, options) => {
        return (
          <div
            onClick={() => {
              logout().then((res) => {
                console.log(res);

                if (res.status === 200) {
                  window.localStorage.clear();
                  navigate("/");
                }
              });
            }}
            className="text-white m-3 text-l"
            style={{ cursor: "pointer", letterSpacing: "1px" }}
          >
            <span>{item.label}</span>
          </div>
        );
      },
    },
  ];
  const items2 = [
    {
      label: "User Management",
      icon: "pi pi-fw pi-users",
      template: (item, options) => {
        return (
          <div
            onClick={() => navigate("/userManagement/receipt")}
            className="text-white m-3 text-l"
            style={{ cursor: "pointer", letterSpacing: "1px" }}
          >
            <span>{item.label}</span>
          </div>
        );
      },
    },
    {
      label: "Logout",
      icon: "pi pi-fw pi-power-off",
      template: (item, options) => {
        return (
          <div
            onClick={() => {
              navigate("/");
              window.localStorage.clear();
              logout().then((res) => {
                console.log(res);

                if (res.status === 200) {
                  navigate("/");
                }
              });
            }}
            className="text-white m-3 text-l"
            style={{ cursor: "pointer", letterSpacing: "1px" }}
          >
            <span>{item.label}</span>
          </div>
        );
      },
    },
  ];
  const items3 = [
    {
      label: "Events",
      icon: "pi pi-fw pi-calendar",
      template: (item, options) => {
        return (
          <div
            onClick={() => navigate("/events/allEvents")}
            className="text-white m-3 text-l"
            style={{ cursor: "pointer", letterSpacing: "1px" }}
          >
            <span>{item.label}</span>
          </div>
        );
      },
    },
    {
      label: "Journals",
      icon: "pi pi-fw pi-calendar",
      template: (item, options) => {
        return (
          <div
            onClick={() => navigate("/journals/allJournals")}
            className="text-white m-3 text-l"
            style={{ cursor: "pointer", letterSpacing: "1px" }}
          >
            <span>{item.label}</span>
          </div>
        );
      },
    },
    {
      label: "Logout",
      icon: "pi pi-fw pi-power-off",
      template: (item, options) => {
        return (
          <div
            onClick={() => {
              logout().then((res) => {
                console.log(res);

                if (res.status === 200) {
                  window.localStorage.clear();
                  navigate("/");
                }
              });
            }}
            className="text-white m-3 text-l"
            style={{ cursor: "pointer", letterSpacing: "1px" }}
          >
            <span>{item.label}</span>
          </div>
        );
      },
    },
  ];
  const dialogFuncMap = {
    displayBasic: setDisplayBasic,
  };
  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };
  const start = (
    <div className="flex align-items-center justify-content-center">
      <img
        alt="logo"
        src={logo}
        style={{ height: "75px" }}
        className="m-2 mr-4"
      ></img>
    </div>
  );
  const end = (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Button
        label="Change Password"
        className="h-3rem mx-3 px-3 flex align-items-center justify-content-center"
        style={{
          color: "white",
          padding: ".25em 1rem",
          borderRadius: "3px",
          fontWeight: "700",
          letterSpacing: ".3px",
        }}
        onClick={() => onClick("displayBasic")}
      />
      <div
        className="h-3rem mx-3 px-3 flex align-items-center justify-content-center"
        style={{
          backgroundColor: "#42506B",
          color: "white",
          padding: ".25em 1rem",
          borderRadius: "3px",
          fontWeight: "700",
          letterSpacing: ".3px",
        }}
      >
        {userType}
      </div>
    </div>
  );
  const change = async () => {
    try {
      setLoading(true);
      const data = { password: value };
      const res = await changePassword(data);
      console.log("res", res);
      if (res.status === 204) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail:
            "Password changed successfully!\n you are redirecting to login",
          life: 3000,
        });
        onHide("displayBasic");
        setTimeout(() => {
          logout()
            .then((res) => {
              console.log(res);
              if (res.status === 200) {
                window.localStorage.clear();
                navigate("/");
              }
            })
            .catch(() => {
              window.localStorage.clear();
              navigate("/");
            })
            .finally(() => {
              setLoading(false);
            });
        }, 1000);
      }
    } catch (error) {
      console.log("error", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Password couldn't be changed!",
        life: 3000,
      });
    }
  };
  const renderFooter = (name) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Button
          label="Close"
          onClick={() => onHide(name)}
          style={{
            backgroundColor: "#42506B",
            color: "white",
          }}
        />

        <Button
          label="Save"
          icon="pi pi-check"
          loading={isLoading}
          onClick={() => change()}
          disabled={value === ""}
        />
      </div>
    );
  };
  return (
    <div>
      <Toast ref={toast} />
      <Menubar
        style={{
          display: "flex",
          background: "#091A32",
          borderStyle: "none",
          borderRadius: "0px",
          gap: "150px",
        }}
        className="h-6rem text-white p-0 m-0"
        start={start}
        model={
          userType === "Accounts Team"
            ? items2
            : userType === "Office User"
            ? items3
            : items
        }
        end={end}
        orientation="vertical"
      />
      <Dialog
        header="Change Password"
        visible={displayBasic}
        style={{ width: "30vw" }}
        footer={renderFooter("displayBasic")}
        onHide={() => onHide("displayBasic")}
      >
        <div className="card flex justify-content-center">
          <div className="flex flex-column gap-2">
            <label htmlFor="password">New Password</label>
            <InputText
              id="password"
              value={value}
              placeholder="Enter new password"
              onChange={(e) => {
                setValue(e.target.value);
              }}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default AdminMenuBar;
