import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import {
  getAllUsers,
  toggleStatus,
  deleteUser,
  searchMemberApi,
} from "../../../../../apis/api";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import ViewUserPopUp from "./components/ViewUserPopUp";
import DeleteUserPopUp from "./components/DeleteUserPopUp";

function ListAllUsers() {
  const navigate = useNavigate();
  const [update, setUpdate] = useState(false);
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchMember, setSearchMember] = useState("");
  console.log(userData);

  useEffect(() => {
    if (searchMember.length) {
      searchMemberApi(searchMember).then((res) => {
        if (Array.isArray(res)) {
          setUserData(res);
        } else {
          console.log("Keywords Not Enough");
        }
      });
    } else {
      getAllUsers().then((res) => setUserData(res));
    }
  }, [searchMember, update]);
  useEffect(() => {
    getAllUsers().then((data) => {
      setUserData(data);
    });
  }, [update]);

  const toast = useRef(null);
  const roles = {
    "ff000000-0000-0000-0000-000000000000": "Super Admin",
    "ff000000-0000-0000-0000-000000000005": "Joint Secretary",
    "ff000000-0000-0000-0000-000000000003": "Secretary",
    "ff000000-0000-0000-0000-0000000000016": "Vice President",
    "ff000000-0000-0000-0000-0000000000017": "SR.Vice President",
    "ff000000-0000-0000-0000-000000000004": "President",
    "ff000000-0000-0000-0000-000000000006": "Membership Office",
  };

  const showSuccess = (e) => {
    toast.current.show({
      severity: "success",
      summary: "Deleted",
      detail: "The User has been Deleted",
      life: 3000,
    });
  };

  const showError = () => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: "There is an Error, Deleting the User. Please Try Again",
      life: 3000,
    });
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between align-items-center">
        <h2 className="m-0">User Management</h2>
        <Toast ref={toast}></Toast>

        <div>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={searchMember}
              onChange={(e) => setSearchMember(e.target.value)}
              placeholder="Search Member"
            />
            <Button className="ml-3" label="Search User"></Button>
          </span>
        </div>
      </div>
    );
  };

  const isActiveBody = (data) => {
    return (
      <div className="flex justify-content-center align-items-center">
        <Checkbox
          label={data.is_active ? "Deactivate" : "Activate"}
          checked={data.is_active}
          className="m-1"
          onChange={() => {
            setLoading(true);
            toggleStatus(data.id).then((data) => {
              
              if (data.status === "success") {
                setLoading(false);
              }
            });
          }}
          onClick={() => {
            setLoading(true);
            toggleStatus(data.id).then((data) => {
              
              if (data.status === "success") {
                setLoading(false);
              }
            });
          }}
        ></Checkbox>
        <span>{data.is_active ? "Active" : "InActive"}</span>
        <div className="m-1"></div>
      </div>
    );
  };
  const deleteBody = (data) => {
    return (
      <div className="flex justify-content-center align-items-center ">
        <DeleteUserPopUp
          setUpdate={setUpdate}
          update={update}
          showSuccess={showSuccess}
          showError={showError}
          data={data}
        />
      </div>
    );
  };
  const viewProfileBody = (data) => {
    return (
      <div className="flex justify-content-center align-items-center ">
        <ViewUserPopUp userData={data} />
      </div>
    );
  };

  const roleBody = (data) => {
    return <div>{roles[data.role_id]}</div>;
  };

  const header = renderHeader();

  return (
    <div className="m-3">
      <Toast ref={toast} />
      <div>
        <div className="card">
          <DataTable
            header={header}
            value={userData}
            paginator
            rows={15}
            showGridlines
            responsiveLayout="scroll"
          >
            <Column field="email" header="Email"></Column>
            <Column field="first_name" header="First Name"></Column>
            <Column field="last_name" header="Last Name"></Column>

            <Column body={roleBody} header="Role"></Column>
            <Column body={isActiveBody} header="Active Status"></Column>
            <Column body={viewProfileBody} header="View Profile"></Column>
            <Column body={deleteBody} header="Delete User"></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
}

export default ListAllUsers;
