import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

function ViewUserPopUp({ userData }) {
  const [displayBasic, setDisplayBasic] = useState(false);
  const [position, setPosition] = useState("center");
  const dialogFuncMap = {
    displayBasic: setDisplayBasic,
  };
  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };

  const renderFooter = (name) => {
    return (
      <div>
        <Button
          label="Close "
          icon="pi pi-check"
          onClick={() => onHide(name)}
          autoFocus
        />
      </div>
    );
  };
  console.log(userData);
  return (
    <div>
      <Button
        label="View User"
        className="h-2rem"
        onClick={() => onClick("displayBasic")}
      />
      <Dialog
        header="User Detail"
        visible={displayBasic}
        style={{ width: "30vw" }}
        footer={renderFooter("displayBasic")}
        onHide={() => onHide("displayBasic")}
      >
        <div>
          <div className="m-2">
            <strong>Email:</strong> {userData.email}
          </div>
          <div className="m-2">
            <strong>FirstName: </strong>
            {userData.first_name}
          </div>
          <div className="m-2">
            <strong>LastName:</strong> {userData.last_name}
          </div>
          <div className="m-2">
            <strong>Phone Number:</strong> {userData.phone_number}
          </div>
          <div className="m-2">
            <strong>Email:</strong> {userData.email}
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default ViewUserPopUp;
