import React, { useRef } from "react";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Password } from "primereact/password";
import { useFormik } from "formik";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import { createNewUser } from "../../../../../apis/api";
function AddnewUser() {
  const toast = useRef(null);
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      phone_number: "",
      email: "",
      encrypted_password: "",
      role: "",
    },
    validate: (data) => {
      let errors = {};

      if (!data.first_name) {
        errors.first_name = "First Name is required.";
      }
      if (!data.last_name) {
        errors.last_name = "Last Name is required.";
      }

      if (!data.email) {
        errors.email = "Email is required.";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)
      ) {
        errors.email = "Invalid email address. E.g. example@email.com";
      }

      if (!data.encrypted_password) {
        errors.encrypted_password = "Password is required.";
      }
      if (!data.phone_number) {
        errors.phone_number = "Password is required.";
      }
      if (!data.role) {
        errors.role = "Role is required.";
      }
      console.log("Errors", errors);
      return errors;
    },
    onSubmit: (data) => {
      const userData = {
        user: {
          first_name: data.first_name,
          last_name: data.last_name,
          phone_number: data.phone_number,
          email: data.email,
          encrypted_password: data.encrypted_password,
        },
        role: data.role,
      };
      createNewUser(userData)
        .then((data) => {
          console.log(data);
          if (data.status === "success") {
            showSuccess("User Created");
          } else if (data.err) {
            showError("Error Occured, Please Try Again");
          }
        })
        .catch((err) => showError("Email Already Exists"));
    },
  });
  const passwordHeader = <h6>Pick a password</h6>;
  const passwordFooter = (
    <React.Fragment>
      <Divider />
      <p className="mt-2">Suggestions</p>
      <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: "1.5" }}>
        <li>At least one lowercase</li>
        <li>At least one uppercase</li>
        <li>At least one numeric</li>
        <li>Minimum 8 characters</li>
      </ul>
    </React.Fragment>
  );

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  const showSuccess = () => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "New User Created",
      life: 3000,
    });
  };
  const showError = (message) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: message,
      life: 3000,
    });
  };
  return (
    <div>
      <Divider className="mt-5" align="center">
        <h2>Add New User</h2>
      </Divider>
      <Toast ref={toast} />
      <div className="flex justify-content-center">
        <form onSubmit={formik.handleSubmit} className="p-fluid">
          <div className="flex">
            <div className="card w-25rem mx-5 ">
              <div className="field my-5 ">
                <span className="p-float-label">
                  <InputText
                    id="first_name"
                    name="first_name"
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    autoFocus
                  />
                  <label htmlFor="name">First Name*</label>
                </span>
                {getFormErrorMessage("first_name")}
              </div>
              <div className="field my-5">
                <span className="p-float-label">
                  <InputText
                    id="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                  <label htmlFor="email">Email*</label>
                  {getFormErrorMessage("email")}
                </span>
              </div>
              <div className="field my-5">
                <span className="p-float-label">
                  <Password
                    id="encrypted_password"
                    name="encrypted_password"
                    value={formik.values.encrypted_password}
                    onChange={formik.handleChange}
                    toggleMask
                    header={passwordHeader}
                    footer={passwordFooter}
                  />
                  <label htmlFor="password">Password*</label>
                </span>
                {getFormErrorMessage("encrypted_password")}
              </div>
            </div>
            <div className="card w-25rem mx-5">
              <div className="field my-5 ">
                <span className="p-float-label">
                  <InputText
                    id="last_name"
                    name="last_name"
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    autoFocus
                  />
                  <label htmlFor="name">Last Name*</label>
                </span>
                {getFormErrorMessage("last_name")}
              </div>
              <div className="field my-5 ">
                <span className="p-float-label">
                  <InputText
                    id="phone_number"
                    name="phone_number"
                    value={formik.values.phone_number}
                    onChange={formik.handleChange}
                    autoFocus
                  />
                  <label htmlFor="name">Phone Number*</label>
                </span>
                {getFormErrorMessage("phone_number")}
              </div>
              <div className="field my-5 ">
                <span className="p-float-label">
                  <Dropdown
                    optionLabel="name"
                    optionValue="value"
                    id="role"
                    name="role"
                    value={formik.values.role}
                    onChange={formik.handleChange}
                    autoFocus
                    options={[
                      {
                        name: "Super Admin",
                        value: "ff000000-0000-0000-0000-000000000000",
                      },
                      {
                        name: "Joint Secretary",
                        value: "ff000000-0000-0000-0000-000000000005",
                      },
                      {
                        name: "Secretary",
                        value: "ff000000-0000-0000-0000-000000000003",
                      },
                      {
                        name: "Vice President",
                        value: "ff000000-0000-0000-0000-000000000016",
                      },
                      {
                        name: "SR.Vice President",
                        value: "ff000000-0000-0000-0000-000000000017",
                      },
                      {
                        name: "President",
                        value: "ff000000-0000-0000-0000-000000000004",
                      },
                      {
                        name: "Membership Office",
                        value: "ff000000-0000-0000-0000-000000000006",
                      },
                      {
                        name: "COO User",
                        value: "ff000000-0000-0000-0000-000000000007",
                      },
                      {
                        name: "TALLY User",
                        value: "ff000000-0000-0000-0000-000000000008",
                      },
                      {
                        name: "Election Scanner",
                        value: "ff000000-0000-0000-0000-000000000009",
                      },
                      {
                        name: "Election Voter",
                        value: "ff000000-0000-0000-0000-000000000010",
                      },
                      {
                        name: "Election Results",
                        value: "ff000000-0000-0000-0000-000000000011",
                      },
                      {
                        name: "Accounts Team",
                        value: "ff000000-0000-0000-0000-000000000012",
                      },
                      {
                        name: "Election Activity user",
                        value: "ff000000-0000-0000-0000-000000000013",
                      },
                      {
                        name: "Election RFID Update User",
                        value: "ff000000-0000-0000-0000-000000000014",
                      },
                      {
                        name: "Fkcci Office User",
                        value: "ff000000-0000-0000-0000-000000000015",
                      },
                    ]}
                  />
                  <label htmlFor="name">Role*</label>
                </span>
                {getFormErrorMessage("role")}
              </div>
            </div>
          </div>

          <Button type="submit" label="Add New User" className="mt-2 w-20rem" />
        </form>
      </div>
    </div>
  );
}

export default AddnewUser;
