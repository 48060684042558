import { Button } from "primereact/button";
import React from "react";
import { Image } from "primereact/image";

function MemberProfile({ data, size, status }) {
  console.log(data);
  return (
    <div>
      {data && (
        // <div>
        //   <div className="flex flex-column align-items-center justify-content-center">
        //     <div className="flex">
        //       {" "}
        //       <div className="mx-5 my-3 p-3">
        //         <Image
        //           preview
        //           alt=""
        //           width={size ? size : 200}
        //           height={size ? size : 200}
        //           src={
        //             data[0]
        //               ? data[0].link
        //               : "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=826&t=st=1661248188~exp=1661248788~hmac=64bb7d23be8dcef15ecfef304e7d5bfe293f29bbf5b48fd6cadb282e205f9ddb"
        //           }
        //         />
        //       </div>
        //       <div className="mx-5 my-3 p-3">
        //         <Image
        //           preview
        //           alt=""
        //           width={size ? size : 200}
        //           height={size ? size : 200}
        //           src={
        //             data[1]
        //               ? data[1].link
        //               : "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=826&t=st=1661248188~exp=1661248788~hmac=64bb7d23be8dcef15ecfef304e7d5bfe293f29bbf5b48fd6cadb282e205f9ddb"
        //           }
        //         />
        //       </div>
        //     </div>
        //     <div className="flex">
        //       <div className="mx-5 my-3 p-3">
        //         <Image
        //           preview
        //           alt=""
        //           width={size ? size : 200}
        //           height={size ? size : 200}
        //           src={
        //             data[2]
        //               ? data[2].link
        //               : "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=826&t=st=1661248188~exp=1661248788~hmac=64bb7d23be8dcef15ecfef304e7d5bfe293f29bbf5b48fd6cadb282e205f9ddb"
        //           }
        //         />
        //       </div>
        //       {/* <div className="mx-5 my-3 p-3">
        //         <Image
        //           preview
        //           alt=""
        //           width={size ? size : 200}
        //           height={size ? size : 200}
        //           src={
        //             data[3]
        //               ? data[3].link
        //               : "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=826&t=st=1661248188~exp=1661248788~hmac=64bb7d23be8dcef15ecfef304e7d5bfe293f29bbf5b48fd6cadb282e205f9ddb"
        //           }
        //         />
        //       </div> */}
        //     </div>
        //   </div>
        //   <div className="text-center bg-black">
        //     <Button
        //       label={status}
        //       className="w-6 "
        //       style={{ background: "green" }}
        //       disabled
        //       // style={{
        //       //   backgroundColor: "#6a11cb",
        //       //   color: "white",
        //       //   textTransform: "uppercase",
        //       //   padding: ".25em 1rem",
        //       //   borderRadius: "3px",
        //       //   fontWeight: "700",
        //       //   letterSpacing: ".3px",
        //       //   margin: "20px",
        //       // }}
        //     ></Button>
        //   </div>
        // </div>
        <></>
      )}
    </div>
  );
}

export default MemberProfile;
