import moment from "moment";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { useDownloadExcel } from "react-export-table-to-excel";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Paginator } from "primereact/paginator";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { QueryGenerator } from "../../../../../helper/QueryGenerator";
import { useFormik } from "formik";
import { getReceipts, searchReceipt } from "../../../../../apis/api";
import { useReactToPrint } from "react-to-print";
import { SplitButton } from "primereact/splitbutton";
const ListOfAllRecipt = () => {
  const [data, setData] = useState([]);
  const [receiptdata, setReceiptData] = useState([]);

  const [financialYear, setFinancialYear] = useState("");
  const [period, setPeriod] = useState("");
  const [search, setSearch] = useState("");
  const [history, setHistory] = useState("");

  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [displayBasic1, setDisplayBasic1] = useState(false);
  const [displayBasic2, setDisplayBasic2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [position, setPosition] = useState("center");
  const [url, setUrl] = useState("");
  const [searchMember, setSearchMember] = useState("");
  const [isLoading2, setLoading2] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const [singleReceipt, setSingleReceipt] = useState();

  const memberRef = useRef(null);
  const memberRefforPdf = useRef(null);

  const styles = {
    border: "1px solid #000000",
    width: "200px",
    paddingRight: "5px",
    paddingTop: "10px",
    marginTop: "2px",
    textAlign: "right",
  };
  const styles2 = {
    width: "354px",
    border: "1px solid #000000",
    borderRadius: "0px",
    marginLeft: "2px",
    textAlign: "left",
    marginTop: "2px",
  };
  const thStyle = {
    fontSize: "13px",
    border: "1px solid",
    borderRight: "0",
    background: "#fafafa",
    padding: "10px 5px",
  };
  const thStyle2 = {
    width: "16%",
  };
  const tdStyle = {
    fontSize: "15px",
    border: "1px solid",
    borderLeft: "0",
    padding: "10px 5px",
  };
  const onBasicPageChange = (event) => {
    console.log("event", event);
    setBasicFirst(event.first);
    setBasicRows(event.rows);
    setCurrentPage(event.page);
  };
  const dialogFuncMap = {
    displayBasic1: setDisplayBasic1,
  };
  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };
  const formik = useFormik({
    initialValues: {
      memberIdStart: "",
      memberIdEnd: "",
      admissionStart: "",
      admissionEnd: "",
      lastPaymentStart: "",
      lastPaymentEnd: "",
      memberType: "",
      categoryType: "",
      sortType: "receiptNo",
      dataType: "",
      paymentType: "",
      paymentType: "",
      companyName: "",
    },

    onSubmit: async (data) => {
      console.log("data", data);
      // formik.handleReset();
      const arr = [];
      Object.entries(data).map(([key, value]) => {
        arr.push({ [key]: value });
      });
      const query = await QueryGenerator(arr);
      console.log("query", query);
      setUrl(query);
      onHide("displayBasic1");
    },
  });

  const renderUploadFooter = (name) => {
    return (
      <div>
        {/* <Button
          type="submit"
          label="Search"
          icon="pi pi-check"
          // onClick={onSubmit}
          autoFocus
        />
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={() => {
            onHide(name);
          }}
          className="p-button-text"
        /> */}
      </div>
    );
  };
  const list = [
    {
      label: "Download as PDF",
      icon: "pi pi-arrow-down",
      command: () => {
        getAllData(totalRecords, "print");
      },
    },
    {
      label: "Download as Excel",
      icon: "pi pi-arrow-down",
      command: () => {
        console.log("hiihi");
        getAllData(totalRecords, "excel");
      },
    },
  ];

  const memberTypeOptions = ["Ordinary", "Patron"];
  const categoryType = [
    {
      label: "Small Scale Manufacturing Activit",
      value: "ms",
    },
    {
      label: "Small Scale Trading Activity",
      value: "ts",
    },
    {
      label: "Large Scale Manufacturing Activity",
      value: "ml",
    },
    {
      label: "Large Scale Service Activity",
      value: "sl",
    },
    {
      label: "Association",
      value: "as",
    },
    {
      label: "Small Scale Service Activity",
      value: "ss",
    },
    {
      label: "Large Scale Trading Activity",
      value: "tl",
    },
    {
      label: "District Chambers Of Commerce",
      value: "di",
    },
    {
      label: "Profession",
      value: "pr",
    },
  ];
  const sortOptions = ["Member ID", "Pincode", "City", "Compony Name"];
  const paymentOptions = ["Cheque", "NEFT"];

  const dataOptions = [
    { label: "Moffisal", value: "Local Only" },
    { label: "All data", value: "All data" },
  ];
  const renderHeader = () => {
    return (
      <div>
        <div className="flex justify-content-end ">
          <div>
            <div className="flex align-items-center flex-wrap gap-3">
              <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
                  value={searchMember}
                  onChange={(e) => {
                    setSearchMember(e.target.value);
                  }}
                  onKeyUp={() => {
                    if (searchMember.length >= 4) {
                      searchReceipt(searchMember).then((res) => {
                        if (Array.isArray(res)) {
                          setData(res);
                        } else {
                          // showError("Keywords Not Enough");
                        }
                      });
                    } else {
                      getReceipts(currentPage, 10, url).then((res) => {
                        setData(res.results);
                        setTotalRecords(res.total);
                      });
                    }
                  }}
                  placeholder="Search Member"
                />
              </span>
              <h3>Total Receipts({totalRecords})</h3>
              <span className="p-input-icon-left">
                <Button
                  onClick={() => {
                    onClick("displayBasic1");
                  }}
                  className="ml-2 "
                  label="Apply Filters"
                ></Button>
                {/* <Button
                label="Download As Excel"
                className="ml-2 "
                icon="pi pi-arrow-down"
                loading={loading}
                onClick={() => {
                  getAllData(totalRecords);
                }}
              ></Button> */}
                <SplitButton
                  label="Download"
                  className="ml-2 "
                  icon="pi pi-arrow-down"
                  loading={loading}
                  model={list}
                ></SplitButton>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const header = renderHeader();
  const handleMemberExcelPrint = useDownloadExcel({
    currentTableRef: memberRef.current,
    filename: "FKCCI Receipt",
    sheet: "Members",
  });
  const pageStyle = `@media print {
    @page {
      size: 210mm 297mm;
      margin: 0;
    }
  }`;
  const handleMemberPrint = useReactToPrint({
    pageStyle: pageStyle,
    content: () => memberRefforPdf.current,
  });
  const getAllData = async (length, dataType) => {
    setLoading(true);
    getReceipts(0, length, url)
      .then((res) => {
        return res;
      })
      .then((res) => {
        console.log("res2", res);
        setReceiptData(res?.results);
        setTimeout(() => {
          if (dataType === "print") {
            handleMemberPrint();
          } else {
            handleMemberExcelPrint.onDownload();
          }
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  useEffect(() => {
    getReceipts(currentPage, 10, url)
      .then((res) => {
        console.log("res", res);
        setData(res.results);
        setTotalRecords(res.total);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, [url, currentPage]);

  const city = (data) => {
    return (
      <div
        onClick={() => {
          console.log("Button", data);
          setSingleReceipt(data);
          setDisplayBasic2(true);
        }}
      >
        {data.city}, {data.pincode}
      </div>
    );
  };
  const status = (data) => {
    return (
      <div
        onClick={() => {
          setSingleReceipt(data);
          setDisplayBasic2(true);
        }}
      >
        {console.log("Button", data)}
        {data.cancel_status ? "cancelled" : "not-cancelled"}
      </div>
    );
  };
  const last_payment = (data) => {
    return (
      <div>
        {data?.invoice_date !== null ? (
          <div
            onClick={() => {
              console.log("Button", data);
              setSingleReceipt(data);
              setDisplayBasic2(true);
            }}
          >
            {moment(data?.invoice_date).format("DD MMM YYYY")}
            {", "}
            {moment(data?.invoice_date).format("h:mm a")}
          </div>
        ) : null}
      </div>
    );
  };
  const admissionDate = (data) => {
    return (
      <div>
        {data?.date_of_admission !== null ? (
          <div
            onClick={() => {
              console.log("Button", data);
              setSingleReceipt(data);
              setDisplayBasic2(true);
            }}
          >
            {moment(data?.date_of_admission).format("DD MMM YYYY")}
          </div>
        ) : null}
      </div>
    );
  };
  const name_of_org = (data) => {
    return (
      <div
        className="capitalize cursor-pointer"
        onClick={() => {
          console.log("Button", data);
          setSingleReceipt(data);
          setDisplayBasic2(true);
        }}
      >
        {data.name_of_org}
      </div>
    );
  };
  const membership_category = (data) => {
    return (
      <div
        className="capitalize cursor-pointer"
        onClick={() => {
          console.log("Button", data);
          setSingleReceipt(data);
          setDisplayBasic2(true);
        }}
      >
        {data.membership_category}
      </div>
    );
  };
  const member_category = (data) => {
    return (
      <div
        className="capitalize cursor-pointer"
        onClick={() => {
          console.log("Button", data);
          setSingleReceipt(data);
          setDisplayBasic2(true);
        }}
      >
        {data.member_category}
      </div>
    );
  };
  const paid_amount = (data) => {
    return (
      <div
        className="capitalize cursor-pointer"
        onClick={() => {
          console.log("Button", data);
          setSingleReceipt(data);
          setDisplayBasic2(true);
        }}
      >
        {data.paid_amount}
      </div>
    );
  };
  const MEM_id_no = (data) => {
    return (
      <div
        className="capitalize cursor-pointer"
        onClick={() => {
          console.log("Button", data);
          setSingleReceipt(data);
          setDisplayBasic2(true);
        }}
      >
        {data.MEM_id_no}
      </div>
    );
  };
  const receipt_no = (data) => {
    return (
      <div
        className="capitalize cursor-pointer"
        onClick={() => {
          console.log("Button", data);
          setSingleReceipt(data);
          setDisplayBasic2(true);
        }}
      >
        {data.receipt_no}
      </div>
    );
  };
  return (
    <div>
      <div className="m-3">
        <div className="card">
          <DataTable
            value={data}
            header={header}
            showGridlines
            loading={isLoading2}
            selectionMode={"checkbox"}
            selection={selectedProducts}
            onSelectionChange={(e) => setSelectedProducts(e.value)}
          >
            <Column field={MEM_id_no} header="Member ID"></Column>
            <Column field={receipt_no} header="Receipt No."></Column>
            <Column field={name_of_org} header="Name of Organization"></Column>
            <Column field={member_category} header="Member Tag"></Column>
            <Column
              field={membership_category}
              header="Membership(Small/Large)"
            ></Column>
            <Column field={paid_amount} header="Total Amount"></Column>
            <Column field={admissionDate} header="Date of Admission"></Column>
            <Column field={last_payment} header="Last Payment"></Column>
            <Column field={city} header="City & Pincode"></Column>
            <Column field={status} header="Status"></Column>
          </DataTable>
          <Paginator
            first={basicFirst}
            totalRecords={totalRecords}
            rows={basicRows}
            onPageChange={onBasicPageChange}
          ></Paginator>
        </div>
        <div style={{ display: "none" }}>
          <div
            ref={memberRefforPdf}
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, minmax(63.5mm, 1fr))",
              gridAutoRows: "max-content",
              gridAutoFlow: "row",
              paddingTop: "1mm",
              paddingLeft: "0mm",
              paddingRight: "8mm",
            }}
          >
            {selectedProducts?.length
              ? selectedProducts?.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      marginTop:
                        index % 24 === 0 || index % 24 === 1 || index % 24 === 2
                          ? "11.5mm"
                          : "0px",
                      height: "34.125mm",
                      // border: "0.125px solid #000000",
                      padding: "2px 5px 0px 20px",
                      borderRadius: "20px",
                      overflow: "hidden",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "0.7em",
                        color: "#000000",
                        lineHeight: "14px",
                      }}
                    >
                      {item?.MEM_id_no}
                    </p>
                    <p
                      style={{
                        fontSize: "0.7em",
                        fontWeight: "400",
                        lineHeight: "14px",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {item?.name_of_org}
                    </p>
                    <p
                      style={{
                        fontSize: "0.7em",
                        fontWeight: "400",
                        lineHeight: "14px",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {item?.address}
                    </p>
                    <p
                      style={{
                        fontSize: "0.7em",
                        fontWeight: "400",
                        lineHeight: "14px",
                      }}
                    >
                      {item?.city} - {item?.pincode}
                    </p>
                    <p
                      style={{
                        fontSize: "0.7em",
                        fontWeight: "400",
                        lineHeight: "14px",
                      }}
                    >
                      Phone : {item?.phone_number}
                    </p>
                    <p
                      style={{
                        fontSize: "0.7em",
                        fontWeight: "400",
                        lineHeight: "14px",
                      }}
                    >
                      mobile : {item?.mobile}
                    </p>
                  </div>
                ))
              : receiptdata?.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      marginTop:
                        index % 24 === 0 || index % 24 === 1 || index % 24 === 2
                          ? "11.5mm"
                          : "0px",
                      height: "34.125mm",
                      // border: "0.125px solid #000000",
                      padding: "2px 5px 0px 20px",
                      borderRadius: "20px",
                      overflow: "hidden",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "0.7em",
                        color: "#000000",
                        lineHeight: "14px",
                      }}
                    >
                      {item?.MEM_id_no}
                    </p>
                    <p
                      style={{
                        fontSize: "0.7em",
                        fontWeight: "400",
                        lineHeight: "14px",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {item?.name_of_org}
                    </p>
                    <p
                      style={{
                        fontSize: "0.7em",
                        fontWeight: "400",
                        lineHeight: "14px",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {item?.address}
                    </p>
                    <p
                      style={{
                        fontSize: "0.7em",
                        fontWeight: "400",
                        lineHeight: "14px",
                      }}
                    >
                      {item?.city} - {item?.pincode}
                    </p>
                    <p
                      style={{
                        fontSize: "0.7em",
                        fontWeight: "400",
                        lineHeight: "14px",
                      }}
                    >
                      Phone : {item?.phone_number}
                    </p>
                    <p
                      style={{
                        fontSize: "0.7em",
                        fontWeight: "400",
                        lineHeight: "14px",
                      }}
                    >
                      mobile : {item?.mobile}
                    </p>
                  </div>
                ))}
          </div>
        </div>
        <div style={{ display: "none" }}>
          <table ref={memberRef} border="1">
            <thead>
              <tr>
                <th>Date of voucher</th>
                <th>Voucher No.</th>
                <th>Reference No.</th>
                <th>Party Name</th>
                <th>Address</th>
                <th>City</th>
                <th>Pincode</th>
                <th>Amount</th>
                <th>Cr Ledger</th>
                <th>Dr Value</th>
                <th>Cr Value</th>
                <th>Narration</th>
                <th>Bill Reference</th>
                <th>Payment Type</th>
                <th>Cheque No.</th>
                <th>Cheque Date</th>
                <th>Bank Name</th>
                <th>Cost Center</th>
                <th>SGST</th>
                <th>CGST</th>
                <th>IGST</th>
                <th>Admission Amount</th>
                <th>Interest Amount</th>
                <th>Misc Amount</th>
                <th>GST No.</th>
                <th>Credited to Bank Name</th>
              </tr>
            </thead>
            <tbody>
              {receiptdata?.map((val) => (
                <tr>
                  <td>
                    {val?.date_of_payment !== null
                      ? `${moment(val?.invoice_date).format("DD-MM-YYYY")}`
                      : null}
                  </td>
                  <td>{val.receipt_no}</td>
                  <td>{val.MEM_id_no}</td>
                  <td>{val.name_of_org}</td>
                  <td>{val.address}</td>
                  <td>{val.city}</td>
                  <td>{val.pincode}</td>
                  <td>
                    {Number(
                      Number(val.admission_amount) +
                        Number(val.subscription_amount)
                    ).toFixed(2)}
                  </td>
                  <td>Membership Fee</td>
                  <td>{val.subscription_amount}</td>
                  <td>{val.subscription_amount}</td>
                  <td>
                    {val.towards} {val?.MEM_id_no} {val?.name_of_org}
                  </td>
                  <td>{val.receipt_no}</td>
                  <td>{val.payment_type}</td>
                  <td>
                    {val.payment_type === "cheque"
                      ? val.cheque_number
                      : val.transactional_id}
                  </td>
                  <td>
                    {val?.date_of_payment !== null
                      ? `${moment(val?.date_of_payment).format("DD-MM-YYYY")}`
                      : null}
                  </td>
                  <td>{val.bank_name}</td>
                  <td>Admin</td>
                  <td>{val.sgst}</td>
                  <td>{val.cgst}</td>
                  <td>{val.igst}</td>
                  <td>{val.admission_amount}</td>
                  <td>{val.interest_amount}</td>
                  <td>{val.misc_amount}</td>
                  <td>{val.GSTIN}</td>
                  <td>Canara Bank Membership A/c</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Dialog
          header="Report Filters"
          visible={displayBasic1}
          breakpoints={{ "960px": "75vw" }}
          style={{ width: "60vw" }}
          footer={renderUploadFooter("displayBasic1")}
          onHide={() => onHide("displayBasic1")}
        >
          <form onSubmit={formik.handleSubmit}>
            <div>
              <div className="flex">
                <div className="text-l align-items-center" style={styles}>
                  Member ID From :
                </div>
                <InputText
                  style={{
                    width: "150px",
                    border: "1px solid #000000",
                    borderRadius: "0px",
                    marginLeft: "2px",
                    textAlign: "left",
                    marginTop: "2px",
                  }}
                  id="memberIdStart"
                  value={formik.values.memberIdStart}
                  onChange={formik.handleChange}
                ></InputText>
                <div
                  className="text-l align-items-center"
                  style={{
                    border: "1px solid #000000",
                    width: "50px",
                    padding: "10px",
                    paddingTop: "10px",
                    marginTop: "2px",
                    textAlign: "right",
                    marginLeft: "2px",
                  }}
                >
                  To :
                </div>
                <InputText
                  style={{
                    width: "150px",
                    border: "1px solid #000000",
                    borderRadius: "0px",
                    marginLeft: "2px",
                    textAlign: "left",
                    marginTop: "2px",
                  }}
                  id="memberIdEnd"
                  value={formik.values.memberIdEnd}
                  onChange={formik.handleChange}
                ></InputText>
              </div>
              <div className="flex">
                <div className="text-l align-items-center" style={styles}>
                  Admission From :
                </div>
                <Calendar
                  style={{
                    width: "150px",
                    border: "1px solid #000000",
                    borderRadius: "0px",
                    marginLeft: "2px",
                    textAlign: "left",
                    marginTop: "2px",
                  }}
                  id="adadmissionStart"
                  // value={formik.values.admissionStart}
                  onChange={(e) => {
                    console.log("event", moment(e.value).format("DD-MM-YYYY"));
                    // setAdmissionStart(moment(e.value).format("DD-MM-YYYY"));
                    formik.values.admissionStart = moment(e.value).format(
                      "YYYY-MM-DD"
                    );
                  }}
                />

                <div
                  className="text-l align-items-center"
                  style={{
                    border: "1px solid #000000",
                    width: "50px",
                    padding: "10px",
                    paddingTop: "10px",
                    marginTop: "2px",
                    textAlign: "right",
                    marginLeft: "2px",
                  }}
                >
                  To :
                </div>
                <Calendar
                  style={{
                    width: "150px",
                    border: "1px solid #000000",
                    borderRadius: "0px",
                    marginLeft: "2px",
                    textAlign: "left",
                    marginTop: "2px",
                  }}
                  id="admissionEnd"
                  // value={formik.values.admissionEnd}
                  onChange={(e) => {
                    // setAdmissionEnd(moment(e.value).format("DD-MM-YYYY"));
                    formik.values.admissionEnd = moment(e.value).format(
                      "YYYY-MM-DD"
                    );
                  }}
                />
              </div>
              <div className="flex">
                <div className="text-l align-items-center" style={styles}>
                  Last Payment From :
                </div>
                <Calendar
                  style={{
                    width: "150px",
                    border: "1px solid #000000",
                    borderRadius: "0px",
                    marginLeft: "2px",
                    textAlign: "left",
                    marginTop: "2px",
                  }}
                  id="lastPaymentStart"
                  // value={formik.values.admissionStart}
                  onChange={(e) => {
                    console.log("event", moment(e.value).format("DD-MM-YYYY"));
                    // setAdmissionStart(moment(e.value).format("DD-MM-YYYY"));
                    formik.values.lastPaymentStart = moment(e.value).format(
                      "YYYY-MM-DD"
                    );
                  }}
                />

                <div
                  className="text-l align-items-center"
                  style={{
                    border: "1px solid #000000",
                    width: "50px",
                    padding: "10px",
                    paddingTop: "10px",
                    marginTop: "2px",
                    textAlign: "right",
                    marginLeft: "2px",
                  }}
                >
                  To :
                </div>
                <Calendar
                  style={{
                    width: "150px",
                    border: "1px solid #000000",
                    borderRadius: "0px",
                    marginLeft: "2px",
                    textAlign: "left",
                    marginTop: "2px",
                  }}
                  id="lastPaymentEnd"
                  // value={formik.values.admissionEnd}
                  onChange={(e) => {
                    // setAdmissionEnd(moment(e.value).format("DD-MM-YYYY"));
                    formik.values.lastPaymentEnd = moment(e.value).format(
                      "YYYY-MM-DD"
                    );
                  }}
                />
              </div>
              <div className="flex">
                <div className="text-l align-items-center" style={styles}>
                  Compony Name:
                </div>
                <InputText
                  style={styles2}
                  id="companyName"
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                ></InputText>
              </div>
              <div className="flex">
                <div className="text-l align-items-center" style={styles}>
                  Member Type :
                </div>
                <Dropdown
                  showClear
                  id="memberType"
                  value={formik.values.memberType}
                  options={memberTypeOptions}
                  onChange={formik.handleChange}
                  placeholder="Not selected"
                  // editable
                  style={styles2}
                />
              </div>
              {/* <div className="flex">
                <div className="text-l align-items-center" style={styles}>
                  Scale Type :
                </div>
                <Dropdown
                  showClear
                  id="scaleType"
                  value={formik.values.scaleType}
                  options={scaleType}
                  onChange={formik.handleChange}
                  placeholder="Not selected"
                  // editable
                  style={styles2}
                />
              </div> */}
              <div className="flex">
                <div className="text-l align-items-center" style={styles}>
                  Category Type :
                </div>
                <Dropdown
                  showClear
                  id="categoryType"
                  value={formik.values.categoryType}
                  options={categoryType}
                  optionLabel="label"
                  onChange={formik.handleChange}
                  placeholder="Not selected"
                  // editable
                  style={styles2}
                />
              </div>
              <div className="flex">
                <div className="text-l align-items-center" style={styles}>
                  Report Sorting on :
                </div>
                <Dropdown
                  showClear
                  id="sortType"
                  value={formik.values.sortType}
                  options={sortOptions}
                  onChange={formik.handleChange}
                  placeholder="Sorting on"
                  // editable
                  style={styles2}
                />
              </div>
              <div className="flex">
                <div className="text-l align-items-center" style={styles}>
                  Data Selection :
                </div>
                <Dropdown
                  showClear
                  id="dataType"
                  value={formik.values.dataType}
                  options={dataOptions}
                  onChange={formik.handleChange}
                  placeholder="Not selected"
                  // editable
                  style={styles2}
                />
              </div>
              <div className="flex">
                <div className="text-l align-items-center" style={styles}>
                  Payment Type :
                </div>
                <Dropdown
                  showClear
                  id="paymentType"
                  value={formik.values.paymentType}
                  options={paymentOptions}
                  onChange={formik.handleChange}
                  placeholder="Not selected"
                  // editable
                  style={styles2}
                />
              </div>
              <div style={{ marginTop: "20px" }}>
                <Button
                  type="submit"
                  label="Search"
                  icon="pi pi-check"
                  autoFocus
                />
                <Button
                  label="Cancel"
                  icon="pi pi-times"
                  type="btn"
                  onClick={() => {
                    onHide("displayBasic1");
                    formik.resetForm();
                    setUrl("");
                  }}
                  className="p-button-text"
                />
              </div>
            </div>
          </form>
        </Dialog>
        <Dialog
          header="Report Details"
          visible={displayBasic2}
          breakpoints={{ "960px": "75vw" }}
          style={{ width: "60vw" }}
          footer={renderUploadFooter("displayBasic2")}
          onHide={() => setDisplayBasic2(false)}
        >
          {/* <hr /> */}
          <div>
            <table
              style={{
                width: "100%",
                textAlign: "left",
                border: "1px solid",
                borderCollapse: "collapse",
              }}
            >
              <tr>
                <th style={thStyle}>
                  {" "}
                  Date of voucher <span style={{ float: "right" }}>:</span>
                </th>
                <td style={Object.assign({}, tdStyle, thStyle2)}>
                  {singleReceipt?.invoice_date !== null
                    ? `${moment(singleReceipt?.invoice_date).format(
                        "DD-MM-YYYY"
                      )}`
                    : null}
                </td>
                <th style={Object.assign({}, thStyle, thStyle2)}>
                  {" "}
                  Receipt No <span style={{ float: "right" }}>:</span>
                </th>
                <td style={Object.assign({}, tdStyle, thStyle2)}>
                  {" "}
                  {singleReceipt?.receipt_no}
                </td>
                <th style={Object.assign({}, thStyle, thStyle2)}>
                  Member ID <span style={{ float: "right" }}>:</span>
                </th>
                <td style={Object.assign({}, tdStyle, thStyle2)}>
                  {singleReceipt?.MEM_id_no}
                </td>
              </tr>
              <tr>
                <th style={thStyle}>
                  Party Name <span style={{ float: "right" }}>:</span>
                </th>
                <td style={tdStyle} colSpan="3">
                  {singleReceipt?.name_of_org}
                </td>
                <th style={thStyle}>
                  GST No <span style={{ float: "right" }}>:</span>
                </th>
                <td style={tdStyle}>{singleReceipt?.GSTIN}</td>
              </tr>
              <tr>
                <th style={thStyle}>
                  Address <span style={{ float: "right" }}>:</span>
                </th>
                <td style={tdStyle} colSpan="3">
                  {singleReceipt?.address}
                </td>
                <th style={thStyle}>
                  City <span style={{ float: "right" }}>:</span>
                </th>
                <td style={tdStyle}>{singleReceipt?.city}</td>
              </tr>
              <tr>
                <th style={thStyle}>
                  Pincode <span style={{ float: "right" }}>:</span>
                </th>
                <td style={tdStyle}>{singleReceipt?.pincode}</td>
                <th style={thStyle}>
                  Cr ledger <span style={{ float: "right" }}>:</span>
                </th>
                <td style={tdStyle}> Membership Fee</td>
                <th style={thStyle}>
                  Cost Center <span style={{ float: "right" }}>:</span>
                </th>
                <td style={tdStyle}>Admin</td>
                {/* <th style={thStyle} >CR value <span style={{float:'right'}}>:</span></th>
                <td style={tdStyle} >{singleReceipt?.subscription_amount}</td>
                <th style={thStyle} >DR value <span style={{float:'right'}}>:</span></th>
                <td style={tdStyle} >{singleReceipt?.subscription_amount}</td> */}
              </tr>
              {/* <tr> */}

              {/* <th style={thStyle} >Bill Reference <span style={{float:'right'}}>:</span></th>
                <td style={tdStyle} > {singleReceipt?.receipt_no}</td> */}
              {/* </tr> */}
              <tr>
                <th style={thStyle}>
                  Payment Type <span style={{ float: "right" }}>:</span>
                </th>
                <td style={tdStyle}> {singleReceipt?.payment_type}</td>
                <th style={thStyle}>
                  {" "}
                  Cheque NO <span style={{ float: "right" }}>:</span>
                </th>
                <td style={tdStyle}>
                  {" "}
                  {singleReceipt?.payment_type === "cheque"
                    ? singleReceipt?.cheque_number
                    : singleReceipt?.transactional_id}{" "}
                </td>
                <th style={thStyle}>
                  Cheque Date <span style={{ float: "right" }}>:</span>
                </th>
                <td style={tdStyle}>
                  {" "}
                  {singleReceipt?.date_of_payment !== null
                    ? `${moment(singleReceipt?.date_of_payment).format(
                        "DD-MM-YYYY"
                      )}`
                    : null}
                </td>
              </tr>
              <tr>
                <th style={thStyle}>
                  Bank Name <span style={{ float: "right" }}>:</span>
                </th>
                <td style={tdStyle}> {singleReceipt?.bank_name}</td>
                <th style={thStyle}>
                  Sgst <span style={{ float: "right" }}>:</span>
                </th>
                <td style={tdStyle}> {singleReceipt?.sgst}</td>
                <th style={thStyle}>
                  Cgst <span style={{ float: "right" }}>:</span>
                </th>
                <td style={tdStyle}> {singleReceipt?.cgst}</td>
              </tr>
              <tr>
                <th style={thStyle}>
                  Igst <span style={{ float: "right" }}>:</span>
                </th>
                <td style={tdStyle}> {singleReceipt?.igst}</td>
                <th style={thStyle}>
                  Admission Amount <span style={{ float: "right" }}>:</span>
                </th>
                <td style={tdStyle}> {singleReceipt?.admission_amount}</td>
                <th style={thStyle}>
                  Interest Amount <span style={{ float: "right" }}>:</span>
                </th>
                <td style={tdStyle}> {singleReceipt?.interest_amount}</td>
              </tr>
              <tr>
                <th style={thStyle}>
                  Amount <span style={{ float: "right" }}>:</span>
                </th>
                <td style={tdStyle}>
                  {" "}
                  {Number(
                    Number(singleReceipt?.admission_amount) +
                      Number(singleReceipt?.subscription_amount)
                  ).toFixed(2)}
                </td>
                <th style={thStyle}>
                  Misc Amount <span style={{ float: "right" }}>:</span>
                </th>
                <td style={tdStyle}>{singleReceipt?.misc_amount}</td>
                <th style={thStyle}>
                  Total Amount <span style={{ float: "right" }}>:</span>
                </th>
                <td style={tdStyle}>{singleReceipt?.paid_amount}</td>
                {/* <th style={thStyle} >GST No <span style={{float:'right'}}>:</span></th>
                <td style={tdStyle} >{singleReceipt?.GSTIN}</td> */}
              </tr>
              <tr>
                <th style={thStyle}>
                  Remarks <span style={{ float: "right" }}>:</span>
                </th>
                <td style={tdStyle} colSpan="5">
                  {" "}
                  {singleReceipt?.remark}
                </td>
              </tr>
            </table>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default ListOfAllRecipt;
