import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import {
  getBookPrint,
  getCityWiseCount,
  getMemberCount,
  searchMemberApi,
} from "../../../../../apis/api";
import { getApprovedMembers } from "../../../../../apis/api";
import { approve } from "../../../../../apis/api";
import { useNavigate, useLocation } from "react-router-dom";
import { Paginator } from "primereact/paginator";
import { useReactToPrint } from "react-to-print";
import { Dropdown } from "primereact/dropdown";
import { getDefaultNormalizer } from "@testing-library/react";
import { SplitButton } from "primereact/splitbutton";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useDispatch, useSelector } from "react-redux";
import {
  setMemberData,
  setTotal,
} from "../../../../../redux/member/memberReducer";
import moment from "moment/moment";

function PrintMebers() {
  const toast = useRef(null);
  const navigate = useNavigate();
  const memberRef = useRef(null);
  const memberRefforExcel = useRef(null);
  const cityRefforExcel = useRef(null);
  const [update, setUpdate] = useState(false);
  const [searchMember, setSearchMember] = useState("");
  const [data, setData] = useState([]);
  const [url, setUrl] = useState(null);
  const [url2, setUrl2] = useState(null);
  const [newUrl, setNewUrl] = useState("");

  const [totalRecords, setTotalRecords] = useState(0);
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [excelData, setExcelData] = useState([]);
  const [excelDataCityWise, setExcelDataCityWise] = useState([]);
  const [category, setCategory] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [isLoading2, setLoading2] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [allMemberCount, setAllMemberCount] = useState(0);
  const [approvedMemberCount, setApprovedMemberCount] = useState(0);
  const [iconName, setIconName] = useState("pi pi-sort-amount-up");
  const [orderBy, setOrderBy] = useState("ASC");
  const { memberData, isRefresh, total } = useSelector((state) => state.member);
  const dispatch = useDispatch();
  const location = useLocation();
  const [type, setType] = useState("All");
  const [member, setMember] = useState("all");
  const [typeLable, setTypeLable] = useState("All Members");
  const [memberTypes, setMemberTypes] = useState("");
  useEffect(() => {
    if (isRefresh) {
      setLoading(true);
      console.log("loc", isRefresh, currentPage);
      getApprovedMembers(currentPage, 10, orderBy, newUrl, type)
        .then((res) => {
          // if (currentPage === 0) {
          //   getAlldata(res.total);
          // } else {
          //   setLoading(false);
          // }
          console.log("res", res);
          setData(res.results);
          setTotalRecords(res.total);
          dispatch(setMemberData(res.results));
          dispatch(setTotal(res.total));
        })
        .catch((err) => {
          console.log("error", err);
        })
        .finally((res) => {
          setLoading(false);
        });
      cityWiseData();
    } else {
      setLoading(false);
      setTotalRecords(total);
    }
  }, [update, currentPage, newUrl, orderBy, type]);
  useEffect(() => {
    getCount();
  }, []);
  const handleMemberPrint = useReactToPrint({
    content: () => memberRef.current,
    pageStyle: "@page { size: 210mm 297mm;margin-top:0mm }",
  });

  const getCount = async () => {
    getMemberCount()
      .then((res) => {
        console.log("resCount", res);
        setAllMemberCount(res?.totalCount);
        setApprovedMemberCount(res?.approvedCount);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const getAlldata = async (total, pType) => {
    setLoading2(true);
    let url = newUrl;
    // url = url.toString().substring(1);
    getBookPrint(url, member)
      .then((res) => {
        console.log("result", res);
        setExcelData(res);
        setTimeout(() => {
          handleMemberPrint();
        }, 1000);
      })
      .catch((err) => {
        console.log(err, "error");
      })
      .finally((res) => {
        setLoading2(false);
      });
  };
  const getFilteredData = async () => {
    setLoading(true);
    searchMemberApi(searchMember).then((res) => {
      if (Array.isArray(res)) {
        setExcelData(res);
        setData(res);
        setTotalRecords(res?.length);
        dispatch(setMemberData(res));
        setUrl2(`&key=${searchMember}`);
        setIsSearch(true);
      } else {
        // showError("Keywords Not Enough");
      }
    });
    setLoading(false);
  };
  const cityWiseData = async () => {
    const data = await getCityWiseCount();
    setExcelDataCityWise(data);
  };
  const handleMemberExcelPrint = useDownloadExcel({
    currentTableRef: memberRefforExcel.current,
    filename: "FKCCI Members",
    sheet: "Members",
  });
  const cat = {
    ms: "MEMBERS - MANUFACTURE - SMALL",
    ml: "MEMBERS - MANUFACTURE - LARGE",
    ts: "MEMBERS - TRADE - SMALL",
    tl: "MEMBERS - TRADE - LARGE",
    as: "MEMBERS - ASSOCIATION",
    di: "MEMBERS - DISTRICT ASSOCIATION",
    ss: "MEMBERS - SERVICE - SMALL",
    sl: "MEMBERS - SERVICE - LARGE",
    pr: "MEMBERS - PROFESSION",
  };
  const cat2 = {
    ms: "Small Scale Manufacturing Activity",
    ml: "Large Scale Manufacturing Activity",
    ts: "Small Scale Trading Activity",
    tl: "Large Scale Trading Activity",
    as: "Association",
    di: "District Chambers Of Commerce",
    ss: "Small Scale Service Activity",
    sl: "Large Scale Service Activity",
    pr: "Profession",
  };
  const items = [
    {
      label: "Small Scale Manufacturing Activity",
      value: "ms",
      command: () => {
        setCurrentPage(0);
        setUrl("&industry=ms");
        setNewUrl("&industry=ms");
        setIsSearch(false);
        setCategory("ms");
      },
    },
    {
      label: "Small Scale Trading Activity",
      value: "ts",
      command: () => {
        setCurrentPage(0);
        setUrl("&industry=ts");
        setNewUrl("&industry=ts");
        setIsSearch(false);
        setCategory("ts");
      },
    },
    {
      label: "Large Scale Manufacturing Activity",
      value: "ml",
      command: () => {
        setCurrentPage(0);
        setUrl("&industry=ml");
        setNewUrl("&industry=ml");
        setIsSearch(false);
        setCategory("ml");
      },
    },
    {
      label: "Large Scale Service Activity",
      value: "sl",
      command: () => {
        setCurrentPage(0);
        setUrl("&industry=sl");
        setNewUrl("&industry=sl");
        setIsSearch(false);
        setCategory("sl");
      },
    },
    {
      label: "Association",
      value: "as",
      command: () => {
        setCurrentPage(0);
        setUrl("&industry=as");
        setNewUrl("&industry=as");
        setIsSearch(false);
        setCategory("as");
      },
    },
    {
      label: "Small Scale Service Activity",
      value: "ss",
      command: () => {
        setCurrentPage(0);
        setUrl("&industry=ss");
        setNewUrl("&industry=ss");
        setIsSearch(false);
        setCategory("ss");
      },
    },
    {
      label: "Large Scale Trading Activity",
      value: "tl",
      command: () => {
        setCurrentPage(0);
        setUrl("&industry=tl");
        setNewUrl("&industry=tl");
        setIsSearch(false);
        setCategory("tl");
      },
    },
    {
      label: "District Chambers Of Commerce",
      value: "di",
      command: () => {
        setCurrentPage(0);
        setUrl("&industry=dc");
        setNewUrl("&industry=dc");
        setIsSearch(false);
        setCategory("di");
      },
    },
    {
      label: "Profession",
      value: "pr",
      command: () => {
        setCurrentPage(0);
        setUrl("&industry=pr");
        setNewUrl("&industry=pr");
        setIsSearch(false);
        setCategory("pr");
      },
    },
  ];
  const memberType = [
    {
      label: "Ordinary",
      value: "ordinary",
      command: () => {
        setCurrentPage(0);
        let str = "";
        if (url !== null) {
          str += url + "&memberCategory=ordinary";
          setNewUrl(str);
        } else {
          setNewUrl("&memberCategory=ordinary");
        }
        setIsSearch(false);
        setMemberTypes("ordinary");
      },
    },
    {
      label: "Patron",
      value: "patron",
      command: () => {
        setCurrentPage(0);
        let str = "";
        if (url !== null) {
          str += url + "&memberCategory=patron";
          setNewUrl(str);
        } else {
          setNewUrl("&memberCategory=patron");
        }
        setIsSearch(false);
        setMemberTypes("patron");
      },
    },
  ];
  const memberOption = [
    {
      label: "All Members",
      value: "all",
      command: () => {
        setMember("all");
      },
    },
    {
      label: "Old Members",
      value: "old",
      command: () => {
        setMember("old");
      },
    },
    {
      label: "New Member",
      value: "new",
      command: () => {
        setMember("new");
      },
    },
  ];
  const list = [
    {
      label: "Download as PDF",
      icon: "pi pi-arrow-down",
      command: () => {
        getAlldata(totalRecords, "print");
      },
    },
    {
      label: "Download as Excel",
      icon: "pi pi-arrow-down",
      command: () => {
        getAlldata(totalRecords, "excel");
      },
    },
  ];

  const onBasicPageChange = (event) => {
    console.log("event", event);
    setBasicFirst(event.first);
    setBasicRows(event.rows);
    setCurrentPage(event.page);
  };
  const approvalStatus = (rowData) => {
    if (rowData.status === "APPROVED") {
      return (
        <span
          style={{
            backgroundColor: "#B3E5FC",
            color: "#23547B",
            textTransform: "uppercase",
            padding: ".25em 1rem",
            borderRadius: "3px",
            fontWeight: "700",
            letterSpacing: ".3px",
          }}
        >
          {rowData.status}
        </span>
      );
    } else if (rowData.status === "REJECTED") {
      return (
        <span
          style={{
            backgroundColor: "#ECCFFF",
            color: "#694382",
            textTransform: "uppercase",
            padding: ".25em 1rem",
            borderRadius: "3px",
            fontWeight: "700",
            letterSpacing: ".3px",
          }}
        >
          {rowData.status}
        </span>
      );
    } else {
      return (
        <span
          style={{
            backgroundColor: "#FFD8B2",
            color: "#805B36",
            textTransform: "uppercase",
            padding: ".25em 1rem",
            borderRadius: "3px",
            fontWeight: "700",
            letterSpacing: ".3px",
          }}
        >
          {rowData.status}
        </span>
      );
    }
  };
  const showSuccess = (message) => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: message,
      life: 3000,
    });
  };
  const showError = (message) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: message,
      life: 3000,
    });
  };

  const renderHeader = () => {
    return (
      // <div className="flex justify-content-between align-items-center flex-wrap gap-3">
      <div className="">
        <div
          className="flex align-items-center flex-wrap justify-content-between gap-3"
          style={{ marginBottom: "10px" }}
        >
          <div className="flex align-items-center flex-wrap gap-3">
            <h3>Approved Members({approvedMemberCount})</h3>
          </div>
          <h3>All Members : {allMemberCount}</h3>
        </div>
        <Toast ref={toast}></Toast>

        <div className="flex align-items-center flex-wrap justify-content-between gap-3">
          <div className="flex align-items-center flex-wrap gap-3">
            <SplitButton
              label="Membership Category"
              icon="pi pi-filter"
              onClick={() => {}}
              model={items}
            ></SplitButton>
            <SplitButton
              label="Member Type"
              icon="pi pi-filter"
              onClick={() => {}}
              model={memberType}
            ></SplitButton>
            <SplitButton
              label={`${
                member.charAt(0).toUpperCase() + member.slice(1)
              } Members`}
              icon="pi pi-filter"
              onClick={() => {}}
              model={memberOption}
            ></SplitButton>
          </div>
          <div className="flex align-items-center flex-wrap gap-3">
            <Button
              label="Download"
              icon="pi pi-arrow-down"
              loading={isLoading2}
              onClick={() => {
                getAlldata(totalRecords, "print");
              }}
            ></Button>
          </div>
        </div>
      </div>
    );
  };

  const viewApplicationField = (data) => {
    return (
      <Button
        onClick={() => {
          console.log("Button", data);
          navigate("/userManagement/memberDetails", {
            state: data.member_id,
          });
        }}
        className="p-button-primary w-7rem"
        label="View"
      ></Button>
    );
  };

  const memberTag = (data) => {
    return (
      <div className="capitalize cursor-pointer">{data.member_category}</div>
    );
  };
  const memIdNo = (data) => {
    return <div className="capitalize">{data?.MEM_id_no}</div>;
  };
  const NameOrg = (data) => {
    return <div>{data.name_of_org}</div>;
  };
  const firstName = (data) => {
    return <div>{data.first_name}</div>;
  };
  const phoneNumber = (data) => {
    return <div>{data.mobile}</div>;
  };
  const memberCat = (data) => {
    return <div>{data.membership_category}</div>;
  };
  const email = (data) => {
    return <div className="capitalize">{data.user.email}</div>;
  };
  const header = renderHeader();
  return (
    <div>
      <div className="m-3">
        <div className="card">
          <DataTable
            value={memberData}
            header={header}
            showGridlines
            loading={isLoading2}
          >
            <Column field="MEM_id_no" header="Membership Id"></Column>
            <Column field={NameOrg} header="Name of Organization"></Column>
            <Column field={firstName} header="First Name"></Column>
            <Column field={phoneNumber} header="Mobile"></Column>
            <Column field={memberCat} header="Membership Category"></Column>
            <Column field={memberTag} header="Member Tag"></Column>
            <Column field={email} header="Email"></Column>
            <Column field={viewApplicationField} header=" Application"></Column>
          </DataTable>
          <Paginator
            first={basicFirst}
            totalRecords={total}
            rows={basicRows}
            onPageChange={onBasicPageChange}
          ></Paginator>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <div ref={memberRef}>
          <div>
            <table
              style={{
                // border: "1px solid black",
                borderCollapse: "collapse",
              }}
            >
              <thead>
                <tr className="custom-header-table">
                  <td colSpan={5}>
                    <span className="print-header">
                      {memberTypes.toUpperCase()} {cat[category]}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th
                    style={{
                      border: "1px solid black",
                      padding: "8px",
                      maxWidth: "10mm",
                    }}
                  >
                    Sl. No.
                  </th>
                  <th
                    style={{
                      border: "1px solid black",
                      padding: "8px",
                      maxWidth: "70mm",
                    }}
                  >
                    Name & Address
                  </th>
                  <th
                    style={{
                      border: "1px solid black",
                      padding: "8px",
                      maxWidth: "40mm",
                    }}
                  >
                    ID. No. Business
                  </th>
                  <th
                    style={{
                      border: "1px solid black",
                      padding: "8px",
                      maxWidth: "50mm",
                      minWidth: "50mm",
                    }}
                  >
                    Representative / s
                  </th>

                  <th
                    style={{
                      border: "1px solid black",
                      padding: "8px",
                      maxWidth: "40mm",
                    }}
                  >
                    Photos
                  </th>
                </tr>
              </thead>
              <tbody>
                {excelData?.map((val, index) => (
                  <>
                    <tr key={index} className="page-break-directory">
                      <td
                        style={{
                          border: "1px solid black",
                          padding: "8px",
                          maxWidth: "10mm",
                          verticalAlign: "top",
                        }}
                      >
                        <p style={{ textAlign: "center", fontSize: "10px" }}>
                          {index + 1}
                        </p>
                      </td>
                      <td
                        style={{
                          border: "1px solid black",
                          padding: "8px",
                          maxWidth: "70mm",
                          wordWrap: "break-word",
                          fontSize: "10px",
                        }}
                      >
                        <p>{val.name_of_org}</p>
                        <p>{val?.address}</p>
                        <p>
                          {val?.city} - {val?.pincode}
                        </p>
                        <br />
                        <p>MOBILE:{val?.mobile}</p>
                        <p>Email:{val?.email}</p>
                      </td>
                      <td
                        style={{
                          border: "1px solid black",
                          padding: "8px",
                          textAlign: "center",
                          maxWidth: "40mm",
                          fontSize: "10px",
                          wordWrap: "break-word",
                        }}
                      >
                        <p>{val.MEM_id_no}</p>
                        <br />

                        <p>{val.description}</p>
                        <br />

                        <p>
                          DOA
                          <br />{" "}
                          {moment(val?.date_of_admission).format("DD/MM/YYYY")}
                        </p>
                      </td>
                      <td
                        style={{
                          border: "1px solid black",
                          padding: "8px",
                          textAlign: "center",
                          maxWidth: "50mm",
                          fontSize: "10px",
                          minWidth: "50mm",
                        }}
                      >
                        {val?.media?.length >= 1 ? (
                          <div>
                            <p>{val.name_of_representative1}</p>
                            <p>
                              {!!val.designation_of_representative1
                                ? `(${val.designation_of_representative1})`
                                : null}
                            </p>
                          </div>
                        ) : null}
                        <div
                          style={{
                            marginBottom:
                              val?.media?.length === 1 ? "80px" : "0px",
                          }}
                        />
                        <div
                          style={{
                            marginTop: !!val.designation_of_representative2
                              ? "10px"
                              : "40px",
                          }}
                        >
                          {val?.media?.length >= 2 ? (
                            <div>
                              <p>{val.name_of_representative2}</p>
                              <p>
                                {!!val.designation_of_representative2
                                  ? `(${val.designation_of_representative2})`
                                  : null}
                              </p>
                            </div>
                          ) : null}
                        </div>

                        <div
                          style={{
                            marginTop: !!val.designation_of_representative3
                              ? "10px"
                              : "40px",
                          }}
                        >
                          {val?.media?.length >= 3 ? (
                            <div>
                              <p>{val.name_of_representative3}</p>
                              <p>
                                {!!val.designation_of_representative3
                                  ? `(${val.designation_of_representative3})`
                                  : null}
                              </p>
                            </div>
                          ) : null}
                        </div>
                      </td>
                      <td
                        style={{
                          border: "1px solid black",
                          padding: "8px",
                          maxWidth: "30mm",
                        }}
                      >
                        {val?.media?.map((item, index) => (
                          <img
                            key={index}
                            src={item?.link}
                            width="35"
                            height="35"
                            style={{
                              marginLeft: "5mm",
                            }}
                          />
                        ))}
                        {val?.media?.length === 2 && (
                          <div style={{ height: "30px" }}></div>
                        )}
                        {val?.media?.length === 1 && (
                          <div style={{ height: "75px" }}></div>
                        )}
                        {!val?.media?.length && (
                          <div style={{ height: "120px" }}></div>
                        )}
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrintMebers;
