import React from "react";
import MenuBar from "../../../components/MenuBar";
import { Button } from "primereact/button";
import { useNavigate, useLocation } from "react-router-dom";
function MembershipDetails() {
  const location = useLocation();
  const data = location.state;
  console.log(data);
  return (
    <div>
      <MenuBar />
      <div
        style={{
          backgroundColor: "#E9E9FF",
          height: "70vh",
          display: "flex",
        }}
      >
        <div
          style={{
            width: "40%",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {data?.media?.map((item, index) => (
            <div>
              <img style={{ height: "200px" }} src={item?.link} />
            </div>
          ))}
        </div>
        <div style={{ width: "25%", marginTop: "100px" }}>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#FFCCCC",
                borderRadius: "100px",
                height: "30px",
                paddingLeft: "10px",
                paddingRight: "10px",
                width: "120px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#FF4F4F",
                  width: "25px",
                  height: "25px",
                  borderRadius: "100px",
                  marginRight: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <i style={{ color: "#FFFFFF" }} className="pi pi-star-fill"></i>
              </div>
              <div>Member</div>
            </div>
            <h2 style={{ textAlign: "left", margin: "10px 0px" }}>
              {data.user.first_name}&nbsp;{data.user.last_name}
            </h2>
            <div style={{ display: "flex", alignItems: "center" }}>
              <i
                style={{ marginRight: "10px", fontSize: "20px" }}
                className="pi pi-clock"
              ></i>
              <div>{data.membership_category}</div>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                alignItems: "start",
              }}
            >
              <i
                style={{ marginRight: "5px", fontSize: "20px" }}
                className="pi pi-map-marker"
              ></i>
              <div>{data.name_of_org}</div>
            </div>
            <div style={{}} className="p-fluid">
              <div
                style={{
                  margin: "20px 0px",
                  background: "#F5F7FA",
                  height: "100px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  borderRadius: "8px",
                }}
              >
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <i
                    style={{ marginRight: "20px", fontSize: "35px" }}
                    className="pi pi-phone"
                  ></i>
                  <strong style={{ fontSize: "25px" }}>
                    {data.user.phone_number}
                  </strong>
                </div>
                <div>Click here to reveal phone number.</div>
              </div>
              <Button
                style={{
                  background: "#2A2B8F",
                  height: "45px",
                }}
                label="Send Message"
              ></Button>
            </div>
          </div>
        </div>
        <div style={{ width: "25%", margin: "100px" }}>
          <div>
            <div style={{ display: "flex", marginBottom: "20px" }}>
              <i style={{ fontSize: "25px" }} className="pi pi-envelope"></i>
              <div style={{ marginLeft: "10px" }}>{data.user.email}</div>
            </div>
            <div style={{ display: "flex", marginBottom: "20px" }}>
              <i style={{ fontSize: "25px" }} className="pi pi-map-marker"></i>
              <div style={{ marginLeft: "10px" }}>{data.address}</div>
            </div>
            <div style={{ display: "flex", marginBottom: "20px" }}>
              <i style={{ fontSize: "25px" }} className="pi pi-globe"></i>
              <div style={{ marginLeft: "10px" }}>{data.website}</div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <div>Years of Estalishment: {data?.year_of_estd}</div>
            <div>Anual Turnover: {data?.annual_turnover}</div>
            <div>Authorized Capital: {data?.authorized_capital}</div>
            <div>Paid-up Capital: {data?.paid_up_capital}</div>
            <div>Product Description: {data?.product_desc}</div>
            <div>GST: {data?.GSTIN}</div>
            <div>PAN: {data?.PAN}</div>
          </div>

          <div style={{ marginTop: "30px" }}>
            <div style={{ display: "flex" }}>
              <i
                style={{ fontSize: "20px", marginRight: "10px" }}
                className="pi pi-share-alt"
              ></i>
              <div>Share Ads</div>
            </div>
            <div style={{ display: "flex" }}>
              <i
                style={{
                  marginTop: "20px",
                  marginRight: "20px",
                  fontSize: "30px",
                }}
                className="pi pi-whatsapp"
              ></i>
              <i
                style={{
                  marginTop: "20px",
                  marginRight: "20px",
                  fontSize: "30px",
                }}
                className="pi pi-facebook"
              ></i>
              <i
                style={{
                  marginTop: "20px",
                  marginRight: "20px",
                  fontSize: "30px",
                }}
                className="pi pi-twitter"
              ></i>
              <i
                style={{
                  marginTop: "20px",
                  marginRight: "20px",
                  fontSize: "30px",
                }}
                className="pi pi-linkedin"
              ></i>
              <i
                style={{
                  marginTop: "20px",
                  marginRight: "20px",
                  fontSize: "30px",
                }}
                className="pi pi-link"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div style={{ margin: "40px 60px", height: "20vh", textAlign: "left" }}>
          <h2> Description</h2>
          <div>{data.product_desc}</div>
        </div>
      </div>
    </div>
  );
}

export default MembershipDetails;
