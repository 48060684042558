import moment from "moment";
import React from "react";
import Logo from "../../../../../../assets/logo1.png";
import Signature from "../../../../../../assets/signature.png";
import { Text, View, Image } from "@react-pdf/renderer";
import "./Invoice.css";
var converter = require("number-to-words");
function DownloadInvoice({ data, isDuplicate ,second}) {
  console.log(data, 'dattatattata');
  console.log(data.id, 'checkingggggggggggg');
  console.log(data?.payment_details[0]?.bank_name, 'checkingggggggggggg');
  const getYear = (val) => {
    // const date = new Date(val);
    // const year = date.getFullYear();
    return val;
  };
  const getNextYear = (val) => {
    // const date = new Date(val);
    const year = Number(val) + 1;
    return year;
  };
  const getCurrency = (val) => {
    const curr = new Intl.NumberFormat().format(val);
    const total = curr.toString() + ".00";
    return total;
  };
  console.log("invoiceData", data);
  return (
    <View
      style={{
        width: "100%",
        padding:"20px 10px",
        marginTop:second?"0":"0"
      }}
    >
      <View
        style={{
          border: "1px solid #000000",
          display: "flex",flexDirection:"row",
          flexDirection:"row",
          justifyContent:"space-evenly",
          borderRadius: "5px",
          padding: "3px 10px",
        }}
      >
        <View>
        <Image src={Logo} alt="Logo" style={{ width: "60px" }} />
          <Text style={{
            fontSize:"10px",
            textAlign: "left",
          }}>PAN No.: AAACF2404N</Text>
          <Text style={{
            fontSize:"10px",
            textAlign: "left",
          }}>GSTIN: 29AAACF2404N1ZM</Text>
        </View>
        <View style={{display:"flex",textAlign:"center",alignItems:"center"}}>
          <Text
            style={{
              fontSize: "13px",
              textAlign: "center",
              fontWeight: "600",
            }}
          >
            FEDERATION OF KARNATAKA CHAMBERS OF COMMERCE & INDUSTRY
          </Text>
          <Text 
           style={{
            fontSize: "14px",
            textAlign: "center",
            marginTop:"2px"
          }}>Federation House</Text>
          <Text
            style={{
              fontSize: "12px",
              textAlign: "center",
            marginTop:"2px"

            }}
          >
            Kempegowda Road, Bengaluru - 560009 | Ph: +91 080 2226 2355/56
          </Text>
          <Text style={{
            fontSize: "14px",
            textAlign: "center",
            marginTop:"2px"

          }}>e-mail:membership@fkcci.in</Text>
          <Text
            style={{
              fontSize: "16px",
              textAlign: "center",
              fontWeight: "500",
              marginTop:"2px"
            }}
          >
           {isDuplicate}
          </Text>
        </View>
        <View style={{
            marginTop:"65px",
            right:"10px",
            width:"170px"
        }}>
          <Text style={{
            fontSize:"10px",
          }}>
            CIN No: U91110KA1938NPL00024
          </Text>
        </View>
      </View>
       <View
        style={{
          border: "1px solid #000000",
          borderRadius: "5px",
          padding: "5px 20px",
        }}
      >
        <Text style={{
            fontSize: "12px",
            textAlign: "center",
          }}>Place of Supply:29-Karnataka</Text>
        <View style={{ display: "flex",flexDirection:"row", justifyContent: "space-between" }}>
          <Text style={{ fontSize: "12px" }}>
            Inv. No. : {data?.payment_details[0]?.receipt_no}
          </Text>
          <Text style={{ fontSize: "12px" }}>
            Date : {moment(data?.created_at).format("DD-MM-YYYY")}
          </Text>
        </View>
        <Text style={{
            fontSize: "10px",
            textAlign: "left",
          }}>
          Received with thanks from Messers {data?.member?.name_of_org},{" "}
          {data?.member?.address} {data?.member?.address2}{" "}
          {data?.member?.address3}
          {data?.member?.city}-{data?.member?.pincode}
        </Text>
        <View
          style={{
            display: "flex",flexDirection:"row",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <Text style={{ fontSize: "10px" }}>{data?.member?.MEM_id_no}</Text>
          <Text style={{ fontSize: "10px" ,marginLeft:'15px'}}>
            MEMBER GST NO.{data?.member?.GSTIN}
          </Text>
        </View>
        <Text style={{
            fontSize: "10px",
            textAlign: "left",
          }}>
          towards Membership Subscription for the Year{" "}
          {getYear(data?.payment_details[0]?.financial_year)}-
          {getNextYear(data?.payment_details[0]?.financial_year)}
        </Text>
        <Text style={{
            fontSize: "10px",
            textAlign: "left",
            marginLeft: "100px" 
          }} >
          Description of Service
        </Text>
        <View
          style={{
            borderTop: "1px dotted #000000",
            borderBottom: "1px dotted #000000",
            display: "flex",flexDirection:"row",
          }}
        >
          <View
            style={{
              width: "50%",
              borderRight: "1px dotted #000000",
              padding: "10px 5px",
              display: "flex",flexDirection:"row",
              justifyContent: "flex-end",
            }}
          >
            
            <View
              style={{
                display: "flex",flexDirection:"row",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "flex-end",
                marginRight: "25px",
              }}
            >
              <Text style={{
            fontSize: "10px",
            textAlign: "center",
          }}>Membership Subscription Fee :</Text>
              <Text style={{
            fontSize: "10px",
            textAlign: "center",
          }}>Admission Fee :</Text>
              <View style={{ display: "flex" ,flexDirection:"row"}}>
                {/* <Text style={{
            fontSize: "10px",
            textAlign: "center",
            marginRight: "10px"
          }}>
                  HSN CODE:999599
                </Text> */}
                <Text style={{
            fontSize: "10px",
            textAlign: "center",
          }}>HSN CODE:999599  Interest Amount :</Text>
              </View>
              <Text style={{
            fontSize: "10px",
            textAlign: "center",
          }}>Total Taxable Value :</Text>
              <Text style={{
            fontSize: "10px",
            textAlign: "center",
          }}>IGST @18% :</Text>
              <Text style={{
            fontSize: "10px",
            textAlign: "center",
          }}>SGST @9% :</Text>
              <Text style={{
            fontSize: "10px",
            textAlign: "center",
          }}>CGST @9% :</Text>
              <Text style={{
            fontSize: "10px",
            textAlign: "center",
          }}>MISC. Amount :</Text>
            </View>
            <View
              style={{
                display: "flex",flexDirection:"row",
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Text style={{
            fontSize: "10px",
            textAlign: "center",
          }}>
                {getCurrency(data?.payment_details[0]?.subscription_amount)}
              </Text>
              <Text style={{
            fontSize: "10px",
            textAlign: "center",
          }}>
                {getCurrency(data?.payment_details[0]?.admission_amount)}
              </Text>
              <Text style={{
            fontSize: "10px",
            textAlign: "center",
          }}>
                {getCurrency(data?.payment_details[0]?.interest_amount)}
              </Text>
              <Text style={{
            fontSize: "10px",
            textAlign: "center",
          }}>
                {getCurrency(data?.payment_details[0]?.taxable_amount)}
              </Text>

              <Text style={{
            fontSize: "10px",
            textAlign: "center",
          }}>
                {getCurrency(data?.payment_details[0]?.igst)}
              </Text>
              <Text style={{
            fontSize: "10px",
            textAlign: "center",
          }}>
                {getCurrency(data?.payment_details[0]?.sgst)}
              </Text>
              <Text style={{
            fontSize: "10px",
            textAlign: "center",
          }}>
                {getCurrency(data?.payment_details[0]?.cgst)}
              </Text>
              <Text style={{
            fontSize: "10px",
            textAlign: "center",
          }}>
                {getCurrency(data?.payment_details[0]?.misc_amount)}
              </Text>
            </View>
          </View>
          <View
            style={{
              width: "50%",
              padding: "10px 10px",
            }}
          >
            {data?.payment_details[0]?.payment_type === "NEFT" ? (
              <Text style={{
            fontSize: "10px",
            textAlign: "left",
          }}>
                by NEFT Transaction ID.{" "}
                {data?.payment_details[0]?.transactional_id} Date:
                {moment(data?.payment_details[0]?.date_of_payment).format(
                  "DD-MM-YYYY"
                )}
                {"\n"}
                {data?.payment_details[0]?.bank_name}
              </Text>
            ) : (
              <Text style={{
            fontSize: "10px",
            textAlign: "left",
          }}>
                by Cheque bearing No. {data?.payment_details[0]?.cheque_number}{" "}
                Date:
                {moment(data?.payment_details[0]?.date_of_payment).format(
                  "DD-MM-YYYY"
                )}
                {"\n"}
                {data?.payment_details[0]?.bank_name}
              </Text>
            )}
          </View>
        </View>
        <View
          style={{
            display: "flex",flexDirection:"row",
            justifyContent: "space-between",
            width: "70%",
          }}
        >
          <Text style={{
            fontSize: "12px",
            textAlign: "center",
            fontWeight: "700"
          }}>
            Rs.{getCurrency(data?.payment_details[0]?.paid_amount)}
          </Text>
          <Text style={{
            fontSize: "12px",
            textAlign: "center",
          }}>Tax Payable on Reverse Charge: No</Text>
        </View>
        <Text  style={{
            fontSize: "12px",
            textAlign: "left",
          }}>
          a Sum of Ruppes:{" "}
          {converter.toWords(data?.payment_details[0]?.paid_amount ?? 0)} Only
        </Text>
        <View
          style={{
            display: "flex",flexDirection:"row",
            justifyContent: "space-between",
            alignItems: "flex-end",
            marginTop: "-40px",
          }}
        >
          <Text style={{
            fontSize: "12px",
            textAlign: "left",
          }}>
            Remarks: {data?.payment_details[0]?.remark}
          </Text>
          <Image
            src={Signature}
            alt="Logo"
            style={{
              height: "70px",
              marginRight: "40px",
              marginBottom:"-10px",
              marginTop:"10px"
            }}
          />
        </View>
        <View
          style={{
            display: "flex",flexDirection:"row",
            justifyContent: "space-between",
            marginTop: "5px",
          }}
        >
          <Text style={{
            fontSize: "12px",
            textAlign: "center",
          }}>*Cheque Subject to Realisation</Text>
          <Text style={{
            fontSize: "12px",
            textAlign: "center",
          }}>Authorised Signatory</Text>
        </View>
      </View>  
    </View>
  );
}

export default DownloadInvoice;
