import React, { useState } from "react";
import { Menubar } from "primereact/menubar";
import logo from "../assets/logo.png";
import { Divider } from "primereact/divider";
import { useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { useEffect } from "react";
import { getUserProfile } from "../apis/api";

function MenuBar() {
  const navigate = useNavigate();
  const start = <img style={{ height: "100%" }} src={logo}></img>;
  const [position, setPosition] = useState("center");
  const [displayPosition, setDisplayPosition] = useState(false);
  const [displayPosition2, setDisplayPosition2] = useState(false);
  const [userData, setUserData] = useState(null);

  const dialogFuncMap = {
    displayPosition: setDisplayPosition,
  };
  const dialogFuncMap2 = {
    displayPosition2: setDisplayPosition2,
  };
  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };
  const onClick2 = (name, position) => {
    dialogFuncMap2[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };
  const onHide2 = (name) => {
    dialogFuncMap2[`${name}`](false);
  };
  const renderFooter = (name) => {
    return <div></div>;
  };
  useEffect(() => {
    getUserProfile()
      .then((res) => {
        // console.log("res", res);
        setUserData(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);
  return (
    <div
      style={{
        height: "100px",
        background: "#091A32",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div style={{ margin: "10px 50px" }}>{start}</div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", fontSize: "15px" }}>
          <div
            onClick={() => navigate("/home")}
            style={{
              textAlign: "center",
              color: "white",
              margin: "0px 25px",
              cursor: "pointer",
            }}
          >
            <i className="pi pi-home"></i>
            <div>Home</div>
          </div>
          <div
            onClick={() => {}}
            style={{
              textAlign: "center",
              color: "white",
              margin: "0px 25px",
              cursor: "pointer",
            }}
          >
            <i className="pi pi-envelope"></i>
            <div>Message</div>
          </div>
          <div
            onClick={() => navigate("/allEvents")}
            style={{
              textAlign: "center",
              color: "white",
              margin: "0px 25px",
              cursor: "pointer",
            }}
          >
            <i className="pi pi-search"></i>
            <div>Events</div>
          </div>
          <div
            style={{
              textAlign: "center",
              color: "white",
              margin: "0px 25px",
              cursor: "pointer",
            }}
          >
            <i className="pi pi-user"></i>
            <div
              onClick={() => {
                navigate("/membershipDescription");
              }}
            >
              Membership Details
            </div>
          </div>
          <div
            style={{
              textAlign: "center",
              color: "white",
              margin: "0px 25px",
              cursor: "pointer",
            }}
            // onClick={() => {
            //   // navigate("/userProfile");
            //   setDisplayPosition2(true);
            // }}
            onClick={() => onClick2("displayPosition2", "top-right")}
          >
            <i className="pi pi-user-edit"></i>
            <div>Account</div>
          </div>
          <div
            onClick={() => onClick("displayPosition", "top-right")}
            style={{
              textAlign: "center",
              color: "white",
              margin: "0px 25px",
              cursor: "pointer",
            }}
          >
            <i className="pi pi-bell"></i>
            <div>Notifications</div>
          </div>
          <div
            onClick={() => {
              window.localStorage.clear();
              navigate("/");
            }}
            style={{
              textAlign: "center",
              color: "white",
              margin: "0px 25px",
              cursor: "pointer",
            }}
          >
            <i className="pi pi-sign-out"></i>
            <div>Logout</div>
          </div>
        </div>
      </div>
      <Dialog
        header="Notifications"
        visible={displayPosition}
        position={position}
        modal
        style={{ width: "25vw", marginTop: "100px" }}
        onHide={() => onHide("displayPosition")}
        draggable={false}
        resizable={false}
      >
        <p className="m-0"></p>
      </Dialog>
      <Dialog
        header="User Profile"
        visible={displayPosition2}
        position={position}
        footer={renderFooter}
        modal
        style={{ width: "25vw", marginTop: "100px", padding: "20px" }}
        onHide={() => onHide2("displayPosition2")}
        draggable={false}
        resizable={false}
      >
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                backgroundColor: "#767E94",
                height: "56px",
                width: "56px",
                borderRadius: "30px",
                marginRight: "30px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={
                  userData !== null &&
                  userData?.member[0]?.profile_photo !== null
                    ? userData?.member[0]?.profile_photo
                    : "https://cdn-icons-png.flaticon.com/512/17/17004.png"
                }
                alt="user"
                style={{ height: "50px" }}
              />
            </div>
            <div>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#191F33",
                }}
              >
                {userData?.first_name}
              </div>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#767E94",
                }}
              >
                Member
              </div>
            </div>
          </div>
          <div
            style={{
              fontSize: "12px",
              fontWeight: "600",
              color: "#939AAD",
              marginTop: "24px",
            }}
          >
            ACCOUNTS
          </div>
          <div
            onClick={() => {
              navigate("/userProfile", { state: userData });
            }}
            style={{ marginTop: "10px", display: "flex", alignItems: "center" }}
          >
            <i style={{ color: "#767E94" }} className="pi pi-microsoft"></i>
            <div
              style={{
                fontSize: "12px",
                fontWeight: "600",
                color: "#767E94",
                marginLeft: "15px",
              }}
            >
              Profile Overview
            </div>
          </div>
          <div
            onClick={() => {
              navigate("/membershipDescription");
            }}
            style={{ marginTop: "10px", display: "flex", alignItems: "center" }}
          >
            <i style={{ color: "#767E94" }} className="pi pi-microsoft"></i>
            <div
              style={{
                fontSize: "12px",
                fontWeight: "600",
                color: "#767E94",
                marginLeft: "15px",
              }}
            >
              Membership Details
            </div>
          </div>
          <div
            onClick={() => {
              window.localStorage.clear();
              navigate("/");
            }}
            style={{ marginTop: "10px", display: "flex", alignItems: "center" }}
          >
            <i style={{ color: "#767E94" }} className="pi pi-sign-out"></i>
            <div
              style={{
                fontSize: "12px",
                fontWeight: "600",
                color: "#767E94",
                marginLeft: "15px",
              }}
            >
              Sign Out
            </div>
          </div>
          <div
            style={{
              fontSize: "12px",
              fontWeight: "600",
              color: "#939AAD",
              marginTop: "24px",
            }}
          >
            OTHER
          </div>
          <div
            style={{ marginTop: "10px", display: "flex", alignItems: "center" }}
          >
            <i style={{ color: "#767E94" }} className="pi pi-info-circle"></i>
            <div
              style={{
                fontSize: "12px",
                fontWeight: "600",
                color: "#767E94",
                marginLeft: "15px",
              }}
            >
              About
            </div>
          </div>
          <div
            onClick={() => {
              navigate("/contactUs");
            }}
            style={{ marginTop: "10px", display: "flex", alignItems: "center" }}
          >
            <i style={{ color: "#767E94" }} className="pi pi-phone"></i>
            <div
              style={{
                fontSize: "12px",
                fontWeight: "600",
                color: "#767E94",
                marginLeft: "15px",
              }}
            >
              Contact
            </div>
          </div>
          <div
            style={{ marginTop: "10px", display: "flex", alignItems: "center" }}
          >
            <i
              style={{ color: "#767E94" }}
              className="pi pi-question-circle"
            ></i>
            <div
              style={{
                fontSize: "12px",
                fontWeight: "600",
                color: "#767E94",
                marginLeft: "15px",
              }}
            >
              FAQs
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default MenuBar;
