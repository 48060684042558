import React, { useRef, useState } from "react";
import MenuBar from "../../../components/MenuBar";
import AdminMenuBar from "../../admin/AdminMenuBar";
import events from "../../../assets/events.png";
import { Button } from "primereact/button";
import Footer from "../../../components/Footer";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useNavigate, useLocation } from "react-router-dom";
import { Toast } from "primereact/toast";
import moment from "moment";

import {
  getAllEvents,
  getEventDetails,
  registerEvent,
  varifyEventRegisteration,
} from "../../../apis/api";
function EventsDetail() {
  const userType = window.localStorage.getItem("userType");
  const location = useLocation();
  const data = location.state;
  console.log(data);
  const toast = useRef(null);
  const [displayResponsive, setDisplayResponsive] = useState(false);
  const [position, setPosition] = useState("center");
  const dialogFuncMap = {
    displayResponsive: setDisplayResponsive,
  };
  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };

  const header = () => {
    return (
      <div>
        <div
          style={{
            textAlign: "center",
            fontSize: "30px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <i
            style={{ fontSize: "40px", marginRight: "20px" }}
            className="pi pi-user"
          ></i>
          <div>Register Now</div>
        </div>
      </div>
    );
  };
  const eventRegister = async () => {
    registerEvent(location?.state?.id)
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .then((res) => {
        console.log("res", res?.data);
        if (typeof res?.data === typeof {}) {
          displayRazorpay(res?.data, res?.data?.order_id);
        } else {
          if (res?.data === "Successfully subscribed to the event") {
            toast.current.show({
              severity: "success",
              summary: "Subcribed successfully",
              detail: "Successfully subscribed to the event",
              life: 3000,
            });
          } else if (res?.data === "Already Subscribed") {
            toast.current.show({
              severity: "success",
              summary: "Already subcribed!",
              detail: "Already Subscribed for this event!",
              life: 3000,
            });
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const loadScript = (src) => {
    return new Promise((resovle) => {
      const script = document.createElement("script");
      script.src = src;

      script.onload = () => {
        resovle(true);
      };

      script.onerror = () => {
        resovle(false);
      };

      document.body.appendChild(script);
    });
  };

  const displayRazorpay = async (data, id) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("You are offline... Failed to load Razorpay SDK");
      return;
    }
    // console.log(finalAmount);
    const options = {
      order_id: data?.id,
      key: "rzp_test_7orho26dPSCJZE",
      currency: data?.currency,
      amount: data?.amount_due,
      name: "FKCCI",
      description: "Thanks for purchasing",

      handler: function (response) {
        const val = {
          razorpay_order_id: response?.razorpay_order_id,
          razorpay_payment_id: response?.razorpay_payment_id,
          razorpay_signature: response?.razorpay_signature,
        };
        varifyEventRegisteration(id, val)
          .then((res) => {
            console.log("res", res);
            if (res.status === 200) {
              toast.current.show({
                severity: "success",
                summary: "Payment Succefull!",
                detail: "Registeration for event is done",
                life: 3000,
              });
            } else {
              toast.current.show({
                severity: "error",
                summary: "Payment Failed!",
                detail: "couldn't register for event",
                life: 3000,
              });
            }
          })
          .catch((err) => {
            console.log("res", err);
            toast.current.show({
              severity: "error",
              summary: "Payment Failed!",
              detail: "couldn't register for event",
              life: 3000,
            });
          });
        console.log("res", response.razorpay_payment_id, response);
      },
      prefill: {
        name: "FKCCi",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };
  return (
    <div style={{ height: "100%" }}>
      <Toast ref={toast} />
      {userType === "FKCCI Member" ? <MenuBar /> : <AdminMenuBar />}
      <img alt="" style={{ width: "100%" }} src={events}></img>
      <div
        className="flex border-round "
        style={{
          display: "flex",
          flexDirection: "row",
          // justifyContent: "space-between",
        }}
      >
        {/* <div className="w-4  font-bold p-3 flex align-items-center justify-content-center">
          <img
            className="h-20rem"
            alt=""
            src="https://png.pngtree.com/png-vector/20190804/ourlarge/pngtree-map-location-pin-world-abstract-flat-color-icon-template-png-image_1650126.jpg"
          ></img>
        </div> */}
        <div className="w-8 p-3" style={{ width: "30%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#25C063",
              borderRadius: "100px",
              height: "30px",
              paddingLeft: "10px",
              paddingRight: "10px",
              width: "100px",
            }}
          >
            <div
              style={{
                backgroundColor: "#2A2B8F",
                width: "25px",
                height: "25px",
                borderRadius: "100px",
                marginRight: "5px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <i style={{ color: "#FFFFFF" }} className="pi pi-star-fill"></i>
            </div>
            <div>Event</div>
          </div>
          <div className="text-left">
            <h2 className="m-2">{data.event_name}</h2>
            <div className="m-1" style={{ display: "flex" }}>
              <div className="flex">
                <i
                  style={{ marginRight: "5px", fontSize: "20px" }}
                  className="pi pi-clock m-1"
                ></i>
                <p className="m-1" style={{ marginTop: "-10px" }}>
                  {moment(data.event_start).format("DD MMM YYYY")}{" "}
                  {moment(data.event_start).format("h:mm a")} to{" "}
                  {moment(data.event_end).format("DD MMM YYYY")}{" "}
                  {moment(data.event_end).format("h:mm a")}
                </p>
              </div>
              <div className="flex justify-content-center align-items-center mx-2">
                <i
                  style={{ marginRight: "5px", fontSize: "20px" }}
                  className="pi pi-map-marker m-1"
                ></i>
                <div className="m-1">{data.location}</div>
              </div>
            </div>
            <div style={{}} className="p-fluid">
              <div
                className="w-6 flex align-items-center justify-content-center bg-gray-200"
                style={{
                  margin: "20px 0px",
                  height: "80px",
                  borderRadius: "10px",
                }}
              >
                <h3 style={{ margin: "20px" }}>For Members and Non Members</h3>
              </div>
              {userType !== "FKCCI Member" ? (
                <div></div>
              ) : (
                <div>
                  <Button
                    className="w-6"
                    onClick={() => eventRegister()}
                    style={{
                      background: "#2A2B8F",
                    }}
                    label="Register Now"
                  ></Button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            width: "70%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            paddingTop: "20px",
            paddingBottom: "30px",
          }}
        >
          <h2>Discription</h2>
          <div
            style={{
              textAlign: "left",
              lineHeight: "24px",
              marginTop: "30px",
            }}
          >
            {data?.event_description}
          </div>
        </div>
      </div>

      <Dialog
        header={header}
        visible={displayResponsive}
        onHide={() => onHide("displayResponsive")}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "50vw" }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ width: "50%" }} className="p-fluid">
            <div style={{ marginBottom: "20px" }}>
              <InputText placeholder="Full Name"></InputText>
            </div>
            <div style={{ marginBottom: "20px" }}>
              <InputText placeholder="Email Address"></InputText>
            </div>
            <div style={{ marginBottom: "20px" }}>
              <InputText placeholder="Member or Non Member"></InputText>
            </div>
            <div style={{ marginBottom: "20px" }}>
              <InputText placeholder="Phone Number"></InputText>
            </div>
            <Button
              style={{
                background: "#2A2B8F",
                height: "45px",
              }}
              label="Register Now"
            ></Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default EventsDetail;
