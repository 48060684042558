import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { register } from "../../apis/api";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { FileUpload } from "primereact/fileupload";
import AdminMenuBar from "./AdminMenuBar";
export const UpdateMembershipForm = () => {
  const [displayResponsive, setDisplayResponsive] = useState(false);
  const [position, setPosition] = useState("center");

  const dialogFuncMap = {
    displayResponsive: setDisplayResponsive,
  };
  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };
  const formik = useFormik({
    initialValues: {
      organizationName: "",
      email: "",
      address: "",
      phone: "",
      fax: "",
      mobile: "",
      website: "",
      yearOfEstablishment: "",
      natureOfBusiness: "",
      sizeofBusiness: "",
      membershipCatagory: "",
      annualTurnOver: "",
      authorizedCapital: "",
      paidUpCapital: "",
      domestic: "",
      exports: "",
      total: "",
      productDescription: "",
      gstin: "",
      pan: "",
      ieCode: "",
      msmeRegNo: "",
      compRegNo: "",
      profession: "",
      representative1Name: "",
      representative2Name: "",
      representative3Name: "",
      designation1: "",
      designation2: "",
      designation3: "",
    },
    validate: (data) => {
      let errors = {};
      // if (!data.organizationName) {
      //   errors.organizationName = "This Field is Required.";
      // }
      // if (!data.address) {
      //   errors.address = "This Field is Required.";
      // }
      // if (!data.phone) {
      //   errors.phone = "This Field is Required.";
      // }
      // if (!data.fax) {
      //   errors.fax = "This Field is Required.";
      // }
      // if (!data.website) {
      //   errors.website = "This Field is Required.";
      // }
      // if (!data.mobile) {
      //   errors.mobile = "This Field is Required.";
      // }
      // if (!data.yearOfEstablishment) {
      //   errors.yearOfEstablishment = "This Field is Required.";
      // }
      // if (!data.natureOfBusiness) {
      //   errors.natureOfBusiness = "This Field is Required.";
      // }
      // if (!data.sizeofBusiness) {
      //   errors.sizeofBusiness = "This Field is Required.";
      // }
      // if (!data.membershipCatagory) {
      //   errors.membershipCatagory = "This Field is Required.";
      // }
      // if (!data.annualTurnOver) {
      //   errors.annualTurnOver = "This Field is Required.";
      // }
      // if (!data.authorizedCapital) {
      //   errors.authorizedCapital = "This Field is Required.";
      // }
      // if (!data.paidUpCapital) {
      //   errors.paidUpCapital = "This Field is Required.";
      // }
      // if (!data.domestic) {
      //   errors.domestic = "This Field is Required.";
      // }
      // if (!data.exports) {
      //   errors.exports = "This Field is Required";
      // }
      // if (!data.total) {
      //   errors.total = "This Field is Required";
      // }
      // if (!data.productDescription) {
      //   errors.productDescription = "This Field is Required";
      // }
      // if (!data.gstin) {
      //   errors.gstin = "This Field is Required";
      // }
      // if (!data.pan) {
      //   errors.pan = "This Field is Required";
      // }
      // if (!data.ieCode) {
      //   errors.ieCode = "This Field is Required";
      // }
      // if (!data.msmeRegNo) {
      //   errors.msmeRegNo = "This Field is Required";
      // }
      // if (!data.compRegNo) {
      //   errors.compRegNo = "This Field is Required";
      // }
      // if (!data.profession) {
      //   errors.profession = "This Field is Required";
      // }
      // if (!data.representative1Name) {
      //   errors.representative1Name = "This Field is Required";
      // }
      // if (!data.representative2Name) {
      //   errors.representative2Name = "This Field is Required";
      // }
      // if (!data.representative3Name) {
      //   errors.representative3Name = "This Field is Required";
      // }
      // if (!data.designation1) {
      //   errors.designation1 = "This Field is Required";
      // }
      // if (!data.designation2) {
      //   errors.designation2 = "This Field is Required";
      // }
      // if (!data.designation3) {
      //   errors.designation3 = "This Field is Required";
      // }

      return errors;
    },
    onSubmit: (data) => {
      const registerData = {
        first_name: "Bruce",
        last_name: "Wayne",
        phone_number: data.phone,
        email: data.email,
        encrypted_password: "password",
        status: "APPROVED",
        sub_status: "in process",
        MEM_id_no: "mem2321",
        payment_type: "cheque",
        payment_type_no: "chq232424",
        payment_on_date: "2022-05-23 22:38:27.577283-07",
        payment_drawn_date: "2022-05-23 22:38:27.577283-07",
        admission_fee: "432323",
        subscription_period: "10",
        name_of_org: data.organizationName,
        address: data.address,
        mobile: data.mobile,
        website: data.website,
        fax: data.fax,
        year_of_estd: data.yearOfEstablishment,
        member_category: data.membershipCatagory,
        membership_category: data.membershipCatagory,
        annual_turnover: data.annualTurnOver,
        authorized_capital: data.authorizedCapital,
        paid_up_capital: data.paidUpCapital,
        product_desc: data.productDescription,
        domestic_sales: data.domestic,
        exports_sales: data.exports,
        total_sales: data.total,
        GSTIN: data.gstin,
        PAN: data.pan,
        IE_code: data.ieCode,
        MSME_registration_no: data.msmeRegNo,
        company_registration_no: data.compRegNo,
        institute_membership_no: data.profession,
        name_of_representative1: data.representative1Name,
        name_of_representative2: data.representative2Name,
        name_of_representative3: data.representative3Name,
        designation_of_representative1: data.designation1,
        designation_of_representative2: data.designation2,
        designation_of_representative3: data.designation3,
        proposed_by: "Alfred Pennyworth",
        proposed_by_id_no: "WE78528",
        seconded_by: "Mr Fox",
        seconded_by_id_no: "WE95631",
      };
      register(registerData).then((res) => console.log(res));
    },
  });
  console.log(window.localStorage.getItem("token"));
  // onFileUpload = () => {

  //   // Create an object of formData
  //   const formData = new FormData();

  //   // Update the formData object
  //   formData.append(
  //     "myFile",
  //     this.state.selectedFile,
  //     this.state.selectedFile.name
  //   );

  //   // Details of the uploaded file
  //   console.log(this.state.selectedFile);

  //   // Request made to the backend api
  //   // Send formData object
  //   axios.post("api/uploadfile", formData);
  // };

  console.log(formik.values);
  const genderOptions = ["Male", "Female", "Prefer Not to Respond"];
  const memberShipOptions = ["Ordinary", "Patron"];
  const natureOfBusiness = [
    "Manufacture",
    "Trade",
    "Service",
    "Profession",
    "Association",
  ];
  const sizeofBusiness = ["Small", "Medium/Large"];
  return (
    <div style={{}}>
      <AdminMenuBar />
      <div>
        <div
          className="title"
          style={{ textAlign: "center", margin: "40px 0px" }}
        >
          <h1>Update Member Details</h1>
        </div>
        <div className="form">
          <form onSubmit={formik.handleSubmit}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{ width: "40%", margin: "0px 20px" }}
                  className="p-fluid"
                >
                  <div className="p-field">
                    <label>Organization Name</label>
                    <InputText
                      id="organizationName"
                      value={formik.values.organizationName}
                      onChange={formik.handleChange}
                    ></InputText>
                    {formik.errors.organizationName && (
                      <div style={{ color: "red" }}>
                        {formik.errors.organizationName}
                      </div>
                    )}
                  </div>
                  <div className="p-field">
                    <label>Address</label>
                    <InputText
                      id="address"
                      value={formik.values.address}
                      onChange={formik.handleChange}
                    ></InputText>
                    {formik.errors.address && (
                      <div style={{ color: "red" }}>
                        {formik.errors.address}
                      </div>
                    )}
                  </div>
                  <div className="p-field">
                    <label>Phone</label>
                    <InputText
                      id="phone"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                    ></InputText>
                    {formik.errors.phone && (
                      <div style={{ color: "red" }}>{formik.errors.phone}</div>
                    )}
                  </div>
                  <div className="p-field">
                    <label>Fax</label>
                    <InputText
                      id="fax"
                      value={formik.values.fax}
                      onChange={formik.handleChange}
                    ></InputText>
                    {formik.errors.fax && (
                      <div style={{ color: "red" }}>{formik.errors.fax}</div>
                    )}
                  </div>
                  <div className="p-field">
                    <label>Mobile</label>
                    <InputText
                      id="mobile"
                      value={formik.values.mobile}
                      onChange={formik.handleChange}
                    ></InputText>
                    {formik.errors.mobile && (
                      <div style={{ color: "red" }}>{formik.errors.mobile}</div>
                    )}
                  </div>
                </div>
                <div
                  style={{ width: "40%", margin: "0px 40px" }}
                  className="p-fluid"
                >
                  <div className="p-field">
                    <label>Website</label>
                    <InputText
                      id="website"
                      value={formik.values.website}
                      onChange={formik.handleChange}
                    ></InputText>
                    {formik.errors.website && (
                      <div style={{ color: "red" }}>
                        {formik.errors.website}
                      </div>
                    )}
                  </div>
                  <div className="p-field">
                    <label>Year of Establishment</label>
                    <Calendar
                      id="yearOfEstablishment"
                      value={formik.values.yearOfEstablishment}
                      onChange={formik.handleChange}
                    ></Calendar>
                    {formik.errors.yearOfEstablishment && (
                      <div style={{ color: "red" }}>
                        {formik.errors.yearOfEstablishment}
                      </div>
                    )}
                  </div>
                  <div className="p-field">
                    <label>Nature of Business</label>
                    <Dropdown
                      id="natureOfBusiness"
                      value={formik.values.natureOfBusiness}
                      onChange={formik.handleChange}
                      options={natureOfBusiness}
                    ></Dropdown>
                    {formik.errors.natureOfBusiness && (
                      <div style={{ color: "red" }}>
                        {formik.errors.natureOfBusiness}
                      </div>
                    )}
                  </div>
                  <div className="p-field">
                    <label>Size of the Business</label>
                    <Dropdown
                      id="sizeofBusiness"
                      value={formik.values.sizeofBusiness}
                      onChange={formik.handleChange}
                      options={sizeofBusiness}
                    ></Dropdown>
                    {formik.errors.sizeofBusiness && (
                      <div style={{ color: "red" }}>
                        {formik.errors.sizeofBusiness}
                      </div>
                    )}
                  </div>
                  <div className="p-field">
                    <label>Membership Catagory</label>
                    <Dropdown
                      id="membershipCatagory"
                      value={formik.values.membershipCatagory}
                      onChange={formik.handleChange}
                      options={memberShipOptions}
                    ></Dropdown>
                    {formik.errors.membershipCatagory && (
                      <div style={{ color: "red" }}>
                        {formik.errors.membershipCatagory}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ margin: "10px 10px", width: "50%" }}>
                  <div style={{ textAlign: "center" }}>
                    <h3>Financial Information</h3>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                    className="p-fluid"
                  >
                    <div style={{ width: "80%" }}>
                      <div className="p-field">
                        <label htmlFor="integeronly">Annual Turnover</label>
                        <InputText
                          id="annualTurnOver"
                          value={formik.values.annualTurnOver}
                          onChange={formik.handleChange}
                        ></InputText>
                        {formik.errors.annualTurnOver && (
                          <div style={{ color: "red" }}>
                            {formik.errors.annualTurnOver}
                          </div>
                        )}
                      </div>
                      <div className="p-field">
                        <label>Authorized Capital</label>
                        <InputText
                          id="authorizedCapital"
                          value={formik.values.authorizedCapital}
                          onChange={formik.handleChange}
                        ></InputText>
                        {formik.errors.authorizedCapital && (
                          <div style={{ color: "red" }}>
                            {formik.errors.authorizedCapital}
                          </div>
                        )}
                      </div>
                      <div className="p-field">
                        <label>Paid-up Capital</label>
                        <InputText
                          id="paidUpCapital"
                          value={formik.values.paidUpCapital}
                          onChange={formik.handleChange}
                        ></InputText>
                        {formik.errors.paidUpCapital && (
                          <div style={{ color: "red" }}>
                            {formik.errors.paidUpCapital}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ margin: "10px 10px", width: "50%" }}>
                  <div style={{ textAlign: "center" }}>
                    <h3>Composition of Sales</h3>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                    className="p-fluid"
                  >
                    <div style={{ width: "80%" }}>
                      <div className="p-field">
                        <label htmlFor="integeronly">Domestic</label>
                        <InputText
                          id="domestic"
                          value={formik.values.domestic}
                          onChange={formik.handleChange}
                        ></InputText>
                        {formik.errors.domestic && (
                          <div style={{ color: "red" }}>
                            {formik.errors.domestic}
                          </div>
                        )}
                      </div>
                      <div className="p-field">
                        <label>Exports</label>
                        <InputText
                          id="exports"
                          value={formik.values.exports}
                          onChange={formik.handleChange}
                        ></InputText>
                        {formik.errors.exports && (
                          <div style={{ color: "red" }}>
                            {formik.errors.exports}
                          </div>
                        )}
                      </div>
                      <div className="p-field">
                        <label>Total</label>
                        <InputText
                          id="total"
                          value={formik.values.total}
                          onChange={formik.handleChange}
                        ></InputText>
                        {formik.errors.total && (
                          <div style={{ color: "red" }}>
                            {formik.errors.total}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="p-fluid"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div
                className="p-field"
                style={{ width: "60%", textAlign: "center" }}
              >
                <h3>Product Description</h3>
                <InputTextarea
                  id="productDescription"
                  value={formik.values.productDescription}
                  onChange={formik.handleChange}
                  rows={2}
                  cols={90}
                ></InputTextarea>
                {formik.errors.productDescription && (
                  <div style={{ color: "red" }}>
                    {formik.errors.productDescription}
                  </div>
                )}
              </div>
            </div>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <div style={{ margin: "0px 40px" }} className="p-fluid">
                <div style={{ textAlign: "center" }}>
                  <h2>Enter the Following Details as Applicable</h2>
                </div>

                <div style={{ display: "flex" }}>
                  <div style={{ width: "70%", margin: "0px 30px" }}>
                    <div className="p-field">
                      <label>GSTIN</label>
                      <InputText
                        id="gstin"
                        value={formik.values.gstin}
                        onChange={formik.handleChange}
                      ></InputText>
                      {formik.errors.gstin && (
                        <div style={{ color: "red" }}>
                          {formik.errors.gstin}
                        </div>
                      )}
                    </div>
                    <div className="p-field">
                      <label>PAN</label>
                      <InputText
                        id="pan"
                        value={formik.values.pan}
                        onChange={formik.handleChange}
                      ></InputText>
                      {formik.errors.pan && (
                        <div style={{ color: "red" }}>{formik.errors.pan}</div>
                      )}
                    </div>
                    <div className="p-field">
                      <label>IE Code</label>
                      <InputText
                        id="ieCode"
                        value={formik.values.ieCode}
                        onChange={formik.handleChange}
                      ></InputText>
                      {formik.errors.ieCode && (
                        <div style={{ color: "red" }}>
                          {formik.errors.ieCode}
                        </div>
                      )}
                    </div>
                  </div>
                  <div style={{ width: "70%", margin: "0px 30px" }}>
                    <div className="p-field">
                      <label>MSME Registration No</label>
                      <InputText
                        id="msmeRegNo"
                        value={formik.values.msmeRegNo}
                        onChange={formik.handleChange}
                      ></InputText>
                      {formik.errors.msmeRegNo && (
                        <div style={{ color: "red" }}>
                          {formik.errors.msmeRegNo}
                        </div>
                      )}
                    </div>
                    <div className="p-field">
                      <label>Company Registration No</label>
                      <InputText
                        id="compRegNo"
                        value={formik.values.compRegNo}
                        onChange={formik.handleChange}
                      ></InputText>
                      {formik.errors.compRegNo && (
                        <div style={{ color: "red" }}>
                          {formik.errors.compRegNo}
                        </div>
                      )}
                    </div>
                    <div className="p-field">
                      <label>Professtion (Institute No) </label>
                      <InputText
                        id="profession"
                        value={formik.values.profession}
                        onChange={formik.handleChange}
                      ></InputText>
                      {formik.errors.profession && (
                        <div style={{ color: "red" }}>
                          {formik.errors.profession}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="p-fluid"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div style={{ width: "30%" }}>
                <div className="p-field">
                  <div style={{ textAlign: "center" }}>
                    <h2>Representative No.1 Details</h2>
                  </div>

                  <label>Name of the Representative </label>
                  <InputText />
                  <label>Designation of the Representative</label>
                  <InputText />
                  <FileUpload
                    style={{ margin: "10px" }}
                    name="demo[]"
                    url="https://primefaces.org/primereact/showcase/upload.php"
                    accept="image/*"
                    maxFileSize={1000000}
                  />
                </div>

                <div className="p-field">
                  <div style={{ textAlign: "center" }}>
                    <h2>Representative No.2 Details</h2>
                  </div>
                  <label>Name of the Representative </label>
                  <InputText />
                  <label>Designation of the Representative </label>
                  <InputText />
                  <FileUpload
                    style={{ margin: "10px" }}
                    name="demo[]"
                    url="https://primefaces.org/primereact/showcase/upload.php"
                    accept="image/*"
                    maxFileSize={1000000}
                  />
                </div>
                <div className="p-field">
                  <div style={{ textAlign: "center" }}>
                    <h2>Representative No.3 Details</h2>
                  </div>
                  <label>Name of the Representative </label>
                  <InputText />
                  <label>Designation of the Representative </label>
                  <InputText />
                  <FileUpload
                    style={{ margin: "10px" }}
                    name="demo[]"
                    url="https://primefaces.org/primereact/showcase/upload.php"
                    accept="image/*"
                    maxFileSize={1000000}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                textAlign: "center",
                marginBottom: "100px",
                marginTop: "30px",
              }}
            >
              <Button
                onClick={() => onClick("displayResponsive")}
                type="submit"
                label="Submit Form"
              ></Button>
              <Dialog
                header="Create a New User"
                visible={displayResponsive}
                onHide={() => onHide("displayResponsive")}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "30vw" }}
              >
                <div className="">
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-900 font-medium mb-2"
                    >
                      Name
                    </label>
                    <InputText id="name" type="text" className="w-full mb-3" />
                    <label
                      htmlFor="email"
                      className="block text-900 font-medium mb-2"
                    >
                      Email
                    </label>
                    <InputText id="email" type="text" className="w-full mb-3" />

                    <label
                      htmlFor="password"
                      className="block text-900 font-medium mb-2"
                    >
                      Password
                    </label>
                    <InputText
                      id="password"
                      type="password"
                      className="w-full mb-3"
                    />
                    <label
                      htmlFor="phone"
                      className="block text-900 font-medium mb-2"
                    >
                      Phone
                    </label>
                    <InputText id="phone" type="text" className="w-full mb-3" />
                    <label
                      htmlFor="email1"
                      className="block text-900 font-medium mb-2"
                    >
                      Alternative Email
                    </label>
                    <InputText
                      id="email1"
                      type="text"
                      className="w-full mb-3"
                    />
                    <label
                      htmlFor="role"
                      className="block text-900 font-medium mb-2"
                    >
                      Alternative Email
                    </label>
                    <Dropdown id="role" className="w-full mb-3" />
                    <Button
                      label="Create New User"
                      icon="pi pi-user"
                      className="w-full mt-2 "
                    />
                  </div>
                </div>
              </Dialog>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
