import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { deleteUser } from "../../../../../../apis/api";

function DeleteUserPopUp({ showSuccess, showError, data,setUpdate,update }) {
  const [displayResponsive, setDisplayResponsive] = useState(false);
  const [position, setPosition] = useState("center");
  const dialogFuncMap = {
    displayResponsive: setDisplayResponsive,
  };

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };
  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };
  const renderFooter = (name) => {
    return (
      <div>
        <Button
          label="No"
          icon="pi pi-times"
          onClick={() => onHide(name)}
          className="p-button-text"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          onClick={(e) => {
            onHide(name);
            deleteUser(data.id).then((data) => {
              if (data.status === "success") {
                showSuccess();
                setUpdate(!update)
              } else {
                showError();
              }
            });
          }}
          autoFocus
        />
      </div>
    );
  };
  return (
    <div>
      <Button
        onClick={() => {
          onClick("displayResponsive");
        }}
        className=" bg-red-600 h-2rem"
        label="Delete"
      ></Button>
      <Dialog
        header="Delete User"
        visible={displayResponsive}
        onHide={() => onHide("displayResponsive")}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "30vw" }}
        footer={renderFooter("displayResponsive")}
      >
        <p>Are you sure about deleting this User?</p>
      </Dialog>
    </div>
  );
}

export default DeleteUserPopUp;
