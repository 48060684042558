import React, { useRef, useState } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import { createNewEvent } from "../../../../apis/api";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import { Divider } from "primereact/divider";
import { useNavigate } from "react-router-dom";

function AddNewEvent() {
  const [uploadImage, setUploadImage] = useState("");
  const [uploadPdf, setUploadPdf] = useState("");
  const toast = useRef(null);
  const navigate = useNavigate();
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  const showSuccess = () => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "New Event Created",
      life: 3000,
    });
  };
  const showError = () => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: "There is an Issue creating a new Event, Please try Again",
      life: 3000,
    });
  };

  const formik = useFormik({
    initialValues: {
      event_name: "",
      event_description: "",
      event_start: "",
      event_end: "",
      is_active: true,
      // event_type: "free",
      location: "",
      fee: "",
      payment_link: "",
      networking_time: "",
    },
    validate: (data) => {
      let errors = {};

      if (!data.event_name) {
        errors.event_name = "Event Name is required.";
      }

      // if (!data.event_description) {
      //   errors.event_description = "Event Description is required.";
      // }

      if (!data.event_start) {
        errors.event_start = "Event Start is Required.";
      }
      if (!data.event_end) {
        errors.event_end = "Event End is Required.";
      }
      if (!data.location) {
        errors.location = "Location is Required.";
      }
      if (!data.fee) {
        errors.fee = "Entry Fee is Required.";
      }
      if (data.fee && data.fee !== "0" && !data.payment_link) {
        errors.payment_link = "Entry Payment Link is Required.";
      }

      return errors;
    },
    onSubmit: (data) => {
      console.log(data);
      const formdata = new FormData();
      formdata.append("event_name", data.event_name);
      formdata.append("event_description", data.event_description);
      formdata.append("event_start", data.event_start?.toISOString());
      formdata.append("event_end", data.event_end?.toISOString());
      if (data?.networking_time !== "") {
        formdata.append("networking", data.networking_time?.toISOString());
      }
      formdata.append("is_active", "true");
      // formdata.append("event_type", "free");
      formdata.append("location", data.location);
      if (data.payment_link !== "") {
        formdata.append("payment_link", data.payment_link);
      }
      formdata.append("fee", data.fee);
      if (uploadImage !== "") {
        formdata.append("image", uploadImage);
      }
      if (uploadPdf !== "") {
        formdata.append("pdf", uploadPdf);
      }
      createNewEvent(formdata)
        .then((data) => {
          if (data.status) {
            console.log(data);
            showSuccess();
            setTimeout(() => {
              navigate(-1);
            }, 3000);
          }
        })
        .catch((e) => {
          console.log(e);
          showError();
        });
    },
  });

  return (
    <div>
      <div className="">
        <Divider className="mt-5" align="center">
          <h2>Create Event</h2>
        </Divider>
        <Toast ref={toast}></Toast>
        <div className="">
          <form className="p-fluid" onSubmit={formik.handleSubmit}>
            <div className=" flex justify-content-center">
              <div className="card w-25rem mx-5 ">
                <div className="field my-5 ">
                  <span className="p-float-label">
                    <InputText
                      id="event_name"
                      name="event_name"
                      value={formik.values.event_name}
                      onChange={formik.handleChange}
                      autoFocus
                    />
                    <label htmlFor="name">Event Name*</label>
                    {getFormErrorMessage("event_name")}
                  </span>
                </div>

                <div className="field my-5 ">
                  <span className="p-float-label">
                    <Calendar
                      id="event_start"
                      name="event_start"
                      showTime
                      hourFormat="12"
                      value={formik.values.event_start}
                      onChange={formik.handleChange}
                      autoFocus
                    />
                    <label htmlFor="name">Event Start*</label>
                    {getFormErrorMessage("event_start")}
                  </span>
                </div>
                <div className="field my-5 ">
                  <span className="p-float-label">
                    <Calendar
                      id="event_end"
                      name="event_end"
                      showTime
                      hourFormat="12"
                      value={formik.values.event_end}
                      onChange={formik.handleChange}
                      autoFocus
                    />
                    <label htmlFor="name">Event End*</label>
                    {getFormErrorMessage("event_end")}
                  </span>
                </div>
                <div className="field my-5 ">
                  <span className="p-float-label">
                    <Calendar
                      id="networking_time"
                      name="networking_time"
                      value={formik.values.networking_time}
                      showTime
                      hourFormat="12"
                      onChange={formik.handleChange}
                      autoFocus
                    />
                    <label htmlFor="name">Networking Time</label>
                    {getFormErrorMessage("event_end")}
                  </span>
                </div>
                <div className="field my-5 ">
                  <span className="p-float-label">
                    <InputTextarea
                      className="h-5rem"
                      id="event_description"
                      name="event_description"
                      value={formik.values.event_description}
                      onChange={formik.handleChange}
                      autoFocus
                    />
                    <label htmlFor="name">Event Description</label>
                  </span>
                </div>
              </div>

              <div className="card w-25rem mx-5 ">
                <div className="field my-5 ">
                  <span className="p-float-label">
                    <InputText
                      id="location"
                      name="location"
                      value={formik.values.location}
                      onChange={formik.handleChange}
                      autoFocus
                    />
                    <label htmlFor="name">Location*</label>
                    {getFormErrorMessage("location")}
                  </span>
                </div>

                <div className="field my-5 ">
                  <span className="p-float-label">
                    <InputText
                      id="fee"
                      name="fee"
                      value={formik.values.fee}
                      onChange={formik.handleChange}
                      autoFocus
                    />
                    <label htmlFor="name">Fee*</label>
                    {getFormErrorMessage("fee")}
                  </span>
                </div>
                {formik.values.fee !== "" && formik.values.fee !== "0" && (
                  <div className="field my-5 ">
                    <span className="p-float-label">
                      <InputText
                        id="payment_link"
                        name="payment_link"
                        value={formik.values.payment_link}
                        onChange={formik.handleChange}
                      />
                      <label htmlFor="name">Payment Link*</label>
                      {getFormErrorMessage("payment_link")}
                    </span>
                  </div>
                )}

                {/* <div className="field my-5 ">
                  <span className="p-float-label">
                    <Dropdown
                      options={["Member", "Non-Member", "Both"]}
                      id="type"
                      name="type"
                      value={formik.values.type}
                      onChange={formik.handleChange}
                      autoFocus
                    />
                    <label htmlFor="name">Type*</label>
                  </span>
                </div> */}
                <div style={{ marginBottom: "20px" }} className="field">
                  <label
                    htmlFor="name"
                    style={{
                      display: "block",
                      marginBottom: "5px",
                      textAlign: "left",
                      fontSize: "14px",
                      color: "gray",
                    }}
                  >
                    Event Image*
                  </label>
                  <span className="p-float-label">
                    <input
                      className="fileUpload w-full h-5rem"
                      type="file"
                      onChange={(e) => setUploadImage(e.target.files[0])}
                    />
                  </span>
                </div>
                <div style={{ marginBottom: "20px" }} className="field">
                  <label
                    htmlFor="name"
                    style={{
                      display: "block",
                      marginBottom: "5px",
                      textAlign: "left",
                      fontSize: "14px",
                      color: "gray",
                    }}
                  >
                    Broucher
                  </label>
                  <span className="p-float-label">
                    <input
                      className="fileUpload w-full h-5rem"
                      type="file"
                      onChange={(e) => setUploadPdf(e.target.files[0])}
                    />
                  </span>
                </div>
              </div>
            </div>

            <Button
              className="w-15rem"
              type="submit"
              label="Register Now"
            ></Button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddNewEvent;
