import React, { useState, useRef, useEffect } from "react";
import { TabMenu } from "primereact/tabmenu";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import AdminMenuBar from "../AdminMenuBar";
import { useDispatch } from "react-redux";
import { setRefresh } from "../../../redux/member/memberReducer";

function UserManagement() {
  const [isLoading, setLoading] = useState(false);
  const userType = window.localStorage.getItem("userType");
  const navigate = useNavigate();
  let location = useLocation();
  console.log(location.pathname.split("/")[2]);
  const [activeIndex, setActiveIndex] = useState(0);
  const toast = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (location.pathname.split("/")[2] === "allUsers") {
      setActiveIndex(0);
    }
    if (location.pathname.split("/")[2] === "pendingApprovals") {
      setActiveIndex(1);
    }
    if (location.pathname.split("/")[2] === "allMembers") {
      setActiveIndex(2);
      dispatch(setRefresh(true));
    }
    if (location.pathname.split("/")[2] === "addNewUser") {
      setActiveIndex(3);
    }
    if (location.pathname.split("/")[2] === "addNewMember") {
      setActiveIndex(4);
    }
  }, [location.pathname]);
  useEffect(() => {
    setLoading(false);
  }, [isLoading]);
  const items = [
    { label: "All Users", icon: "pi pi-fw pi-pencil" },
    {
      label: "Pending Approvals",
      icon: "pi pi-fw pi-home",
    },
    {
      label: "All Members",
      icon: "pi pi-fw pi-home",
    },
    {
      label: "Add User",
      icon: "pi pi-fw pi-home",
    },
    {
      label: "Add new Member",
      icon: "pi pi-fw pi-home",
    },
    {
      label: "Receipt",
      icon: "pi pi-fw pi-home",
    },
    {
      label: "Activity Feed",
      icon: "pi pi-fw pi-home",
    },
  ];
  const items2 = [
    {
      label: "Receipt",
      icon: "pi pi-fw pi-home",
    },
  ];
  if (userType === "Accout Team") {
    activeIndex(0);
  }
  return (
    <div>
      <AdminMenuBar />
      <TabMenu
        activeIndex={activeIndex}
        onTabChange={(e) => {
          if (e.index === 0) {
            setActiveIndex(e.index);
            setLoading(true);
            navigate("allUsers");
          }
          if (e.index === 1) {
            navigate("pendingApprovals");
            setActiveIndex(e.index);
            setLoading(true);
          }
          if (e.index === 2) {
            navigate("allMembers");
            setActiveIndex(e.index);
            setLoading(true);
          }
          if (e.index === 3) {
            navigate("addNewUser");
            setActiveIndex(e.index);
            setLoading(true);
          }
          if (e.index === 4) {
            navigate("addNewMember", { state: { isUpdate: false } });
            setActiveIndex(e.index);
            setLoading(true);
          }
          if (e.index === 5) {
            navigate("receipt");
            setActiveIndex(e.index);
            setLoading(true);
          }
          if (e.index === 6) {
            navigate("activity-feed");
            setActiveIndex(e.index);
            setLoading(true);
          }
        }}
        className="m-3"
        model={userType === "Accounts Team" ? items2 : items}
      />
      {isLoading && (
        <div>
          <div>Loading</div>
          <div>
            <ProgressSpinner className="h-1rem" strokeWidth={3} />
          </div>
        </div>
      )}
      {!isLoading && <Outlet setLoading={setLoading} />}
    </div>
  );
}

export default UserManagement;
