import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { getMemberCount, searchMemberApi } from "../../../../../apis/api";
import {
  getPendingMembers,
  getApprovedMembersforPrint,
  approve,
} from "../../../../../apis/api";
import { useNavigate, useLocation } from "react-router-dom";

import { Paginator } from "primereact/paginator";
import { SplitButton } from "primereact/splitbutton";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
function ListAllPendingApprovals() {
  const location = useLocation();
  const toast = useRef(null);
  const memberRef = useRef(null);
  const memberRefforExcel = useRef(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(10);
  const userType = window.localStorage.getItem("userType");
  const navigate = useNavigate();
  const [update, setUpdate] = useState(false);
  const [searchMember, setSearchMember] = useState("");
  const [data, setData] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [url, setUrl] = useState(null);
  const [url2, setUrl2] = useState("");
  const [newUrl, setNewUrl] = useState("");
  const [memberType, setMemberType] = useState("Member Type");
  const [memberCategory, setMemberCategory] = useState("Member Category");
  const [pendingMembers, setPendingMembers] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [sortType, setSortType] = useState("MEM_id_no");
  const [iconName, setIconName] = useState("pi pi-sort-amount-up");
  const [orderBy, setOrderBy] = useState(null);
  const items = [
    {
      label: "Small Scale Manufacturing Activit",
      value: "ms",
      command: () => {
        setCurrentPage(0);
        setUrl("&industry=ms");
        setNewUrl("&industry=ms");
        setIsSearch(false);
      },
    },
    {
      label: "Small Scale Trading Activity",
      value: "ts",
      command: () => {
        setCurrentPage(0);
        setUrl("&industry=ts");
        setNewUrl("&industry=ts");
        setIsSearch(false);
        setMemberCategory("Small Scale Trading Activity");
      },
    },
    {
      label: "Large Scale Manufacturing Activity",
      value: "ml",
      command: () => {
        setCurrentPage(0);
        setUrl("&industry=ml");
        setNewUrl("&industry=ml");
        setIsSearch(false);
        setMemberCategory("Large Scale Manufacturing Activity");
      },
    },
    {
      label: "Large Scale Service Activity",
      value: "sl",
      command: () => {
        setCurrentPage(0);
        setUrl("&industry=sl");
        setNewUrl("&industry=sl");
        setIsSearch(false);
        setMemberCategory("Large Scale Service Activity");
      },
    },
    {
      label: "Association",
      value: "as",
      command: () => {
        setCurrentPage(0);
        setUrl("&industry=as");
        setNewUrl("&industry=as");
        setIsSearch(false);
        setMemberCategory("Association");
      },
    },
    {
      label: "Small Scale Service Activity",
      value: "ss",
      command: () => {
        setCurrentPage(0);
        setUrl("&industry=ss");
        setNewUrl("&industry=ss");
        setIsSearch(false);
        setMemberCategory("Small Scale Service Activity");
      },
    },
    {
      label: "Large Scale Trading Activity",
      value: "tl",
      command: () => {
        setCurrentPage(0);
        setUrl("&industry=tl");
        setNewUrl("&industry=tl");
        setIsSearch(false);
        setMemberCategory("Large Scale Trading Activity");
      },
    },
    {
      label: "District Chambers Of Commerce",
      value: "di",
      command: () => {
        setCurrentPage(0);
        setUrl("&industry=dc");
        setNewUrl("&industry=dc");
        setIsSearch(false);
        setMemberCategory("District Chambers Of Commerce");
      },
    },
    {
      label: "Profession",
      value: "pr",
      command: () => {
        setCurrentPage(0);
        setUrl("&industry=pr");
        setNewUrl("&industry=pr");
        setIsSearch(false);
        setMemberCategory("Profession");
      },
    },
  ];
  const memberOptions = [
    {
      label: "Ordinary",
      value: "ordinary",
      command: () => {
        setCurrentPage(0);
        let str = "";
        if (url !== null) {
          str += url + "&memberCategory=ordinary";
          setNewUrl(str);
        } else {
          setNewUrl("&memberCategory=ordinary");
        }
        setIsSearch(false);
        setMemberType("Ordinary");
      },
    },
    {
      label: "Patron",
      value: "patron",
      command: () => {
        setCurrentPage(0);
        let str = "";
        if (url !== null) {
          str += url + "&memberCategory=patron";
          setNewUrl(str);
        } else {
          setNewUrl("&memberCategory=patron");
        }
        setIsSearch(false);
        setMemberType("Patron");
      },
    },
  ];
  const list = [
    {
      label: "Download as PDF",
      icon: "pi pi-arrow-down",
      command: () => {
        getAlldata(totalRecords, "print");
      },
    },
    {
      label: "Download as Excel",
      icon: "pi pi-arrow-down",
      command: () => {
        getAlldata(totalRecords, "excel");
      },
    },
  ];
  const sortOptions = [
    {
      label: "Pincode",
      command: () => {
        setSortType("pincode");
      },
    },
    {
      label: "Sector",
      command: () => {
        setSortType("MEM_id_no");
      },
    },
    {
      label: "Compony Name",
      command: () => {
        setSortType("name_of_org");
      },
    },
    {
      label: "City",
      command: () => {
        setSortType("city");
      },
    },
    {
      label: "Member Id",
      command: () => {
        setSortType("MEM_id_no");
      },
    },
  ];
  const onBasicPageChange = (event) => {
    console.log("event", event);
    setBasicFirst(event.first);
    setBasicRows(event.rows);
    setCurrentPage(event.page);
  };
  const getAlldata = async (total, type) => {
    setLoading(true);
    let url = "";
    let url2 = "ASC";
    if (isSearch) {
      url = url2;
    } else {
      url = newUrl;
    }
    if (orderBy !== null) {
      url2 = orderBy;
    }
    url += "&downloadType=pending";
    getApprovedMembersforPrint(sortType, url2, url)
      .then((res) => {
        console.log("result", res);
        setExcelData(res);
        setTimeout(() => {
          if (type === "print") {
            handleMemberPrint();
          } else {
            handleMemberExcelPrint.onDownload();
          }
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        console.log(err, "error");
        setLoading(false);
      });
  };
  const pageStyle = `@media print {
    @page {
      size: 210mm 297mm;
      margin: 0;
    }
  }`;
  const handleMemberPrint = useReactToPrint({
    pageStyle: pageStyle,
    content: () => memberRef.current,
  });

  const handleMemberExcelPrint = useDownloadExcel({
    currentTableRef: memberRefforExcel.current,
    filename: "FKCCI Members",
    sheet: "Members",
  });
  useEffect(() => {
    getPendingMembers(currentPage, 10, orderBy, newUrl)
      .then((res) => {
        console.log("res", res);
        setData(res?.results);
        setTotalRecords(res?.total);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, [update, currentPage, newUrl, orderBy]);
  useEffect(() => {
    getCount();
  }, []);
  const getCount = async () => {
    getMemberCount()
      .then((res) => {
        console.log("resCount", res);
        setPendingMembers(res?.notApprovedCount);
        setTotalCount(res?.totalCount);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const showSuccess = (message) => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: message,
      life: 3000,
    });
  };
  const showError = (message) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: message,
      life: 3000,
    });
  };

  const approvalStatus = (rowData) => {
    if (rowData.status === "APPROVED") {
      return (
        <span
          style={{
            backgroundColor: "#B3E5FC",
            color: "#23547B",
            textTransform: "uppercase",
            padding: ".25em 1rem",
            borderRadius: "3px",
            fontWeight: "700",
            letterSpacing: ".3px",
          }}
        >
          {rowData.status}
        </span>
      );
    } else if (rowData.status === "REJECTED") {
      return (
        <span
          style={{
            backgroundColor: "#ECCFFF",
            color: "#694382",
            textTransform: "uppercase",
            padding: ".25em 1rem",
            borderRadius: "3px",
            fontWeight: "700",
            letterSpacing: ".3px",
          }}
        >
          {rowData.status}
        </span>
      );
    } else {
      return (
        <span
          style={{
            backgroundColor: "#FFD8B2",
            color: "#805B36",
            textTransform: "uppercase",
            padding: ".25em 1rem",
            borderRadius: "3px",
            fontWeight: "700",
            letterSpacing: ".3px",
          }}
        >
          {rowData.status}
        </span>
      );
    }
  };
  const renderHeader = () => {
    return (
      <div className="">
        <div
          className="flex align-items-center flex-wrap gap-3"
          style={{ marginBottom: "10px" }}
        >
          <h2 style={{ textAlign: "left", marginBottom: "10px" }}>
            Pending Approvals({pendingMembers})
          </h2>
          <Button
            icon={iconName}
            onClick={() => {
              console.log("iconName", iconName);
              if (iconName === "pi pi-sort-amount-up" && orderBy !== null) {
                setIconName("pi pi-sort-amount-down");
                setOrderBy("DESC");
              } else {
                setIconName("pi pi-sort-amount-up");
                setOrderBy("ASC");
              }
            }}
          />
        </div>
        <Toast ref={toast}></Toast>

        <div className="flex align-items-center flex-wrap justify-content-between gap-3">
          <div className="flex align-items-center flex-wrap gap-3">
            <SplitButton
              label="Membership Category"
              icon="pi pi-filter"
              onClick={() => {}}
              model={items}
            ></SplitButton>
            <SplitButton
              label="Member Type"
              icon="pi pi-filter"
              onClick={() => {}}
              model={memberOptions}
            ></SplitButton>
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                value={searchMember}
                onChange={(e) => {
                  setSearchMember(e.target.value);
                }}
                onKeyUp={() => {
                  if (searchMember.length >= 4) {
                    searchMemberApi(searchMember).then((res) => {
                      if (Array.isArray(res)) {
                        setData(res);
                        setIsSearch(true);
                        setUrl2(`&key=${searchMember}`);
                      } else {
                        showError("Keywords Not Enough");
                      }
                    });
                  } else {
                    getPendingMembers(currentPage, 10).then((res) => {
                      setData(res.results);
                      setTotalRecords(res.total);
                    });
                  }
                }}
                placeholder="Search Member"
              />
            </span>
            {/* <Button
              onClick={() => {
                if (searchMember.length) {
                  searchMemberApi(searchMember).then((res) => {
                    if (Array.isArray(res)) {
                      setData(res);
                      setIsSearch(true);
                      setUrl2(`&key=${searchMember}`);
                    } else {
                      showError("Keywords Not Enough");
                    }
                  });
                } else {
                  getPendingMembers(currentPage, 10).then((res) => {
                    setData(res.results);
                    setTotalRecords(res.total);
                  });
                }
              }}
              label="Search Member"
            ></Button> */}
          </div>
          <div className="flex align-items-center flex-wrap gap-3">
            <SplitButton
              label="Sort By"
              icon="pi pi-sort-alt"
              model={sortOptions}
            ></SplitButton>
            <SplitButton
              label="Download"
              icon="pi pi-arrow-down"
              loading={isLoading}
              onClick={() => {
                getAlldata(totalRecords, "print");
              }}
              model={list}
            ></SplitButton>
          </div>
        </div>
      </div>
    );
  };
  const approveField = (data) => {
    return (
      <Button
        disabled={(data.status === "REJECTED" || data.status === 'APPROVED') && true}
        onClick={() => {
          approve(data.member_id, {
            status: "APPROVE",
          })
            .then((res) => {
              if (res === "Status Updated") {
                showSuccess("Application Approved");
                setUpdate(!update);
              }
            })
            .catch((err) => showError("Error Rejecting the Application"));
        }}
        label="Approve"
      ></Button>
    );
  };
  const rejectField = (data) => {
    return (
      <Button
        disabled={(data.status === "REJECTED" || data.status === 'APPROVED') && true}
        onClick={() => {
          approve(data.member_id, {
            status: "REJECT",
          })
            .then((res) => {
              if (res === "Status Updated") {
                showSuccess("Application rejected");
                setUpdate(!update);
              }
            })
            .catch((err) => showError("Error Rejecting the Application"));
        }}
        className="bg-red-600"
        label="Reject"
      ></Button>
    );
  };
  const viewApplicationField = (data) => {
    return (
      <Button
        onClick={(e) => {
          e.preventDefault();
          navigate("/userManagement/memberDetails", {
            state: data.member_id,
          });
        }}
        className="p-button-outlined p-button-secondary"
        label="View"
      ></Button>
    );
  };
  const header = renderHeader();
  return (
    <div>
      <div className="m-3">
        <div className="card">
          <DataTable
            value={data}
            header={header}
            loading={isLoading}
            showGridlines
          >
            <Column field="MEM_id_no" header="Member ID"></Column>
            <Column field="name_of_org" header="Name of Organization"></Column>
            <Column field="mobile" header="Mobile"></Column>
            <Column
              field="membership_category"
              header="Membership Category"
            ></Column>
            <Column field="member_category" header="Member Tag"></Column>
            <Column field="user.email" header="Email"></Column>
            <Column field={viewApplicationField} header=" Application"></Column>
            <Column field={approvalStatus} header="Application Status"></Column>

            {(userType === "Vice President"|| userType === "SR.Vice President" ||  userType === "Super Admin") && (
              <Column field={approveField} header="Approval"></Column>
            )}
            {(userType === "Vice President"|| userType === "SR.Vice President" ||  userType === "Super Admin") && (
              <Column field={rejectField} header="Rejection"></Column>
            )}
          </DataTable>
          <Paginator
            first={basicFirst}
            totalRecords={totalRecords}
            rows={basicRows}
            onPageChange={onBasicPageChange}
          ></Paginator>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <div
          ref={memberRef}
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, minmax(63.5mm, 1fr))",
            gridAutoRows: "max-content",
            gridAutoFlow: "row",
            paddingTop: "1mm",
            paddingLeft: "0mm",
            paddingRight: "8mm",
          }}
        >
          {excelData?.map((item, index) => (
            <div
              key={index}
              style={{
                marginTop:
                  index % 24 === 0 || index % 24 === 1 || index % 24 === 2
                    ? "11.5mm"
                    : "0px",
                height: "34.125mm",
                // border: "0.125px solid #000000",
                padding: "2px 5px 0px 20px",
                borderRadius: "20px",
                overflow: "hidden",
              }}
            >
              <p
                style={{
                  fontSize: "0.7em",
                  color: "#000000",
                  lineHeight: "14px",
                }}
              >
                {item?.MEM_id_no}
              </p>
              <p
                style={{
                  fontSize: "0.7em",
                  fontWeight: "400",
                  lineHeight: "14px",
                  wordBreak: "break-word",
                  overflowWrap: "break-word",
                }}
              >
                {item?.name_of_org}
              </p>
              <p
                style={{
                  fontSize: "0.7em",
                  fontWeight: "400",
                  lineHeight: "14px",
                  wordBreak: "break-word",
                  overflowWrap: "break-word",
                }}
              >
                {item?.address}
              </p>
              <p
                style={{
                  fontSize: "0.7em",
                  fontWeight: "400",
                  lineHeight: "14px",
                }}
              >
                {item?.city} - {item?.pincode}
              </p>
              <p
                style={{
                  fontSize: "0.7em",
                  fontWeight: "400",
                  lineHeight: "14px",
                }}
              >
                Phone : {item?.phone_number}
              </p>
              <p
                style={{
                  fontSize: "0.7em",
                  fontWeight: "400",
                  lineHeight: "14px",
                }}
              >
                mobile : {item?.mobile}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div style={{ display: "none" }}>
        <table ref={memberRefforExcel} border="1">
          <thead>
            <tr>
              <th>Member Id</th>
              <th>Compony Name</th>
              <th>Address</th>
              <th>City</th>
              <th>Pincode</th>
              <th>Office No.</th>
              <th>Fax</th>
              <th>Mobile No.</th>
              <th>Email</th>
              <th>Name of Representative 1</th>
              <th>Designation of Representative 1</th>
              <th>Name of Representative 2</th>
              <th>Designation of Representative 2</th>
              <th>Name of Representative 3</th>
              <th>Designation of Representative 3</th>
              <th>Product</th>
              <th>Status</th>
              <th>Date of Addmission</th>
              <th>Date of Estd.</th>
              <th>Membership Category</th>
              <th>Member Tag</th>
              <th>Website</th>
              <th>GSTIN</th>
              <th>Cin No.</th>
              <th>PAN</th>
              <th>Proposed By</th>
              <th>Seconded By</th>
              <th>Introducer Name</th>
            </tr>
          </thead>
          <tbody>
            {excelData?.map((val) => (
              <tr>
                <td>{val.MEM_id_no}</td>
                <td>{val.name_of_org}</td>
                <td>{val?.address}</td>
                <td>{val?.city}</td>
                <td>{val?.pincode}</td>
                <td>{val?.phone_number}</td>
                <td>{val?.fax}</td>
                <td>{val?.mobile}</td>
                <td>{val?.email}</td>
                <td>{val?.name_of_representative1}</td>
                <td>{val?.designation_of_representative1}</td>
                <td>{val?.name_of_representative2}</td>
                <td>{val?.designation_of_representative2}</td>
                <td>{val?.name_of_representative3}</td>
                <td>{val?.designation_of_representative3}</td>
                <td>{val?.description}</td>
                <td>{val?.status}</td>
                <td>
                  {val?.date_of_admission !== null
                    ? `${moment(val?.date_of_admission).format("DD-MM-YYYY")}`
                    : null}
                </td>
                <td>
                  {val?.date_of_estd !== null
                    ? `${moment(val?.date_of_estd).format("DD-MM-YYYY")}`
                    : null}
                </td>
                <td>{val?.membership_category}</td>
                <td>{val?.member_category}</td>
                <td>{val?.website}</td>
                <td>{val?.GSTIN}</td>
                <td>{val?.cin_no}</td>
                <td>{val?.PAN}</td>
                <td>{val?.proposed_by}</td>
                <td>{val?.seconded_by}</td>
                <td>{val?.introducer_name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ListAllPendingApprovals;
