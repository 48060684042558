import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import {
  getApprovedMembersbyCategory,
  getApprovedMembersforPrint,
  getCityWiseCount,
  getMemberCount,
  searchMemberApi,
} from "../../../../../apis/api";
import { getApprovedMembers } from "../../../../../apis/api";
import { approve } from "../../../../../apis/api";
import { useNavigate, useLocation } from "react-router-dom";
import { Paginator } from "primereact/paginator";
import { useReactToPrint } from "react-to-print";
import { Dropdown } from "primereact/dropdown";
import { getDefaultNormalizer } from "@testing-library/react";
import { SplitButton } from "primereact/splitbutton";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useDispatch, useSelector } from "react-redux";
import EligibleData from "./Eligible_members.json";
import {
  setMemberData,
  setTotal,
} from "../../../../../redux/member/memberReducer";
import moment from "moment/moment";
function ListAllMembers() {
  const toast = useRef(null);
  const navigate = useNavigate();
  const memberRef = useRef(null);
  const memberRefforExcel = useRef(null);
  const eligibleRef = useRef(null);
  const cityRefforExcel = useRef(null);
  const [update, setUpdate] = useState(false);
  const [searchMember, setSearchMember] = useState("");
  const [data, setData] = useState([]);
  const [url, setUrl] = useState(null);
  const [url2, setUrl2] = useState(null);
  const [newUrl, setNewUrl] = useState("");

  const [totalRecords, setTotalRecords] = useState(0);
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [excelData, setExcelData] = useState([]);
  const [excelDataCityWise, setExcelDataCityWise] = useState([]);
  const [category, setCategory] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [isLoading2, setLoading2] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [allMemberCount, setAllMemberCount] = useState(0);
  const [approvedMemberCount, setApprovedMemberCount] = useState(0);
  const [sortType, setSortType] = useState("MEM_id_no");
  const [iconName, setIconName] = useState("pi pi-sort-amount-up");
  const [orderBy, setOrderBy] = useState("ASC");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const { memberData, isRefresh, total } = useSelector((state) => state.member);
  const dispatch = useDispatch();
  const location = useLocation();
  const [type, setType] = useState("All");
  const [typeLable, setTypeLable] = useState("All Members");
  
  const [membershipCategoryLable, setMembershipCategoryLable] = useState("Membership Category");
  const [membershipLable, setMembershipLable] = useState("Member Type");
  // const [sortByLabel, setSortByLabel] = useState("Sort By");
  useEffect(() => {
    if (isRefresh) {
      setLoading(true);
      console.log("loc", isRefresh, currentPage);
      getApprovedMembers(currentPage, 10, orderBy, newUrl, type)
        .then((res) => {
          // if (currentPage === 0) {
          //   getAlldata(res.total);
          // } else {
          //   setLoading(false);
          // }
          console.log("res", res);
          setData(res.results);
          setTotalRecords(res.total);
          dispatch(setMemberData(res.results));
          dispatch(setTotal(res.total));
        })
        .catch((err) => {
          console.log("error", err);
        })
        .finally((res) => {
          setLoading(false);
        });
      cityWiseData();
    } else {
      setLoading(false);
      setTotalRecords(total);
    }
  }, [update, currentPage, newUrl, orderBy, type]);
  useEffect(() => {
    getCount();
  }, []);
  const pageStyle = `@media print {
    @page {
      size: 210mm 297mm;
      margin: 0;
    }
  }`;
  const handleMemberPrint = useReactToPrint({
    pageStyle: pageStyle,
    content: () => memberRef.current,
  });
  const getCount = async () => {
    getMemberCount()
      .then((res) => {
        console.log("resCount", res);
        setAllMemberCount(res?.totalCount);
        setApprovedMemberCount(res?.approvedCount);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  console.log("selectedRows", selectedProducts);
  const getAlldata = async (total, pType) => {
    setLoading2(true);
    let url = "";
    if (isSearch) {
      url = url2;
    } else {
      url = newUrl;
    }
    url += `&isPaid=${type}`;
    if (selectedProducts.length) {
      if (pType === "print") {
        handleMemberPrint();
      } else {
        handleMemberExcelPrint.onDownload();
      }
      setLoading2(false);
    } else {
      getApprovedMembersforPrint(sortType, orderBy, url)
        .then((res) => {
          console.log("result", res);
          setExcelData(res);
          setTimeout(() => {
            if (pType === "print") {
              handleMemberPrint();
            } else {
              handleMemberExcelPrint.onDownload();
            }
            setLoading2(false);
          }, 1000);
        })
        .catch((err) => {
          console.log(err, "error");
          setLoading2(false);
        });
    }
  };
  const getFilteredData = async (val) => {
    setLoading(true);
    searchMemberApi(val).then((res) => {
      if (Array.isArray(res)) {
        setExcelData(res);
        setData(res);
        setTotalRecords(res?.length);
        dispatch(setMemberData(res));
        setUrl2(`&key=${val}`);
        setIsSearch(true);
      } else {
        // showError("Keywords Not Enough");
      }
    });
    setLoading(false);
  };
  const cityWiseData = async () => {
    const data = await getCityWiseCount();
    setExcelDataCityWise(data);
  };
  const handleMemberExcelPrint = useDownloadExcel({
    currentTableRef: memberRefforExcel.current,
    filename: "FKCCI Members",
    sheet: "Members",
  });
  const handleCityWiseCountExcelPrint = useDownloadExcel({
    currentTableRef: cityRefforExcel.current,
    filename: "FKCCI City wise count",
    sheet: "Members",
  });
  const handleEligibleExcelPrint = useDownloadExcel({
    currentTableRef: eligibleRef.current,
    filename: "Eligible_FKCCI",
    sheet: "Members",
  });
  const typeOptions = [
    {
      label: "All Members",
      command: () => {
        setType("All");
        setTypeLable("All Members");
        setUrl("");
      },
    },
    {
      label: "Paid Members",
      command: () => {
        setType("Paid");
        setTypeLable("Paid Members");
        setUrl("");
      },
    },
    {
      label: "Due Members",
      command: () => {
        setType("Due");
        setTypeLable("Due Members");
        setUrl("");
      },
    },
  ];
  const items = [
    {
      label: "Small Scale Manufacturing Activity",
      value: "ms",
      command: () => {
        setCurrentPage(0);
        setUrl("&industry=ms");
        setNewUrl("&industry=ms");
        setIsSearch(false);
        setMembershipCategoryLable("Small Scale Manufacturing Activity")
        setMembershipLable("Member Type")
        setTypeLable("All Members")
      },
    },
    {
      label: "Small Scale Trading Activity",
      value: "ts",
      command: () => {
        setCurrentPage(0);
        setUrl("&industry=ts");
        setNewUrl("&industry=ts");
        setIsSearch(false);
        setMembershipCategoryLable("Small Scale Trading Activity")
        setMembershipLable("Member Type")
        setTypeLable("All Members")
      },
    },
    {
      label: "Large Scale Manufacturing Activity",
      value: "ml",
      command: () => {
        setCurrentPage(0);
        setUrl("&industry=ml");
        setNewUrl("&industry=ml");
        setIsSearch(false);
        setMembershipCategoryLable("Large Scale Manufacturing Activity")
        setMembershipLable("Member Type")
        setTypeLable("All Members")

      },
    },
    {
      label: "Large Scale Service Activity",
      value: "sl",
      command: () => {
        setCurrentPage(0);
        setUrl("&industry=sl");
        setNewUrl("&industry=sl");
        setIsSearch(false);
        setMembershipCategoryLable("Large Scale Service Activity")
        setMembershipLable("Member Type")
        setTypeLable("All Members")
      },
    },
    {
      label: "Association",
      value: "as",
      command: () => {
        setCurrentPage(0);
        setUrl("&industry=as");
        setNewUrl("&industry=as");
        setIsSearch(false);
        setMembershipCategoryLable("Association")
        setMembershipLable("Member Type")
        setTypeLable("All Members")
      },
    },
    {
      label: "Small Scale Service Activity",
      value: "ss",
      command: () => {
        setCurrentPage(0);
        setUrl("&industry=ss");
        setNewUrl("&industry=ss");
        setIsSearch(false);
        setMembershipCategoryLable("Small Scale Service Activity")
        setMembershipLable("Member Type")
        setTypeLable("All Members")
      },
    },
    {
      label: "Large Scale Trading Activity",
      value: "tl",
      command: () => {
        setCurrentPage(0);
        setUrl("&industry=tl");
        setNewUrl("&industry=tl");
        setIsSearch(false);
        setMembershipCategoryLable("Large Scale Trading Activity")
        setMembershipLable("Member Type")
        setTypeLable("All Members")
      },
    },
    {
      label: "District Chambers Of Commerce",
      value: "di",
      command: () => {
        setCurrentPage(0);
        setUrl("&industry=dc");
        setNewUrl("&industry=dc");
        setIsSearch(false);
        setMembershipCategoryLable("District Chambers Of Commerce")
        setMembershipLable("Member Type")
        setTypeLable("All Members")
      },
    },
    {
      label: "Profession",
      value: "pr",
      command: () => {
        setCurrentPage(0);
        setUrl("&industry=pr");
        setNewUrl("&industry=pr");
        setIsSearch(false);
        setMembershipCategoryLable("Profession")
        setMembershipLable("Member Type")
        setTypeLable("All Members")
      },
    },
  ];
  const memberType = [
    {
      label: "Ordinary",
      value: "ordinary",
      command: () => {
        setCurrentPage(0);
        let str = "";
        if (url !== null) {
          str += url + "&memberCategory=ordinary";
          setNewUrl(str);
        } else {
          setNewUrl("&memberCategory=ordinary");
        }
        setIsSearch(false);
        setMembershipLable("Ordinary")
      },
    },
    {
      label: "Patron",
      value: "patron",
      command: () => {
        setCurrentPage(0);
        let str = "";
        if (url !== null) {
          str += url + "&memberCategory=patron";
          setNewUrl(str);
        } else {
          setNewUrl("&memberCategory=patron");
        }
        setIsSearch(false);
        setMembershipLable("Patron")
      },
    },
  ];
  const list = [
    {
      label: "Download as PDF",
      icon: "pi pi-arrow-down",
      command: () => {
        getAlldata(totalRecords, "print");
      },
    },
    {
      label: "Download as Excel",
      icon: "pi pi-arrow-down",
      command: () => {
        getAlldata(totalRecords, "excel");
      },
    },
  ];
  const sortOptions = [
    {
      label: "Pincode",
      command: () => {
        setSortType("pincode");
      },
    },
    {
      label: "Sector",
      command: () => {
        setSortType("MEM_id_no");
        
      },
    },
    {
      label: "Compony Name",
      command: () => {
        setSortType("name_of_org");
      },
    },
    {
      label: "City",
      command: () => {
        setSortType("city");
      },
    },
    {
      label: "Member Id",
      command: () => {
        setSortType("MEM_id_no");
      },
    },
    {
      label: "M-Official",
      value: "LocalOnly",
      command: () => {
        setCurrentPage(0);
        setUrl("&dataselect=LocalOnly");
        setNewUrl("&dataselect=LocalOnly");
        setIsSearch(false);
      },
    },
    {
      label: "ALL-Data",
      value: "Alldata",
      command: () => {
        setCurrentPage(0);
        setUrl("&dataselect=Alldata");
        setNewUrl("&dataselect=Alldata");
        setIsSearch(false);
      },
    },
  ];
  // const dataSelectionList = [
  //   {
  //     label: "M-Official",
  //     value: "LocalOnly",
  //     command: () => {
  //       setCurrentPage(0);
  //       setUrl("&dataselect=LocalOnly");
  //       setNewUrl("&dataselect=LocalOnly");
  //       setIsSearch(false);
  //     },
  //   },
  //   {
  //     label: "ALL-Data",
  //     value: "Alldata",
  //     command: () => {
  //       setCurrentPage(0);
  //       setUrl("&dataselect=Alldata");
  //       setNewUrl("&dataselect=Alldata");
  //       setIsSearch(false);
  //     },
  //   },
  // ];
  
  const onBasicPageChange = (event) => {
    console.log("event", event);
    setBasicFirst(event.first);
    setBasicRows(event.rows);
    setCurrentPage(event.page);
  };
  const approvalStatus = (rowData) => {
    if (rowData.status === "APPROVED") {
      return (
        <span
          style={{
            backgroundColor: "#B3E5FC",
            color: "#23547B",
            textTransform: "uppercase",
            padding: ".25em 1rem",
            borderRadius: "3px",
            fontWeight: "700",
            letterSpacing: ".3px",
          }}
        >
          {rowData.status}
        </span>
      );
    } else if (rowData.status === "REJECTED") {
      return (
        <span
          style={{
            backgroundColor: "#ECCFFF",
            color: "#694382",
            textTransform: "uppercase",
            padding: ".25em 1rem",
            borderRadius: "3px",
            fontWeight: "700",
            letterSpacing: ".3px",
          }}
        >
          {rowData.status}
        </span>
      );
    } else {
      return (
        <span
          style={{
            backgroundColor: "#FFD8B2",
            color: "#805B36",
            textTransform: "uppercase",
            padding: ".25em 1rem",
            borderRadius: "3px",
            fontWeight: "700",
            letterSpacing: ".3px",
          }}
        >
          {rowData.status}
        </span>
      );
    }
  };
  const showSuccess = (message) => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: message,
      life: 3000,
    });
  };
  const showError = (message) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: message,
      life: 3000,
    });
  };

  const renderHeader = () => {
    return (
      // <div className="flex justify-content-between align-items-center flex-wrap gap-3">
      <div className="">
        <div
          className="flex align-items-center flex-wrap justify-content-between gap-3"
          style={{ marginBottom: "10px" }}
        >
          <div className="flex align-items-center flex-wrap gap-3">
            <h3>Approved Members({approvedMemberCount})</h3>
            <Button
              icon={iconName}
              onClick={() => {
                console.log("iconName", iconName);
                if (iconName === "pi pi-sort-amount-up") {
                  setIconName("pi pi-sort-amount-down");
                  setOrderBy("DESC");
                } else {
                  setIconName("pi pi-sort-amount-up");
                  setOrderBy("ASC");
                }
              }}
            />
          </div>
          <h3>All Members : {allMemberCount}</h3>
        </div>
        <Toast ref={toast}></Toast>

        <div className="flex align-items-center flex-wrap justify-content-between gap-3">
          <div className="flex align-items-center flex-wrap gap-3">
            <SplitButton
              label={membershipCategoryLable}
              icon="pi pi-filter"
              onClick={() => {}}
              model={items}
            ></SplitButton>
            <SplitButton
              label={membershipLable}
              icon="pi pi-filter"
              onClick={() => {}}
              model={memberType}
            ></SplitButton>
            <SplitButton
              label={typeLable}
              icon="pi pi-user"
              model={typeOptions}
            ></SplitButton>
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                // value={searchMember}
                style={{ width: "190px" }}
                onChange={(e) => {
                  if (e.target.value?.length >= 4) {
                    getFilteredData(e.target.value);
                  } else if (e.target.value?.length === 0) {
                    setUpdate(!update);
                  }
                }}
                placeholder="Search Member"
                // onKeyUp={() => {
                //   console.log(searchMember.length, "lllllllllllll");
                //   if (searchMember.length >= 4) {
                //     getFilteredData();
                //   }
                //   //  else {
                //   //   setUpdate(!update);
                //   // }
                // }}
              />
            </span>

            {/* <Button
              onClick={() => {
                if (searchMember.length) {
                  getFilteredData();
                } else {
                  getApprovedMembers(currentPage, 10).then((res) => {
                    setData(res.results);
                    setTotalRecords(res.total);
                  });
                }
              }}
              label="Search Member"
            ></Button> */}
          </div>
          <div className="flex align-items-center flex-wrap gap-3">
            <SplitButton
              label={sortType}
              icon="pi pi-sort-alt"
              model={sortOptions}
            ></SplitButton>
            <SplitButton
              label="Download"
              icon="pi pi-arrow-down"
              loading={isLoading2}
              onClick={() => {
                getAlldata(totalRecords, "print");
              }}
              model={list}
            ></SplitButton>
            {/* <SplitButton
              label="Data Selection"
              icon="pi pi-sort-alt"
              model={dataSelectionList}
            ></SplitButton> */}
            <Button
              label="City Wise Count"
              icon="pi pi-arrow-down"
              loading={isLoading2}
              onClick={() => {
                handleCityWiseCountExcelPrint.onDownload();
              }}
            ></Button>
            <Button
              label="Clear"
              icon="pi pi-times"
              loading={isLoading2}
              onClick={() => {
                setLoading2(true);
                setTimeout(() => {
                  setMembershipCategoryLable("Membership Category")
                setMembershipLable("Member Type")
                setTypeLable("All Members")
                setNewUrl("")
                  setLoading2(false);
                }, 1000); 
                
                // handleCityWiseCountExcelPrint.onDownload();
              }}
            ></Button>
            {/* <Button
              label="Download Eligible Members"
              icon="pi pi-arrow-down"
              onClick={() => {
                handleEligibleExcelPrint.onDownload();
              }}
            ></Button> */}
          </div>
        </div>
      </div>
    );
  };

  const viewApplicationField = (data) => {
    return (
      <Button
        onClick={() => {
          console.log("Button", data);
          navigate("/userManagement/memberDetails", {
            state: data.member_id,
          });
        }}
        className="p-button-primary w-7rem"
        label="View"
      ></Button>
    );
  };

  const memberTag = (data) => {
    return (
      <div
        className="capitalize cursor-pointer"
        onClick={() => {
          console.log("Button", data);
          navigate("/userManagement/memberDetails", {
            state: data.member_id,
          });
        }}
      >
        {data.member_category}
      </div>
    );
  };
  const memIdNo = (data) => {
    return (
      <div
        className="capitalize"
        onClick={() => {
          console.log("Button", data);
          navigate("/userManagement/memberDetails", {
            state: data.member_id,
          });
        }}
      >
        {data?.MEM_id_no}
      </div>
    );
  };
  const NameOrg = (data) => {
    return (
      <div
        className="capitalize cursor-pointer"
        onClick={() => {
          console.log("Button", data);
          navigate("/userManagement/memberDetails", {
            state: data.member_id,
          });
        }}
      >
        {data.name_of_org}
      </div>
    );
  };
  const firstName = (data) => {
    return (
      <div
        className="capitalize cursor-pointer"
        onClick={() => {
          console.log("Button", data);
          navigate("/userManagement/memberDetails", {
            state: data.member_id,
          });
        }}
      >
        {data.first_name}
      </div>
    );
  };
  const phoneNumber = (data) => {
    return (
      <div
        className="capitalize cursor-pointer"
        onClick={() => {
          console.log("Button", data);
          navigate("/userManagement/memberDetails", {
            state: data.member_id,
          });
        }}
      >
        {data.mobile}
      </div>
    );
  };
  const memberCat = (data) => {
    return (
      <div
        className="capitalize cursor-pointer"
        onClick={() => {
          console.log("Button", data);
          navigate("/userManagement/memberDetails", {
            state: data.member_id,
          });
        }}
      >
        {data.membership_category}
      </div>
    );
  };
  const email = (data) => {
    return (
      <div
        className="capitalize"
        onClick={() => {
          console.log("Button", data);
          navigate("/userManagement/memberDetails", {
            state: data.member_id,
          });
        }}
      >
        {data.user.email}
      </div>
    );
  };

  const header = renderHeader();
  return (
    <div>
      <div className="m-3">
        <div className="card">
          <DataTable
            value={memberData}
            header={header}
            showGridlines
            loading={isLoading2}
            selectionMode={"checkbox"}
            selection={selectedProducts}
            onSelectionChange={(e) => setSelectedProducts(e.value)}
          >
            <Column
              selectionMode="multiple"
              headerStyle={{ width: "3rem" }}
            ></Column>
            <Column field="MEM_id_no" header="Membership Id"></Column>
            <Column field={NameOrg} header="Name of Organization"></Column>
            <Column field={firstName} header="First Name"></Column>
            <Column field={phoneNumber} header="Mobile"></Column>
            <Column field={memberCat} header="Membership Category"></Column>
            <Column field={memberTag} header="Member Tag"></Column>
            <Column field={email} header="Email"></Column>
            <Column field={viewApplicationField} header=" Application"></Column>
          </DataTable>
          <Paginator
            first={basicFirst}
            totalRecords={total}
            rows={basicRows}
            onPageChange={onBasicPageChange}
          ></Paginator>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <div
          ref={memberRef}
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, minmax(63.5mm, 1fr))",
            gridAutoRows: "max-content",
            gridAutoFlow: "row",
            paddingTop: "1mm",
            paddingLeft: "0mm",
            paddingRight: "8mm",
          }}
        >
          {selectedProducts?.length
            ? selectedProducts?.map((item, index) => (
                <div
                  key={index}
                  style={{
                    marginTop:
                      index % 24 === 0 || index % 24 === 1 || index % 24 === 2
                        ? "11.5mm"
                        : "0px",
                    height: "34.125mm",
                    // border: "0.125px solid #000000",
                    padding: "2px 5px 0px 20px",
                    borderRadius: "20px",
                    overflow: "hidden",
                  }}
                >
                  <p
                    style={{
                      fontSize: "0.7em",
                      color: "#000000",
                      lineHeight: "14px",
                    }}
                  >
                    {item?.MEM_id_no}
                  </p>
                  <p
                    style={{
                      fontSize: "0.7em",
                      fontWeight: "400",
                      lineHeight: "14px",
                      wordBreak: "break-word",
                      overflowWrap: "break-word",
                    }}
                  >
                    {item?.name_of_org}
                  </p>
                  <p
                    style={{
                      fontSize: "0.7em",
                      fontWeight: "400",
                      lineHeight: "14px",
                      wordBreak: "break-word",
                      overflowWrap: "break-word",
                    }}
                  >
                    {item?.address}
                  </p>
                  <p
                    style={{
                      fontSize: "0.7em",
                      fontWeight: "400",
                      lineHeight: "14px",
                    }}
                  >
                    {item?.city} - {item?.pincode}
                  </p>
                  <p
                    style={{
                      fontSize: "0.7em",
                      fontWeight: "400",
                      lineHeight: "14px",
                    }}
                  >
                    Phone : {item?.phone_number}
                  </p>
                  <p
                    style={{
                      fontSize: "0.7em",
                      fontWeight: "400",
                      lineHeight: "14px",
                    }}
                  >
                    mobile : {item?.mobile}
                  </p>
                </div>
              ))
            : excelData?.map((item, index) => (
                <div
                  key={index}
                  style={{
                    marginTop:
                      index % 24 === 0 || index % 24 === 1 || index % 24 === 2
                        ? "11.5mm"
                        : "0px",
                    height: "34.125mm",
                    // border: "0.125px solid #000000",
                    padding: "2px 5px 0px 20px",
                    borderRadius: "20px",
                    overflow: "hidden",
                  }}
                >
                  <p
                    style={{
                      fontSize: "0.7em",
                      color: "#000000",
                      lineHeight: "14px",
                    }}
                  >
                    {item?.MEM_id_no}
                  </p>
                  <p
                    style={{
                      fontSize: "0.7em",
                      fontWeight: "400",
                      lineHeight: "14px",
                      wordBreak: "break-word",
                      overflowWrap: "break-word",
                    }}
                  >
                    {item?.name_of_org}
                  </p>
                  <p
                    style={{
                      fontSize: "0.7em",
                      fontWeight: "400",
                      lineHeight: "14px",
                      wordBreak: "break-word",
                      overflowWrap: "break-word",
                    }}
                  >
                    {item?.address}
                  </p>
                  <p
                    style={{
                      fontSize: "0.7em",
                      fontWeight: "400",
                      lineHeight: "14px",
                    }}
                  >
                    {item?.city} - {item?.pincode}
                  </p>
                  <p
                    style={{
                      fontSize: "0.7em",
                      fontWeight: "400",
                      lineHeight: "14px",
                    }}
                  >
                    Phone : {item?.phone_number}
                  </p>
                  <p
                    style={{
                      fontSize: "0.7em",
                      fontWeight: "400",
                      lineHeight: "14px",
                    }}
                  >
                    mobile : {item?.mobile}
                  </p>
                </div>
              ))}
        </div>
      </div>
      <div style={{ display: "none" }}>
        <table ref={memberRefforExcel} border="1">
          <thead>
            <tr>
              <th>Member Id</th>
              <th>Compony Name</th>
              <th>Address</th>
              <th>City</th>
              <th>Pincode</th>
              <th>State</th>
              <th>Office No.</th>
              <th>Fax</th>
              <th>Mobile No1</th>
              <th>Mobile No2</th>
              <th>Mobile No3</th>
              <th>Email</th>
              <th>Name of Representative 1</th>
              <th>Designation of Representative 1</th>
              <th>Name of Representative 2</th>
              <th>Designation of Representative 2</th>
              <th>Name of Representative 3</th>
              <th>Designation of Representative 3</th>
              <th>Product</th>
              <th>Status</th>
              <th>Date of Addmission</th>
              <th>Date of Estd.</th>
              <th>Membership Category</th>
              <th>Member Tag</th>
              <th>Website</th>
              <th>GSTIN</th>
              <th>Cin No.</th>
              <th>PAN</th>
              <th>Proposed By</th>
              <th>Seconded By</th>
              <th>Introducer Name</th>
            </tr>
          </thead>
          <tbody>
            {selectedProducts.length
              ? selectedProducts?.map((val) => (
                  <tr>
                    <td>{val.MEM_id_no}</td>
                    <td>{val.name_of_org}</td>
                    <td>{val?.address}</td>
                    <td>{val?.city}</td>
                    <td>{val?.pincode}</td>
                    <td>{val?.state}</td>
                    <td>{val?.phone_number}</td>
                    <td>{val?.fax}</td>
                    <td>{val?.mobile}</td>
                    <td>{val?.mobile_of_repr2}</td>
                    <td>{val?.mobile_of_repr3}</td>
                    <td>{val?.email}</td>
                    <td>{val?.name_of_representative1}</td>
                    <td>{val?.designation_of_representative1}</td>
                    <td>{val?.name_of_representative2}</td>
                    <td>{val?.designation_of_representative2}</td>
                    <td>{val?.name_of_representative3}</td>
                    <td>{val?.designation_of_representative3}</td>
                    <td>{val?.description}</td>
                    <td>{val?.status}</td>
                    <td>
                      {val?.date_of_admission !== null
                        ? `${moment(val?.date_of_admission).format(
                            "DD-MM-YYYY"
                          )}`
                        : null}
                    </td>
                    <td>
                      {val?.date_of_estd !== null
                        ? `${moment(val?.date_of_estd).format("DD-MM-YYYY")}`
                        : null}
                    </td>
                    <td>{val?.membership_category}</td>
                    <td>{val?.member_category}</td>
                    <td>{val?.website}</td>
                    <td>{val?.GSTIN}</td>
                    <td>{val?.cin_no}</td>
                    <td>{val?.PAN}</td>
                    <td>{val?.proposed_by}</td>
                    <td>{val?.seconded_by}</td>
                    <td>{val?.introducer_name}</td>
                  </tr>
                ))
              : excelData?.map((val) => (
                  <tr>
                    <td>{val.MEM_id_no}</td>
                    <td>{val.name_of_org}</td>
                    <td>{val?.address}</td>
                    <td>{val?.city}</td>
                    <td>{val?.pincode}</td>
                    <td>{val?.state}</td>
                    <td>{val?.phone_number}</td>
                    <td>{val?.fax}</td>
                    <td>{val?.mobile}</td>
                    <td>{val?.mobile_of_repr2}</td>
                    <td>{val?.mobile_of_repr3}</td>
                    <td>{val?.email}</td>
                    <td>{val?.name_of_representative1}</td>
                    <td>{val?.designation_of_representative1}</td>
                    <td>{val?.name_of_representative2}</td>
                    <td>{val?.designation_of_representative2}</td>
                    <td>{val?.name_of_representative3}</td>
                    <td>{val?.designation_of_representative3}</td>
                    <td>{val?.description}</td>
                    <td>{val?.status}</td>
                    <td>
                      {val?.date_of_admission !== null
                        ? `${moment(val?.date_of_admission).format(
                            "DD-MM-YYYY"
                          )}`
                        : null}
                    </td>
                    <td>
                      {val?.date_of_estd !== null
                        ? `${moment(val?.date_of_estd).format("DD-MM-YYYY")}`
                        : null}
                    </td>
                    <td>{val?.membership_category}</td>
                    <td>{val?.member_category}</td>
                    <td>{val?.website}</td>
                    <td>{val?.GSTIN}</td>
                    <td>{val?.cin_no}</td>
                    <td>{val?.PAN}</td>
                    <td>{val?.proposed_by}</td>
                    <td>{val?.seconded_by}</td>
                    <td>{val?.introducer_name}</td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
      <div style={{ display: "none" }}>
        <table ref={cityRefforExcel} border="1">
          <thead>
            <tr>
              <th>City</th>
              <th>Pincode</th>
              <th>Count Of Members</th>
            </tr>
          </thead>
          <tbody>
            {excelDataCityWise?.map((val) => (
              <tr>
                <td>{val.city}</td>
                <td>{val.pincode}</td>
                <td>{val?.num_members}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div style={{ display: "none" }}>
        <table ref={eligibleRef} border="1">
          <thead>
            <tr>
              <th>Member Id</th>
              <th>RFID 1</th>
              <th>RFID 2</th>
              <th>RFID 3</th>
              <th>Compony Name</th>
              <th>Address</th>
              <th>City</th>
              <th>Pincode</th>
              <th>State</th>
              <th>Office No.</th>
              <th>Fax</th>
              <th>Mobile No.</th>
              <th>Email</th>
              <th>Name of Representative 1</th>
              <th>Designation of Representative 1</th>
              <th>Name of Representative 2</th>
              <th>Designation of Representative 2</th>
              <th>Name of Representative 3</th>
              <th>Designation of Representative 3</th>
              <th>Product</th>
            </tr>
          </thead>
          <tbody>
            {EligibleData?.map((val) => (
              <tr>
                <td>{val?.Member_Id}</td>
                <td>{String(val?.RFID_1)}</td>
                <td>{String(val?.RFID_2)}</td>
                <td>{String(val?.RFID_3)}</td>
                <td>{val?.ComponyName}</td>
                <td>{val?.Address}</td>
                <td>{val?.City}</td>
                <td>{val?.Pincode}</td>
                <td>{val?.State}</td>
                <td>{val?.Office_No}</td>
                <td>{val?.Fax}</td>
                <td>{val?.Mobile_No}</td>
                <td>{val?.Email}</td>
                <td>{val?.Name_1}</td>
                <td>{val?.Designation_1}</td>
                <td>{val?.Name_2}</td>
                <td>{val?.Designation_2}</td>
                <td>{val?.Name_3}</td>
                <td>{val?.Designation_3}</td>
                <td>{val?.Product}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ListAllMembers;
