import React, { useEffect, useState } from "react";
import MenuBar from "../../../components/MenuBar";
import events from "../../../assets/events.png";
import { DataView } from "primereact/dataview";
import { Button } from "primereact/button";
import { getAllEvents } from "../../../apis/api";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Logo from "../../../assets/logo1.png";
function AllEvents() {
  const navigate = useNavigate();
  const [allEvents, setAllEvents] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    getAllEvents().then((res) => {
      console.log("res", res);
      setAllEvents(res.data?.results);
      setTotal(res?.data?.total);
    });
  }, []);
  const header = (
    <div
      className="grid"
      style={{
        textAlign: "center",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div style={{ fontSize: "25px" }}>All Events</div>
      <div style={{ fontSize: "13px" }}>Events({total})</div>
    </div>
  );

  const itemTemplate = (data) => {
    return (
      <div className="p-col-12 p-md-2">
        <div className="product-grid-item card" style={{ cursor: "pointer" }}>
          <div className="product-grid-item-top">
            <div>
              <span className="product-category">{data.category}</span>
            </div>
          </div>
          <div className="product-grid-item-content">
            <div style={{ margin: "20px" }}>
              <img
                alt=""
                src={data.media[0] ? data.media[0].link : Logo}
                onError={(e) =>
                  (e.target.src =
                    "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
                }
              />
            </div>
            <div className="product-text">
              <div className="flex justify-content-start align-items-center ml-3">
                <div className="flex justify-content-start align-items-center ">
                  <i
                    style={{ marginRight: "5px", fontSize: "15px" }}
                    className="pi pi-eject"
                  ></i>
                  <div>FKCCI</div>
                </div>
                <div className="flex justify-content-start align-items-center  ml-3">
                  <i
                    style={{ marginRight: "5px", fontSize: "15px" }}
                    className="pi pi-calendar"
                  ></i>
                  <div>
                    {moment(data.event_start).format("DD MMM YYYY")}{" "}
                    {moment(data.event_start).format("h:mm a")}
                  </div>
                </div>
              </div>
              <div
                style={{
                  fontSize: "14px",
                  textAlign: "left",
                  marginLeft: "15px",
                  marginTop: "5px",
                  color: "#191F33",
                }}
              >
                {data?.event_name}
              </div>
              <div
                style={{
                  color: "#FF4F4F",
                  fontSize: "16px",
                  fontWeight: "600",
                  textAlign: "left",
                  marginLeft: "15px",
                  marginTop: "10px",
                }}
              >
                Rs {data?.fee}/-
              </div>
              {/* <div className="product-name">Rs {data.price}/-</div> */}
              <div style={{ margin: "10px" }}>
                <Button
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#2A2B8F",
                  }}
                  onClick={() => navigate("/eventDetails", { state: data })}
                >
                  View Event
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <MenuBar />
      <img alt="" style={{ width: "100%", height: "300px" }} src={events}></img>
      <div
        className="dataview-demo"
        style={{ justifyContent: "center", display: "flex" }}
      >
        <div className="card" style={{ width: "90%" }}>
          <DataView
            paginator
            rows={18}
            layout="grid"
            header={header}
            itemTemplate={itemTemplate}
            value={allEvents}
          />
        </div>
      </div>
    </div>
  );
}

export default AllEvents;
