import React from "react";

function Footer() {
  return (
    <div style={{}}>
      <div
        style={{
          minHeight: "100%",
          height: "100px",
          background: "#2A2B8F",
        }}
      ></div>
    </div>
  );
}

export default Footer;
