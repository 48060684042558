import React, { useEffect, useRef } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import MenuBar from "../../../components/MenuBar";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserProfile, updateUserProfile } from "../../../apis/api";
import { Toast } from "primereact/toast";

function UsersProfile() {
  const [fname, setFname] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [website, setWebsite] = useState("");
  const [desc, setDesc] = useState("");
  const [service, setService] = useState("");
  const [profileImage, setProfileImage] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();
  const toast = useRef(null);
  const data = location.state;
  useEffect(() => {
    setFname(data?.first_name);
    const member = data?.member[0];
    let add = member?.address;
    if (member?.address2 !== null) {
      setAddress2(member?.address2);
    }
    if (member?.address3 !== null) {
      setAddress3(member?.address3);
    }
    setAddress(add);
    if (member?.description !== null) {
      setDesc(member?.description);
    }
    if (member?.services !== null) {
      setService(member?.services);
    }
    if (member?.website !== null) {
      setWebsite(member?.website);
    }
    if (data?.phone_number !== null) {
      setPhone(data?.phone_number);
    }
    setProfileImage(member?.profile_photo);
  }, []);
  const updateUser = async () => {
    let formData = new FormData();
    formData.append("first_name", fname);
    if (address !== "") {
      formData.append("address", address);
    }
    if (address2 !== "") {
      formData.append("address2", address2);
    }
    if (address3 !== "") {
      formData.append("address3", address3);
    }
    if (website !== "") {
      formData.append("website", website);
    }
    if (desc !== "") {
      formData.append("description", desc);
    }
    if (service !== "") {
      formData.append("services", service);
    }
    updateUserProfile(formData)
      .then((res) => {
        // console.log("res", res);
        if (res?.status === 204) {
          toast.current.show({
            severity: "success",
            summary: "Updated successfully",
            detail: "User profile updated!",
            life: 3000,
          });
          setTimeout(() => {
            navigate("/home");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.current.show({
          severity: "error",
          summary: "Error occured",
          detail: "couldn't updated user profile!",
          life: 3000,
        });
      });
  };
  return (
    <div style={{ height: "100vh" }}>
      <Toast ref={toast} />
      <MenuBar />
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          backgroundColor: "#E9E9FF",
          padding: "50px 90px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "50%",
          }}
        >
          <div
            style={{
              backgroundColor: "#767E94",
              height: "116px",
              width: "116px",
              borderRadius: "60px",
              marginRight: "30px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={
                data?.member[0]?.profile_photo ??
                "https://cdn-icons-png.flaticon.com/512/17/17004.png"
              }
              alt="user"
              style={{ height: "100px" }}
            />
          </div>
          <div
            style={{
              fontSize: "16px",
              fontWeight: "600",
              color: "#191F33",
              marginTop: "15px",
            }}
          >
            {fname}
          </div>
          <div
            style={{ display: "flex", alignItems: "center", marginTop: "15px" }}
          >
            <div
              style={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#191F33",
              }}
            >
              Email :
            </div>
            <div
              style={{
                fontSize: "16px",
                fontWeight: "400",
                color: "#636A80",
                marginLeft: "5px",
              }}
            >
              {data?.email}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "50%",
          }}
        ></div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div
          style={{
            maxWidth: "50%",
            display: "flex",
            flexDirection: "column",
            padding: "50px 90px",
          }}
        >
          <div style={{ textAlign: "left", alignItems: "flex-start" }}>
            Full Name
          </div>
          <InputText
            value={fname}
            onChange={(e) => setFname(e.target.value)}
            placeholder="Full Name"
            style={{ width: "17rem", marginTop: "5px" }}
          />
          <div
            style={{
              textAlign: "left",
              alignItems: "flex-start",
              marginTop: "20px",
            }}
          >
            Phone Number
          </div>
          <InputText
            value={phone}
            disabled
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Phone"
            style={{ width: "17rem", marginTop: "5px" }}
          />
          <div
            style={{
              textAlign: "left",
              alignItems: "flex-start",
              marginTop: "20px",
            }}
          >
            Location
          </div>
          <InputText
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            placeholder="Address"
            style={{ width: "17rem", marginTop: "5px" }}
          />
          <InputText
            value={address2}
            onChange={(e) => setAddress2(e.target.value)}
            placeholder="Address 2"
            style={{ width: "17rem", marginTop: "5px" }}
          />
          <InputText
            value={address3}
            onChange={(e) => setAddress3(e.target.value)}
            placeholder="Address 3"
            style={{ width: "17rem", marginTop: "5px" }}
          />
        </div>
        <div
          style={{
            maxWidth: "50%",
            display: "flex",
            flexDirection: "column",
            padding: "50px 90px",
          }}
        >
          <div style={{ textAlign: "left", alignItems: "flex-start" }}>
            Website Links (optional)
          </div>
          <InputText
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
            placeholder="Website"
            style={{ width: "17rem", marginTop: "5px" }}
          />
          <div
            style={{
              textAlign: "left",
              alignItems: "flex-start",
              marginTop: "20px",
            }}
          >
            Description
          </div>
          <InputText
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
            placeholder="Description"
            style={{
              width: "17rem",
              marginTop: "5px",
              verticalAlign: "text-top",
            }}
          />
          <div
            style={{
              textAlign: "left",
              alignItems: "flex-start",
              marginTop: "20px",
            }}
          >
            Services
          </div>
          <InputText
            value={service}
            onChange={(e) => setService(e.target.value)}
            placeholder="Services"
            style={{ width: "17rem", marginTop: "5px" }}
          />
          <Button
            className="w-12"
            onClick={() => {
              updateUser();
            }}
            style={{
              background: "#2A2B8F",
              borderRadius: "12px",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <div
              style={{
                fontSize: "16px",
                fontWeight: "600",
              }}
            >
              Save Changes
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default UsersProfile;
