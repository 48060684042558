import React, { useState, useEffect } from "react";
import AdminMenuBar from "../AdminMenuBar";
import { TabMenu } from "primereact/tabmenu";
import { useNavigate, useLocation, Outlet } from "react-router-dom";

function Journals() {
  const userType = window.localStorage.getItem("userType");
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  let location = useLocation();
  console.log(location.pathname.split("/")[2]);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (location.pathname.split("/")[2] === "allJournals") {
      setActiveIndex(0);
    }
    if (location.pathname.split("/")[2] === "AddNewJournal") {
      setActiveIndex(1);
    }
  }, [location.pathname]);
  useEffect(() => {
    setLoading(false);
  }, [isLoading]);

  const items = [
    { label: "All Journals", icon: "pi pi-fw pi-pencil" },
    
  ];

  if (userType === "Office User") {
    items.push({
      label: "Add Journal",
      icon: "pi pi-fw pi-home",
    });
  }
  return (
    <div>
      <AdminMenuBar />
      <TabMenu
        activeIndex={activeIndex}
        onTabChange={(e) => {
          if (e.index === 0) {
            setActiveIndex(e.index);
            setLoading(true);
            navigate("allJournals");
          }
          if (e.index === 1) {
            setActiveIndex(e.index);
            setLoading(true);
            navigate("AddNewJournal");
          }
        }}
        className="m-3"
        model={items}
      />
      <Outlet />
    </div>
  );
}

export default Journals;
