import React from "react";
import MemberProfile from "./components/MemberProfile";
import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  cancelInvoice,
  generateInvoice,
  getInvoiceIsPaid,
  getMemberDetail,
  sendInvoiceToEmail,
  updateMemberCategory,
} from "../../../../../apis/api";
import { Button } from "primereact/button";
import Invoice from "./components/Invoice";
import { useReactToPrint } from "react-to-print";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { setRefresh } from "../../../../../redux/member/memberReducer";
import moment from "moment";
import { RadioButton } from "primereact/radiobutton";
import { Toast } from "primereact/toast";
import { pdf, Document, Page, PDFViewer, View } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import DownloadInvoice from "./components/DownloadInvoice";
import { ProgressSpinner } from "primereact/progressspinner";
import { Checkbox } from "primereact/checkbox";
import "./progressiveSpinner.css";
import MemberShipDescription from "../../../../customers/users/MemberShipDescription";
import MemberDescription from "./components/MemberDescription";

function MemberDetails(prop) {
  const [data, setData] = useState([]);
  const [invoiceData, setInvoiceData] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [update, setUpdate] = useState(false);
  const id = location.state;
  const invoiceRef = useRef(null);
  const [displayBasic1, setDisplayBasic1] = useState(false);
  const [displayBasic2, setDisplayBasic2] = useState(false);
  const [position, setPosition] = useState("center");
  const [paymentType, setPaymentType] = useState("");
  const [dateOfPayment, setDateOfPayment] = useState("");
  const [bank, setBank] = useState("");
  const [invoiceType, setInvoiceType] = useState("");
  const [isPaid, setIsPaid] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const dispatch = useDispatch();
  const [total, setTotal] = useState(0);
  const [addTotal, setAddTotal] = useState(0);
  const [intTotal, setIntTotal] = useState(0);
  const [igstTotal, setIgstTotal] = useState(0);
  const [miscTotal, setMiscTotal] = useState(0);
  const [selectedBank, setSelectedBank] = useState("");
  const [fYear, setFYear] = useState("");
  const [current, setCurrent] = useState([{ name: "", code: "" }]);
  const toast = useRef(null);
  const [bankName, setBankName] = useState("");
  const [receiptNo, setReceiptNo] = useState("");
  const [startDate, setStartDate] = useState("2024");
  const [endDate, setEndDate] = useState("2025");
  const [yearDiff, setYearDiff] = useState(1);
  const [subscriptionAmnt, setSubscriptionAmnt] = useState(null);
  const [memberShipCategory, setMemberShipCategory] = useState("");
  const [memberCategory, setMemberCategory] = useState("");
  const [customAmount, setCustomAmount] = useState(false);

  const [loader, setLoader] = useState(false);
  const styles = {
    border: "1px solid #000000",
    width: "200px",
    paddingRight: "5px",
    paddingTop: "10px",
    marginTop: "2px",
    textAlign: "right",
  };
  const styles5 = {
    border: "1px solid #000000",
    width: "150px",
    paddingRight: "5px",
    paddingTop: "10px",
    marginTop: "2px",
    textAlign: "right",
  };
  const styles4 = {
    border: "1px solid #000000",
    width: "153px",
    marginTop: "2px",
    textAlign: "right",
  };
  const styles3 = {
    border: "1px solid red",
    width: "200px",
    paddingRight: "5px",
    paddingTop: "10px",
    marginTop: "2px",
    textAlign: "right",
    color: "red",
  };
  const styles2 = {
    width: "454px",
    border: "1px solid #000000",
    borderRadius: "0px",
    marginLeft: "2px",
    textAlign: "left",
    marginTop: "2px",
  };
  const dialogFuncMap = {
    displayBasic1: setDisplayBasic1,
    displayBasic2: setDisplayBasic2,
  };
  const onClick2 = async (name, position) => {
    await dialogFuncMap[`${name}`](true);
    if (position) {
      setPosition(position);
    }
  };
  const onClick = async (name, position) => {
    const data = await getInvoiceIsPaid(id);
    console.log(data, "paid status");
    if (data[0].already_paid) {
      setIsPaid(true);
      setIsUpdated(false);
      setDetails(data);
      setInvoiceData(data[0]);
    } else if (data[0].updated_member) {
      setIsUpdated(true);
      setIsPaid(false);
      setDetails(data);
      setInvoiceData(data[0]);
    } else {
      setDetails(data);
      setIsPaid(false);
      setIsUpdated(false);
    }
    await dialogFuncMap[`${name}`](true);
    if (position) {
      setPosition(position);
    }
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };

  const rePrint = async () => {
    if (invoiceData !== null) {
      setLoader(true);
      try {
        const doc = (
          <Document>
            <Page size={"A4"} style={{}}>
              <View>
                <DownloadInvoice
                  data={invoiceData}
                  isDuplicate={"TAX INVOICE"}
                  second={false}
                />
                {/* <DownloadInvoice data={res[0]} isDuplicate={"TAX INVOICE (Duplicate for Supplier)"} second={true}/> */}
              </View>
            </Page>
          </Document>
        );
        const blob = await pdf(doc).toBlob();
        let newData = new FormData();
        newData.append("invoice", blob);
        // sendInvoiceToEmail(id, newData).then(res => {
        //   if (res.status === 200) {
        //     console.log('res', res?.data)
        //   }

        // }).catch(err => {
        //   console.log('err', err)
        // })

        setTimeout(() => {
          setLoader(false);
          onHide("displayBasic1");
          handleInvoicePrint();
        }, 1000);
      } catch (error) {
        console.log("error", error);
      }
    } else {
      console.log("invoice data is empty");
    }
  };
  const cancel = async () => {
    cancelInvoice(id)
      .then((res) => {
        onHide("displayBasic1");
        return res;
      })
      .then(async (res) => {
        if (res[0]?.cancelled) {
          toast.current.show({
            severity: "success",
            summary: "Invoice  Cancelled!",
            detail: "Invoice cancelled successfully!",
            life: 5000,
          });
        }
      });
  };
  // console.log(id);
  const getInvoice = async (type, data) => {
    console.log("res", type);
    setLoader(true);
    generateInvoice(id, type, data)
      .then((res) => {
        return res;
      })
      .then(async (res) => {
        console.log("res", res[0]);
        setInvoiceData(res[0]);

        if (res[0]?.already_paid) {
          toast.current.show({
            severity: "success",
            summary: "Invoice Already generated!",
            detail: "There is no latest data previous data will be show!",
            life: 5000,
          });
        } else {
          if (res[0]?.payment_details?.length) {
            try {
              const doc = (
                <Document>
                  <Page size={"A4"} style={{}}>
                    <View>
                      <DownloadInvoice
                        data={res[0]}
                        isDuplicate={"TAX INVOICE"}
                        second={false}
                      />
                      {/* <DownloadInvoice data={res[0]} isDuplicate={"TAX INVOICE (Duplicate for Supplier)"} second={true}/> */}
                    </View>
                  </Page>
                </Document>
              );
              const blob = await pdf(doc).toBlob();
              let newData = new FormData();
              newData.append("invoice", blob);
              sendInvoiceToEmail(id, newData)
                .then((res) => {
                  if (res.status === 200) {
                    console.log("res", res?.data);
                    setTimeout(() => {
                      setLoader(false);
                      onHide("displayBasic1");
                      handleInvoicePrint();
                    }, 1000);
                  }
                })
                .catch((err) => {
                  console.log("err", err);
                  setLoader(false);
                });
            } catch (error) {
              console.log("error", error);
              setLoader(false);
            }
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoader(false);
      });
  };
  const formik = useFormik({
    initialValues: {
      memberId: "",
      org_name: "",
      place: "",
      pincode: "",
      gstNo: "",
      paymentType: "",
      date_of_payment: "",
      cheque_number: "",
      bank_name: "",
      financial_year: "",
      invoice_type: "",
      towards: "Membership Subscription for the Year 2024-2025",
      subscription_amount: "",
      admission_amount: "0",
      interest_amount: "0",
      taxable_amount: "0",
      misc_amount: "0",
      paid_amount: "",
      sgst: "",
      cgst: "",
      igst: "0",
      remark: "",
      start: "",
      end: "",
      subscription_year: "",
    },
    validate: (data) => {
      let errors = {};
      // if (!data.memberId) {
      //   errors.memberId = "This Field is Required.";
      // }
      // if (!data.org_name) {
      //   errors.org_name = "This Field is Required.";
      // }
      // if (!data.place) {
      //   errors.place = "This Field is Required.";
      // }
      // if (!data.pincode) {
      //   errors.pincode = "This Field is Required.";
      // }
      // if (!data.gstNo) {
      //   errors.gstNo = "This Field is Required.";
      // }
      if (!data.paymentType) {
        errors.paymentType = "This Field is Required.";
      }
      if (!data.date_of_payment) {
        errors.date_of_payment = "This Field is Required.";
      }
      if (!data.bank_name) {
        errors.bank_name = "This Field is Required.";
      }

      if (!data.invoice_type) {
        errors.invoice_type = "This Field is Required.";
      }
      if (!data.towards) {
        errors.towards = "This Field is Required.";
      }

      if (!data.subscription_amount) {
        errors.subscription_amount = "This Field is Required.";
      }
      // if (!data.start) {
      //   errors.start = "This Field is Required.";
      // }
      // if (!data.end) {
      //   errors.end = "This Field is Required.";
      // }
      return errors;
    },

    onSubmit: async (data) => {
      const {
        memberId,
        org_name,
        pincode,
        place,
        gstNo,
        start,
        end,
        paymentType,
        ...extraData
      } = data;
      console.log("data", extraData);
      const registerData = {};

      Object.keys(extraData)
        .filter((item) => data[item].length !== 0)
        .map((key) => {
          registerData[key] = data[key];
        });

      console.log("data2", registerData);
      let newData = {};
      if (data.paymentType === "NEFT") {
        newData = {
          ...registerData,
          transactional_id: data.cheque_number,
        };
      } else {
        newData = { ...registerData };
      }
      console.log("extraData", newData);
      getInvoice(data.paymentType.toLowerCase(), newData);
    },
  });
  // const membershipCategory = [
  //   {
  //     category: "Small Scale Manufacturing Activity",
  //     odSubamount: 2000,
  //     addAmount: 1000,
  //     patSubAmount: 36000,
  //   },
  //   {
  //     category: "Large Scale Manufacturing Activity",
  //     odSubamount: 5000,
  //     addAmount: 1000,
  //     patSubAmount: 60000,
  //   },
  //   {
  //     category: "Small Scale Trading Activity",
  //     odSubamount: 2000,
  //     addAmount: 1000,
  //     patSubAmount: 36000,
  //   },
  //   {
  //     category: "Large Scale Trading Activity",
  //     odSubamount: 5000,
  //     addAmount: 1000,
  //     patSubAmount: 60000,
  //   },
  //   {
  //     category: "Small Scale Service Activity",
  //     odSubamount: 2000,
  //     addAmount: 1000,
  //     patSubAmount: 36000,
  //   },
  //   {
  //     category: "Large Scale Service Activity",
  //     odSubamount: 5000,
  //     addAmount: 1000,
  //     patSubAmount: 60000,
  //   },
  //   {
  //     category: "District Chambers Of Commerce",
  //     odSubamount: 4000,
  //     addAmount: 1000,
  //     patSubAmount: 90000,
  //   },
  //   {
  //     category: "Association",
  //     odSubamount: 4000,
  //     addAmount: 1000,
  //     patSubAmount: 90000,
  //   },
  //   {
  //     category: "Profession",
  //     odSubamount: 2000,
  //     addAmount: 1000,
  //     patSubAmount: 36000,
  //   },
  // ];
  const membershipCategoryOptions = [
    "Small Scale Manufacturing Activity",
    "Large Scale Manufacturing Activity",
    "Small Scale Trading Activity",
    "Large Scale Trading Activity",
    "Small Scale Service Activity",
    "Large Scale Service Activity",
    "District Chambers Of Commerce",
    "Association",
    "Profession",
  ];
  const memberOptions = ["ordinary", "patron"];
  const fillData = (data) => {
    console.log("fill", data);
    formik.values.memberId = data.MEM_id_no;
    formik.values.org_name = data?.name_of_org;
    formik.values.place = data?.city;
    formik.values.pincode = data?.pincode;
    formik.values.gstNo = data?.GSTIN;
    // const membership_category = membershipCategory.find(
    //   (item) => item.category === data.membership_category
    // );
    // if (data.member_category === "ordinary") {
    if (formik.values.invoice_type === "New Admission") {
      formik.values.admission_amount = 1000;
    } else {
      formik.values.admission_amount = 0;
    }
    // setSubscriptionAmnt(membership_category.odSubamount);
    // formik.values.subscription_amount = membership_category.odSubamount;
    // } else {
    //   // setSubscriptionAmnt(membership_category.patSubAmount);
    //   formik.values.admission_amount = 1000;
    //   // formik.values.subscription_amount = membership_category.patSubAmount;
    // }
  };
  const setDetails = async (data) => {
    console.log(data, "setDetailss");
    if (data[0].already_paid) {
      formik.values.subscription_amount =
        data[0]?.payment_details[0]?.subscription_amount;
      console.log(formik.values.subscription_amount, "okoko");
      formik.values.admission_amount =
        data[0]?.payment_details[0]?.admission_amount;
      formik.values.interest_amount =
        data[0]?.payment_details[0]?.interest_amount;
      setPaymentType(data[0]?.payment_details[0]?.payment_type);
      console.log(paymentType, "jjjjjj");
      formik.values.payment_type = data[0]?.payment_details[0]?.payment_type;
      // if (paymentType === "NEFT") {
      formik.values.cheque_number =
        data[0]?.payment_details[0]?.transactional_id ||
        data[0]?.payment_details[0]?.cheque_number;
      // } else {
      //   formik.values.cheque_number = data[0]?.payment_details[0]?.cheque_number||123;
      // }
      formik.values.towards = data[0]?.payment_details[0]?.towards;
      formik.values.misc_amount = data[0]?.payment_details[0]?.misc_amount;
      formik.values.paid_amount = data[0]?.payment_details[0]?.paid_amount;
      setDateOfPayment(
        moment(data[0]?.payment_details[0]?.date_of_payment).format(
          "YYYY-MM-DD"
        )
      );
      setBank(data[0]?.payment_details[0]?.bank_name);
      setInvoiceType(data[0]?.payment_details[0]?.invoice_type);
      formik.values.remark = data[0]?.payment_details[0]?.remark;
      setSubscriptionAmnt(data[0]?.payment.subcriptionAmount);
    } else if (data[0].updated_member) {
      formik.values.subscription_amount = data[0]?.payment.subcriptionAmount;
      formik.values.remark = "";
      formik.values.cheque_number = "";
      formik.values.paymentType = "";
      formik.values.date_of_payment = "";
      setBankName("");
      setSubscriptionAmnt(data[0]?.payment.subcriptionAmount);
    } else {
      formik.values.subscription_amount = data[0]?.payment.subcriptionAmount;
      setSubscriptionAmnt(data[0]?.payment.subcriptionAmount);
    }
  };
  useEffect(() => {
    console.log("prop", prop.history);
    // getInvoice();
  }, []);
  const setFinancialYear = () => {
    const date = new Date();
    const currentMonth = date.getMonth() + 1; // Note: getMonth() returns zero-based month (0 for January)
    const currentYear = date.getFullYear();
    
    let financialYearStart;
    if (currentMonth >= 4) {
        // If the current month is April or later, the financial year has started
        financialYearStart = currentYear;
    } else {
        // If the current month is before April, the financial year starts from the previous year
        financialYearStart = currentYear - 1;
    }

    const financialYearEnd = financialYearStart + 1;
    
    formik.values.financial_year = financialYearStart;
    setFYear(`${financialYearStart}-${financialYearEnd}`);
};
  useEffect(() => {
    getMemberDetail(id).then((res) => {
      setData(res);
      fillData(res);
      setMemberCategory(res?.member_category);
      setMemberShipCategory(res?.membership_category);
    });
    setFinancialYear();
    prop.history.listen((loc, action) => {
      console.log("donee", loc);
      if (loc.action === "POP") {
        dispatch(setRefresh(false));
      }
    });
  }, [id, update]);
  const handleInvoicePrint = useReactToPrint({
    content: () => invoiceRef.current,
  });

  formik.values.taxable_amount = Number(
    Number(formik.values.subscription_amount) +
      Number(formik.values.admission_amount) +
      Number(formik.values.interest_amount)
  ).toFixed(2);
  formik.values.cgst = Number(
    Number(formik.values.taxable_amount) * 0.09
  ).toFixed(2);
  formik.values.sgst = Number(
    Number(formik.values.taxable_amount) * 0.09
  ).toFixed(2);
  if (formik.values.igst > 0) {
    formik.values.cgst = 0;
    formik.values.sgst = 0;
  }
  formik.values.paid_amount = Number(
    Number(formik.values.taxable_amount) +
      Number(formik.values.cgst) +
      Number(formik.values.sgst) +
      Number(formik.values.igst) +
      Number(formik.values.misc_amount)
  ).toFixed(2);
  const paymentTypeOptions = ["Cheque", "NEFT"];
  const towardsOption = ["Membership Subscription for the Year 2024-2025"];
  const renderUploadFooter = (name) => {
    return (
      <div>
        {/* <Button
          label="No"
          icon="pi pi-times"
          onClick={() => {
            onHide(name);
          }}
          className="p-button-text"
        />
        <Button
          label="Generate"
          icon="pi pi-check"
          onClick={() => {
            handleInvoicePrint();
          }}
          autoFocus
        /> */}
      </div>
    );
  };
  const bankData = [
    {
      name: "Allahabad Bank",
      code: "ALLA",
    },
    {
      name: "Andhra Bank",
      code: "ANDB",
    },
    {
      name: "AU Small Finance Bank",
      code: "AUBL",
    },
    {
      name: "Axis Bank",
      code: "UTIB",
    },
    {
      name: "Bandhan Bank",
      code: "BDBL",
    },
    {
      name: "Bank Of Baroda",
      code: "BARB_R",
    },
    {
      name: "Bank Of India",
      code: "BKID",
    },
    {
      name: "Bank of Maharashtra",
      code: "MAHB",
    },
    {
      name: "Catholic Syrian Bank",
      code: "CSBK",
    },
    {
      name: "Canara Bank",
      code: "CNRB",
    },
    {
      name: "Central Bank of India",
      code: "CBIN",
    },
    {
      name: "City Union Bank",
      code: "CIUB",
    },
    {
      name: "Corporation Bank",
      code: "CORP",
    },
    {
      name: "Deutsche Bank AG",
      code: "DEUT",
    },
    {
      name: "DCB",
      code: "DCBL",
    },
    {
      name: "Dhanalaxmi bank",
      code: "DLXB",
    },
    {
      name: "Equitas Small Finance Bank",
      code: "ESFB",
    },
    {
      name: "Federal Bank",
      code: "FDRL",
    },
    {
      name: "HDFC Bank",
      code: "HDFC",
    },
    {
      name: "ICICI Bank",
      code: "ICIC",
    },
    {
      name: "IDBI Bank",
      code: "IBKL",
    },
    {
      name: "IDFC First Bank",
      code: "IDFB",
    },
    {
      name: "Indian Bank",
      code: "IDIB",
    },
    {
      name: "Indian Overseas Bank",
      code: "IOBA",
    },
    {
      name: "IndusInd Bank",
      code: "INDB",
    },
    {
      name: "Jammu & Kashmir Bank",
      code: "JAKA",
    },
    {
      name: "Karnataka Bank",
      code: "KARB",
    },
    {
      name: "Karur Vysya Bank",
      code: "KVBL",
    },
    {
      name: "Kotak Mahindra Bank",
      code: "KKBK",
    },
    {
      name: "Punjab National Bank",
      code: "PUNB_R",
    },
    {
      name: "Saraswat Bank",
      code: "SRCB",
    },
    {
      name: "South Indian Bank",
      code: "SIBL",
    },
    {
      name: "Standard Chartered",
      code: "SCBL",
    },
    {
      name: "State Bank Of India",
      code: "SBIN",
    },
    {
      name: "SVC Co-operative Bank",
      code: "SVCB",
    },
    {
      name: "Tamilnad Mercantile Bank",
      code: "TMBL",
    },
    {
      name: "The Lakshmi Vilas Bank Limited",
      code: "LAVB_R",
    },
    {
      name: "The Ratnakar Bank Limited (RBL)",
      code: "RATN",
    },
    {
      name: "The Saraswat Co-Operative Bank",
      code: "SRCB",
    },
    {
      name: "Union Bank of India",
      code: "UBIN",
    },
    {
      name: "UCO Bank",
      code: "UCBA",
    },
    {
      name: "Yes Bank",
      code: "YESB",
    },
  ];
  const selectedBankTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const bankOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{option.name}</div>
      </div>
    );
  };
  const options = bankData.some((data) => {
    return data.name.toLowerCase().includes(current.name);
  })
    ? bankData
    : [...bankData, current];
  const showError = (message) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: message,
      life: 3000,
    });
  };
  const handleCategoryUpdate = async () => {
    if (
      memberCategory === data?.member_category &&
      memberShipCategory === data?.membership_category
    ) {
      showError("There is nothing to update!");
      onHide("displayBasic2");
    } else {
      let category = {};
      if (memberCategory !== data?.member_category) {
        category = { ...category, member_category: memberCategory };
      }
      if (memberShipCategory !== data?.membership_category) {
        category = { ...category, membership_category: memberShipCategory };
      }
      console.log("catData", category);
      updateMemberCategory(category, data?.id)
        .then((res) => {
          console.log("res", res);
          if (res?.status === 200) {
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: "Member Details are updated!",
              life: 3000,
            });
            onHide("displayBasic2");
          }
        })
        .catch((err) => {
          console.log("error", err);
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Failed to update member details!",
            life: 3000,
          });
          onHide("displayBasic2");
        })
        .finally((res) => {
          setUpdate(!update);
        });
    }
  };
  if (!customAmount) {
    if (startDate !== null && endDate !== null) {
      if (startDate > endDate) {
        showError("Start year should be Less than Ending year ");
      } else {
        // console.log(moment(startDate).format('yyyy'),moment(endDate).format('yyyy'))
        formik.values.towards =
          "Membership Subscription for the Year " +
          moment(startDate).format("yyyy") +
          "-" +
          moment(endDate).format("yyyy");
        formik.values.start = moment(startDate).format("yyyy");
        formik.values.end = moment(endDate).format("yyyy");
        formik.values.subscription_amount = Number(
          Number(subscriptionAmnt) *
            Number(Number(formik.values.end) - Number(formik.values.start))
        ).toFixed(2);
        console.log(
          formik.values.subscription_amount,
          Number(Number(formik.values.end) - Number(formik.values.start))
        );
        // formik.values.subscription_amount=Number(formik.values.subscription_amount)*(Number(formik.values.end)-Number(formik.values.start))
        // setYearDiff(Number(formik.values.end)-Number(formik.values.start))
        const arr = [];
        const start = Number(formik.values.start);
        const end = Number(formik.values.end);
        for (let i = start; i < end; i++) {
          const subYear = Number(i + 1);
          arr.push(subYear);
        }
        console.log("subYear", arr);
        formik.values.subscription_year = arr;
      }
    } else {
      const arr = [];
      const start = Number(formik.values.start);
      const end = Number(formik.values.end);
      for (let i = start; i < end; i++) {
        const subYear = Number(i + 1);
        arr.push(subYear);
      }
      console.log("subYear", arr);
      formik.values.subscription_year = arr;
    }
  }

  return (
    <div>
      <Dialog
        visible={loader}
        // onHide={hideOverlay}
        // modal
        showHeader={false}
        contentStyle={{ padding: 0, border: "none", background: "transparent" }}
        className="spinner-dialog"
      >
        <div
          className="blue-progress-spinner"
          style={{ overflow: "hidden", background: "transparent" }}
        >
          <ProgressSpinner
            style={{ margin: "0 auto", background: "transparent" }}
          />
        </div>
      </Dialog>
      {/* {
        loader? 
          <div className="card flex justify-content-center">
        <div className="blue-progress-spinner">
          <ProgressSpinner />
        </div>
       </div>
       : null
      } */}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          paddingRight: "15px",
          gap: "20px",
        }}
      >
        {/* <Button
          onClick={(e) =>
            navigate("/userManagement/addNewMember", {
              state: { isUpdate: true, data: data },
            })
          }
          className="w-11rem  h-2rem font-light "
          label="Update Member"
        ></Button> */}
        <Button
          onClick={(e) => {
            // handleInvoicePrint();
            onClick2("displayBasic2");
          }}
          className="p-button-outlined w-12rem  h-2rem ml-3 font-light "
          label="Update Category"
        ></Button>
        <Button
          onClick={(e) => {
            // handleInvoicePrint();
            onClick("displayBasic1");
          }}
          className="p-button-outlined w-12rem  h-2rem ml-3 font-light "
          label="Generate Invoice"
        ></Button>
      </div>
      <Toast ref={toast}></Toast>
      <div>
        <div className="justify-content-center  m-2  card-container yellow-container">
          {/* <div className="w-4 border-round-2xl  flex justify-content-center align-items-center">
            <MemberProfile
              status={data.status}
              data={data.media?.slice(0, 4)}
              size={180}
            />
          </div> */}

          {/* <div className="border-round-2xl flex justify-content-center align-items-center"> */}
          <MemberDescription
            update={update}
            setUpdate={setUpdate}
            data={data}
            rfid={null}
            scanRfid={false}
          />
          {/* </div> */}
        </div>

        <div style={{ display: "none" }}>
          <div
            style={{
              display: "block",
              height: "100%",
              padding: "5px 10px 0 10px",
              // justifyContent: "space-between",
            }}
            ref={invoiceRef}
          >
            {invoiceData !== "" ? (
              <diV>
                <Invoice data={invoiceData} isDuplicate={false} />
                <Invoice data={invoiceData} isDuplicate={true} />
              </diV>
            ) : null}
          </div>
        </div>

        <Dialog
          header="Generate Invoice"
          visible={displayBasic1}
          breakpoints={{ "960px": "75vw" }}
          style={{ width: "50vw" }}
          footer={renderUploadFooter("displayBasic1")}
          onHide={() => onHide("displayBasic1")}
        >
          <form onSubmit={formik.handleSubmit}>
            <div>
              <div className="flex">
                {formik.errors.memberId ? (
                  <div className="text-l align-items-center" style={styles3}>
                    Member ID* :
                  </div>
                ) : (
                  <div className="text-l align-items-center" style={styles}>
                    Member ID* :
                  </div>
                )}
                <InputText
                  style={{
                    width: "454px",
                    border: "1px solid #000000",
                    borderRadius: "0px",
                    marginLeft: "2px",
                    textAlign: "left",
                    marginTop: "2px",
                  }}
                  id="memberId"
                  disabled
                  value={formik.values.memberId}
                  onChange={formik.handleChange}
                ></InputText>
                {/* {formik.errors.receipt_no ? (
                  <div
                    className="text-l align-items-center"
                    style={{
                      border: "1px solid red",
                      width: "150px",
                      padding: "10px",
                      paddingTop: "10px",
                      marginTop: "2px",
                      textAlign: "right",
                      marginLeft: "2px",
                      color: "red",
                    }}
                  >
                    Receipt No. * :
                  </div>
                ) : (
                  <div
                    className="text-l align-items-center"
                    style={{
                      border: "1px solid #000000",
                      width: "150px",
                      padding: "10px",
                      paddingTop: "10px",
                      marginTop: "2px",
                      textAlign: "right",
                      marginLeft: "2px",
                    }}
                  >
                    Receipt No. * :
                  </div>
                )}
                <InputText
                  style={{
                    width: "150px",
                    border: "1px solid #000000",
                    borderRadius: "0px",
                    marginLeft: "2px",
                    textAlign: "left",
                    marginTop: "2px",
                  }}
                  id="receipt_no"
                  value={formik.values.receipt_no}
                  onChange={formik.handleChange}
                ></InputText> */}
              </div>
              {invoiceData?.payment_details[0]?.receipt_no ? (
                <div className="flex">
                  <div className="text-l align-items-center" style={styles}>
                    Receipt NO :
                  </div>
                  <InputText
                    style={{
                      width: "454px",
                      border: "1px solid #000000",
                      borderRadius: "0px",
                      marginLeft: "2px",
                      textAlign: "left",
                      marginTop: "2px",
                    }}
                    id="memberId"
                    disabled
                    value={invoiceData?.payment_details[0]?.receipt_no}
                    // onChange={formik.handleChange}
                  ></InputText>
                </div>
              ) : null}
              <div className="flex">
                {formik.errors.org_name ? (
                  <div className="text-l align-items-center" style={styles3}>
                    Company Name * :
                  </div>
                ) : (
                  <div className="text-l align-items-center" style={styles}>
                    Company Name * :
                  </div>
                )}
                <InputText
                  style={styles2}
                  id="org_name"
                  value={formik.values.org_name}
                  onChange={formik.handleChange}
                  disabled
                ></InputText>
              </div>
              <div className="flex">
                {formik.errors.place ? (
                  <div className="text-l align-items-center" style={styles3}>
                    Place * :
                  </div>
                ) : (
                  <div className="text-l align-items-center" style={styles}>
                    Place * :
                  </div>
                )}
                <InputText
                  style={styles2}
                  id="place"
                  disabled
                  value={formik.values.place}
                  onChange={formik.handleChange}
                ></InputText>
              </div>
              <div className="flex">
                {formik.errors.pincode ? (
                  <div className="text-l align-items-center" style={styles3}>
                    Pincode * :
                  </div>
                ) : (
                  <div className="text-l align-items-center" style={styles}>
                    Pincode * :
                  </div>
                )}
                <InputText
                  style={styles2}
                  id="pincode"
                  disabled
                  value={formik.values.pincode}
                  onChange={formik.handleChange}
                ></InputText>
              </div>
              <div className="flex">
                {formik.errors.gstNo ? (
                  <div className="text-l align-items-center" style={styles3}>
                    GST/UIN No.* :
                  </div>
                ) : (
                  <div className="text-l align-items-center" style={styles}>
                    GST/UIN No.* :
                  </div>
                )}
                <InputText
                  style={styles2}
                  id="gstNo"
                  disabled
                  value={formik.values.gstNo}
                  onChange={formik.handleChange}
                ></InputText>
              </div>
              <div className="flex">
                {formik.errors.paymentType ? (
                  <div className="text-l align-items-center" style={styles3}>
                    Payment Type * :
                  </div>
                ) : (
                  <div className="text-l align-items-center" style={styles}>
                    Payment Type * :
                  </div>
                )}

                <Dropdown
                  showClear
                  id="paymentType"
                  key={formik.values.paymentType}
                  value={formik.values.paymentType}
                  options={paymentTypeOptions}
                  onChange={formik.handleChange}
                  placeholder={isPaid ? paymentType : "Not selected"}
                  // editable
                  disabled={isPaid}
                  style={styles2}
                />
              </div>
              <div className="flex">
                <div className="text-l align-items-center" style={styles}>
                  {formik.values.paymentType !== "NEFT"
                    ? "Cheque/DD No."
                    : "Transaction ID"}
                </div>
                <InputText
                  style={{
                    width: "180px",
                    border: "1px solid #000000",
                    borderRadius: "0px",
                    marginLeft: "2px",
                    textAlign: "left",
                    marginTop: "2px",
                  }}
                  id="cheque_number"
                  value={formik.values.cheque_number}
                  onChange={formik.handleChange}
                  disabled={isPaid}
                  autoComplete="off"
                ></InputText>
                {formik.errors.date_of_payment ? (
                  <div
                    className="text-l align-items-center"
                    style={{
                      border: "1px solid red",
                      width: "100px",
                      padding: "10px",
                      paddingTop: "10px",
                      marginTop: "2px",
                      textAlign: "right",
                      marginLeft: "2px",
                      color: "red",
                    }}
                  >
                    Date * :
                  </div>
                ) : (
                  <div
                    className="text-l align-items-center"
                    style={{
                      border: "1px solid #000000",
                      width: "100px",
                      padding: "10px",
                      paddingTop: "10px",
                      marginTop: "2px",
                      textAlign: "right",
                      marginLeft: "2px",
                    }}
                  >
                    Date * :
                  </div>
                )}

                <Calendar
                  style={{
                    width: "170px",
                    border: "1px solid #000000",
                    borderRadius: "0px",
                    marginLeft: "2px",
                    textAlign: "left",
                    marginTop: "2px",
                  }}
                  id="date_of_payment"
                  disabled={isPaid}
                  dateFormat="dd-mm-yy"
                  // value={moment(formik.values.date_of_payment).format("DD-MM-YYYY")}
                  value={
                    isPaid
                      ? moment(dateOfPayment).format("DD-MM-YYYY")
                      : moment(formik.values.date_of_payment).format(
                          "DD-MM-YYYY"
                        )
                  }
                  onChange={(e) => {
                    const selectedDate = moment(e.value).format("YYYY-MM-DD");
                    const currentTime = moment().format("HH:mm:ss");
                    const currentDateTime = moment(
                      selectedDate + " " + currentTime,
                      "YYYY-MM-DD HH:mm:ss"
                    ).format("YYYY-MM-DD HH:mm:ss");
                    console.log("event", currentDateTime);
                    formik.setFieldValue("date_of_payment", currentDateTime);
                  }}
                  placeholder={
                    isPaid ? moment(dateOfPayment).format("DD-MM-YYYY") : null
                  }
                />
              </div>
              <div className="flex">
                {formik.errors.bank_name ? (
                  <div className="text-l align-items-center" style={styles3}>
                    Bank * :
                  </div>
                ) : (
                  <div className="text-l align-items-center" style={styles}>
                    Bank * :
                  </div>
                )}
                {/* <InputText
                  style={{
                    width: "454px",
                    border: "1px solid #000000",
                    borderRadius: "0px",
                    marginLeft: "2px",
                    textAlign: "left",
                    marginTop: "2px",
                  }}

                  // placeholder="Bank Address"
                ></InputText> */}
                <Dropdown
                  style={{
                    width: "454px",
                    border: "1px solid #000000",
                    borderRadius: "0px",
                    marginLeft: "2px",
                    textAlign: "left",
                    marginTop: "2px",
                  }}
                  id="bank_name"
                  disabled={isPaid}
                  value={bankName}
                  onChange={(e) => {
                    console.log("event", e.value.name);
                    setBankName(e.value);
                    formik.values.bank_name = e.value.name;
                  }}
                  onFilter={(e) => {
                    setCurrent({ name: e.filter, code: "custom" });
                    console.log(current, "hihihi");
                  }}
                  filter
                  options={options}
                  optionLabel="name"
                  placeholder={isPaid ? bank : "Select a Bank"}
                  valueTemplate={selectedBankTemplate}
                  itemTemplate={bankOptionTemplate}
                />
              </div>
              <div className="flex">
                <div
                  style={{
                    width: "450px",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0px 10px",
                    border: "1px solid #000000",
                    borderRadius: "0px",
                    textAlign: "left",
                    marginTop: "2px",
                    height: "45px",
                  }}
                >
                  <div className="flex align-items-center">
                    <RadioButton
                      inputId="ingredient1"
                      disabled={isPaid}
                      name="Active"
                      value={"New Admission"}
                      onChange={(e) => {
                        formik.values.invoice_type = "New Admission";
                        // formik.values.admission_amount = 1000;
                        setUpdate(true);
                      }}
                      checked={
                        isPaid
                          ? invoiceType === "New Admission"
                          : formik.values.invoice_type === "New Admission"
                      }
                    />
                    {formik.errors.invoice_type ? (
                      <label
                        htmlFor="ingredient1"
                        className="ml-2"
                        style={{ color: "red" }}
                      >
                        New Admission
                      </label>
                    ) : (
                      <label htmlFor="ingredient1" className="ml-2">
                        New Admission
                      </label>
                    )}
                  </div>
                  <div className="flex align-items-center">
                    <RadioButton
                      inputId="ingredient2"
                      disabled={isPaid}
                      name="Deactive"
                      value={"Subscription Renewal"}
                      onChange={(e) => {
                        formik.values.invoice_type = "Subscription Renewal";
                        setUpdate(false);
                        // formik.values.admission_amount = 0;
                      }}
                      checked={
                        isPaid
                          ? invoiceType === "Subscription Renewal"
                          : formik.values.invoice_type ===
                            "Subscription Renewal"
                      }
                    />
                    {formik.errors.invoice_type ? (
                      <label
                        htmlFor="ingredient2"
                        className="ml-2"
                        style={{ color: "red" }}
                      >
                        Subscription Renewal
                      </label>
                    ) : (
                      <label htmlFor="ingredient2" className="ml-2">
                        Subscription Renewal
                      </label>
                    )}
                  </div>
                </div>
                <InputText
                  style={{
                    width: "204px",
                    border: "1px solid #000000",
                    borderRadius: "0px",
                    marginLeft: "2px",
                    textAlign: "left",
                    marginTop: "2px",
                    height: "45px",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "10px",
                  }}
                  id="financial_year"
                  value={fYear}
                  onChange={formik.handleChange}
                  disabled
                />
              </div>
              <div className="flex">
                <div className="text-l align-items-center" style={styles}>
                  Start Year :
                </div>

                <Calendar
                  value={startDate}
                  onChange={(e) => setStartDate(e.value)}
                  style={styles4}
                  view="year"
                  dateFormat="yy"
                />

                <div className="text-l align-items-center" style={styles5}>
                  End Year :
                </div>

                <Calendar
                  value={endDate}
                  onChange={(e) => setEndDate(e.value)}
                  style={styles4}
                  view="year"
                  dateFormat="yy"
                />
              </div>
              <div className="flex">
                {formik.errors.towards ? (
                  <div className="text-l align-items-center" style={styles3}>
                    Towards * :
                  </div>
                ) : (
                  <div className="text-l align-items-center" style={styles}>
                    Towards * :
                  </div>
                )}
                <InputText
                  showClear
                  id="towards"
                  disabled
                  value={formik.values.towards}
                  options={towardsOption}
                  onChange={formik.handleChange}
                  // placeholder="Not selected"
                  style={styles2}
                />
              </div>
              <div className="flex">
                {formik.errors.subscription_amount ? (
                  <div
                    className="text-l align-items-center"
                    style={{
                      border: "1px solid red",
                      width: "200px",
                      paddingRight: "5px",
                      paddingTop: "10px",
                      marginTop: "2px",
                      textAlign: "right",
                      color: "red",
                      display: "flex",
                      gap: "5px",
                      justifyContent: "space-between",
                      paddingLeft: "30px",
                    }}
                  >
                    <Checkbox
                      onChange={(e) => setCustomAmount(e.checked)}
                      checked={customAmount}
                    ></Checkbox>
                    <div>Sub. Amount *:</div>
                  </div>
                ) : (
                  <div
                    className="text-l align-items-center"
                    style={{
                      border: "1px solid #000",
                      width: "200px",
                      paddingRight: "5px",
                      paddingTop: "10px",
                      marginTop: "2px",
                      textAlign: "right",
                      color: "#000",
                      display: "flex",
                      gap: "5px",
                      justifyContent: "space-between",
                      paddingLeft: "30px",
                    }}
                  >
                    <Checkbox
                      onChange={(e) => setCustomAmount(e.checked)}
                      checked={customAmount}
                    ></Checkbox>
                    <div>Sub. Amount *:</div>
                  </div>
                )}
                <InputText
                  style={{
                    width: "150px",
                    border: "1px solid #000000",
                    borderRadius: "0px",
                    marginLeft: "2px",
                    textAlign: "left",
                    marginTop: "2px",
                  }}
                  id="subscription_amount"
                  disabled={isPaid}
                  value={formik.values.subscription_amount}
                  onChange={formik.handleChange}
                  // autoComplete="off"
                ></InputText>
                <div
                  className="text-l align-items-center"
                  style={{
                    border: "1px solid #000000",
                    width: "150px",
                    padding: "10px",
                    paddingTop: "10px",
                    marginTop: "2px",
                    textAlign: "right",
                    marginLeft: "2px",
                  }}
                >
                  Add. Amount :
                </div>
                <InputText
                  style={{
                    width: "150px",
                    border: "1px solid #000000",
                    borderRadius: "0px",
                    marginLeft: "2px",
                    textAlign: "left",
                    marginTop: "2px",
                  }}
                  id="admission_amount"
                  disabled={isPaid}
                  value={formik.values.admission_amount}
                  onChange={formik.handleChange}
                  autoComplete="off"
                ></InputText>
              </div>
              <div className="flex">
                <div className="text-l align-items-center" style={styles}>
                  Interest Amount :
                </div>
                <InputText
                  style={{
                    width: "150px",
                    border: "1px solid #000000",
                    borderRadius: "0px",
                    marginLeft: "2px",
                    textAlign: "left",
                    marginTop: "2px",
                  }}
                  id="interest_amount"
                  disabled={isPaid}
                  value={formik.values.interest_amount}
                  onChange={formik.handleChange}
                  autoComplete="off"
                ></InputText>
                <div
                  className="text-l align-items-center"
                  style={{
                    border: "1px solid #000000",
                    width: "150px",
                    padding: "10px",
                    paddingTop: "10px",
                    marginTop: "2px",
                    textAlign: "right",
                    marginLeft: "2px",
                  }}
                >
                  sgst :
                </div>
                <InputText
                  style={{
                    width: "150px",
                    border: "1px solid #000000",
                    borderRadius: "0px",
                    marginLeft: "2px",
                    textAlign: "left",
                    marginTop: "2px",
                  }}
                  id="sgst"
                  disabled
                  value={formik.values.sgst}
                  onChange={formik.handleChange}
                  autoComplete="off"
                ></InputText>
              </div>
              <div className="flex">
                <div className="text-l align-items-center" style={styles}>
                  cgst :
                </div>
                <InputText
                  style={{
                    width: "150px",
                    border: "1px solid #000000",
                    borderRadius: "0px",
                    marginLeft: "2px",
                    textAlign: "left",
                    marginTop: "2px",
                  }}
                  id="cgst"
                  disabled
                  value={formik.values.cgst}
                  onChange={formik.handleChange}
                  autoComplete="off"
                ></InputText>
                <div
                  className="text-l align-items-center"
                  style={{
                    border: "1px solid #000000",
                    width: "150px",
                    padding: "10px",
                    paddingTop: "10px",
                    marginTop: "2px",
                    textAlign: "right",
                    marginLeft: "2px",
                  }}
                >
                  IGST :
                </div>
                <InputText
                  style={{
                    width: "150px",
                    border: "1px solid #000000",
                    borderRadius: "0px",
                    marginLeft: "2px",
                    textAlign: "left",
                    marginTop: "2px",
                  }}
                  id="igst"
                  disabled={isPaid}
                  value={formik.values.igst}
                  onChange={formik.handleChange}
                  autoComplete="off"
                ></InputText>
              </div>
              <div className="flex">
                <div className="text-l align-items-center" style={styles}>
                  Extra/Misc. Amount :
                </div>
                <InputText
                  style={{
                    width: "150px",
                    border: "1px solid #000000",
                    borderRadius: "0px",
                    marginLeft: "2px",
                    textAlign: "left",
                    marginTop: "2px",
                  }}
                  id="misc_amount"
                  disabled={isPaid}
                  value={formik.values.misc_amount}
                  onChange={formik.handleChange}
                  autoComplete="off"
                ></InputText>
                <div
                  className="text-l align-items-center"
                  style={{
                    border: "1px solid #000000",
                    width: "150px",
                    padding: "10px",
                    paddingTop: "10px",
                    marginTop: "2px",
                    textAlign: "right",
                    marginLeft: "2px",
                  }}
                >
                  Total Amount :
                </div>
                <InputText
                  style={{
                    width: "150px",
                    border: "1px solid #000000",
                    borderRadius: "0px",
                    marginLeft: "2px",
                    textAlign: "left",
                    marginTop: "2px",
                  }}
                  id="paid_amount"
                  disabled
                  value={total === 0 ? formik.values.paid_amount : total}
                  onChange={formik.handleChange}
                  autoComplete="off"
                ></InputText>
              </div>
              <div className="flex">
                <div className="text-l align-items-center" style={styles}>
                  remarks :
                </div>
                <InputText
                  style={styles2}
                  id="remark"
                  disabled={isPaid}
                  value={formik.values.remark}
                  onChange={formik.handleChange}
                  autoComplete="off"
                ></InputText>
              </div>
              <div style={{ marginTop: "20px" }}>
                <Button
                  label="No"
                  type="button"
                  icon="pi pi-times"
                  onClick={() => {
                    onHide("displayBasic1");
                  }}
                  className="p-button-text"
                />
                <Button
                  label="Generate"
                  icon="pi pi-check"
                  disabled={isPaid}
                  type="submit"
                  style={{ marginLeft: "20px" }}
                />
                {isPaid ? (
                  <Button
                    label="Re-Print"
                    icon="pi pi-check"
                    type="button"
                    onClick={() => {
                      // onHide("displayBasic1");
                      rePrint();
                    }}
                    style={{ marginLeft: "20px" }}
                  />
                ) : null}
                {isPaid ? (
                  <Button
                    label="Cancel"
                    icon="pi pi-times"
                    type="button"
                    onClick={() => {
                      // onHide("displayBasic1");
                      // rePrint();
                      cancel();
                    }}
                    style={{ marginLeft: "20px" }}
                  />
                ) : null}
              </div>
            </div>
          </form>
        </Dialog>
        <Dialog
          header="Update Category"
          visible={displayBasic2}
          breakpoints={{ "960px": "75vw" }}
          style={{ width: "50vw" }}
          footer={renderUploadFooter("displayBasic2")}
          onHide={() => onHide("displayBasic2")}
        >
          <div>
            <div>
              <div className="flex">
                <div className="text-center align-items-center textInput">
                  Member Category :
                </div>
                <Dropdown
                  style={{
                    width: "340px",
                    border: "1px solid #000000",
                    borderRadius: "0px",
                    marginLeft: "2px",
                    textAlign: "left",
                    marginTop: "2px",
                  }}
                  value={memberCategory}
                  onChange={(e) => {
                    setMemberCategory(e.target.value);
                  }}
                  options={memberOptions}
                ></Dropdown>
              </div>
              <div className="flex">
                <div className="text-center align-items-center textInput">
                  Membership Category :
                </div>
                <Dropdown
                  style={{
                    width: "340px",
                    border: "1px solid #000000",
                    borderRadius: "0px",
                    marginLeft: "2px",
                    textAlign: "left",
                    marginTop: "2px",
                  }}
                  value={memberShipCategory}
                  onChange={(e) => {
                    setMemberShipCategory(e.target.value);
                  }}
                  options={membershipCategoryOptions}
                ></Dropdown>
              </div>
              <Button
                onClick={(e) => {
                  handleCategoryUpdate();
                }}
                className="p-button w-12rem  h-2rem ml-3 mt-4 font-light"
                label="Save Changes"
              ></Button>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
}

export default MemberDetails;
